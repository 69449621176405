import { guid, StoreConfig, Store, EntityState, EntityStore } from '@datorama/akita';
import pageSettings from '../../config/page-settings';
import { Injectable } from '@angular/core';

export interface TypeComplaint  {
  id: number;
  name: string;
  state: string;
}

export interface TypeComplaintState extends EntityState<TypeComplaint, number> {
  id: number;
  name: string;
  state: string;
}

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'TypeComplaint' })
export class TypeComplaintStore extends EntityStore<TypeComplaintState> {
  constructor() {
    super();
    this.set({1: null}, {activeId: 1});
  }
}
