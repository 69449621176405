import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, ResolveEnd, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService, NoValidatedRoutes } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(
    public auth: AuthService,
    public router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
  ) {}
  async canActivate(state: ActivatedRouteSnapshot): Promise<boolean> {
    try {
      if (NoValidatedRoutes.includes(state.url[0].path)) {
        this.auth.updateSettingsStore({hiddingBars: true});
        return true;
      }
      this.spinner.show();
      const authData = await this.auth.isAuthenticated();
      this.spinner.hide();
      if (!authData) {
        this.auth.logout();
        // location.href = '/login';
        this.auth.updateSettingsStore({hiddingBars: true});
        let url = new URL(window.location.href)
        this.router.navigateByUrl(`/login?redirect=${url.pathname + url.search.replace('?', '@')}`);
        return false;
      }
      const tokenData = this.auth.getJwtData();
      this.spinner.show();
      const data = await this.auth.whoAmI();
      this.spinner.hide();
      const aRoutes = this.auth.getAllowedRoutes()
      .filter(x => x.data.withRoles !== undefined)
      .filter(x =>
        x.data.withRoles.includes('*') ||
        x.data.withRoles.includes(tokenData.scopes[0].authority)
      )
      .map(x => x.path);
      if (aRoutes.length === 0) {
        this.router.navigate(['dashboard']);
        return false;
      }
      if (!aRoutes.includes(state.routeConfig.path)) {
        this.router.navigate(['dashboard']);
        return false;
      }
      this.auth.updateSettingsStore({hiddingBars: false, userData: {
        email: tokenData.sub,
        role: tokenData.scopes[0].authority,
        name: data.data.name,
        lastName: data.data.lastname,
        fullName: `${data.data.name} ${data.data.lastname}`,
        docNumber: data.data.serial,
        docType: data.data.typeDocumentName,
        birthDate: data.data.birthdate,
        cellPhone: data.data.cellPhone,
        entity: data.data.entityName
      }});
      return true;
    } catch (error) {
      this.spinner.hide();
      return false;
    }
  }
}
