<!-- begin #header -->
<div id="header" class="header navbar-default">
  <!-- begin navbar-header -->
  <div class="navbar-header">
    <button type="button" class="navbar-toggle pull-left" *ngIf="this.pageSidebarTwo" (click)="mobileRightSidebarToggle()">
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
    </button>
    <a routerLink="/login" routerLinkActive="active"  class="navbar-brand"><img src="https://defensorialg.com.co/wp-content/uploads/2020/03/logodcflg2-1.png"/></a>
    <button type="button" class="navbar-toggle pt-0 pb-0 mr-0" (click)="mobileTopMenuToggle()" *ngIf="this.pageSettings.pageTopMenu && !this.pageSettings.pageWithoutSidebar">
      <span class="fa-stack fa-lg text-inverse">
        <i class="far fa-square fa-stack-2x"></i>
        <i class="fa fa-cog fa-stack-1x"></i>
      </span>
    </button>
    <button type="button" class="navbar-toggle pt-0 pb-0 mr-0" (click)="mobileMegaMenuToggle()" *ngIf="this.pageSettings.pageMegaMenu">
      <span class="fa-stack fa-lg text-inverse">
        <i class="far fa-square fa-stack-2x"></i>
        <i class="fa fa-cog fa-stack-1x"></i>
      </span>
    </button>
    <button type="button" class="navbar-toggle" (click)="mobileSidebarToggle()" *ngIf="!this.pageSettings.pageWithoutSidebar">
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
    </button>
    <button type="button" class="navbar-toggle" (click)="mobileTopMenuToggle()" *ngIf="this.pageSettings.pageTopMenu && this.pageSettings.pageWithoutSidebar">
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
    </button>
  </div>
  <!-- end navbar-header -->

  <!-- begin mega-menu -->
  <div class="collapse navbar-collapse" *ngIf="this.pageSettings.pageMegaMenu" [ngStyle]="{ 'display': (this.pageSettings.pageMegaMenu && this.pageSettings.pageMobileMegaMenuToggled) ? 'block' : '' }">
    <ul class="nav navbar-nav">
      <li class="dropdown dropdown-lg" ngbDropdown>
        <a href="javascript:;" class="dropdown-toggle" ngbDropdownToggle><i class="fa fa-th-large fa-fw mr-1 mr-md-0"></i> Mega <b class="caret ml-1 ml-md-0"></b></a>
        <div class="dropdown-menu dropdown-menu-lg" ngbDropdownMenu>
          <div class="row">
            <div class="col-lg-4 col-md-4">
              <div class="f-w-700 text-inverse mb-2">UI Kits</div>
              <div class="row m-b-20">
                <div class="col-lg-6">
                  <ul class="nav d-block">
                    <li><a href="javascript:;" class="text-ellipsis text-muted"><i class="fa fa-chevron-right fa-fw text-inverse"></i> FontAwesome</a></li>
                    <li><a href="javascript:;" class="text-ellipsis text-muted"><i class="fa fa-chevron-right fa-fw text-inverse"></i> Ionicons</a></li>
                    <li><a href="javascript:;" class="text-ellipsis text-muted"><i class="fa fa-chevron-right fa-fw text-inverse"></i> Simple Line Icons</a></li>
                    <li><a href="javascript:;" class="text-ellipsis text-muted"><i class="fa fa-chevron-right fa-fw text-inverse"></i> Typography</a></li>
                    <li><a href="javascript:;" class="text-ellipsis text-muted"><i class="fa fa-chevron-right fa-fw text-inverse"></i> Media Object</a></li>
                    <li><a href="javascript:;" class="text-ellipsis text-muted"><i class="fa fa-chevron-right fa-fw text-inverse"></i> Widget Boxes</a></li>
                    <li><a href="javascript:;" class="text-ellipsis text-muted"><i class="fa fa-chevron-right fa-fw text-inverse"></i> Tabs & Accordions</a></li>
                  </ul>
                </div>
                <div class="col-lg-6">
                  <ul class="nav d-block">
                    <li><a href="javascript:;" class="text-ellipsis text-muted"><i class="fa fa-chevron-right fa-fw text-inverse"></i> Unlimited Nav Tabs</a></li>
                    <li><a href="javascript:;" class="text-ellipsis text-muted"><i class="fa fa-chevron-right fa-fw text-inverse"></i> Modal & Notification</a></li>
                    <li><a href="javascript:;" class="text-ellipsis text-muted"><i class="fa fa-chevron-right fa-fw text-inverse"></i> Buttons</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4">
              <div class="f-w-700 text-inverse mb-2">Page Options <span class="label label-pink ml-1">11</span></div>
              <div class="row m-b-20">
                <div class="col-lg-6">
                  <ul class="nav d-block">
                    <li><a href="javascript:;" class="text-ellipsis text-muted"><i class="fa fa-chevron-right fa-fw text-inverse"></i> Blank Page</a></li>
                    <li><a href="javascript:;" class="text-ellipsis text-muted"><i class="fa fa-chevron-right fa-fw text-inverse"></i> Page with Footer <span class="badge badge-success pt-1 pb-1">NEW</span></a></li>
                    <li><a href="javascript:;" class="text-ellipsis text-muted"><i class="fa fa-chevron-right fa-fw text-inverse"></i> Page without Sidebar</a></li>
                    <li><a href="javascript:;" class="text-ellipsis text-muted"><i class="fa fa-chevron-right fa-fw text-inverse"></i> Page with Right Sidebar</a></li>
                    <li><a href="javascript:;" class="text-ellipsis text-muted"><i class="fa fa-chevron-right fa-fw text-inverse"></i> Page with Minified Sidebar</a></li>
                    <li><a href="javascript:;" class="text-ellipsis text-muted"><i class="fa fa-chevron-right fa-fw text-inverse"></i> Page with Two Sidebar</a></li>
                    <li><a href="javascript:;" class="text-ellipsis text-muted"><i class="fa fa-chevron-right fa-fw text-inverse"></i> Page with Line Icons</a></li>
                  </ul>
                </div>
                <div class="col-lg-6">
                  <ul class="nav d-block">
                    <li><a href="javascript:;" class="text-ellipsis text-muted"><i class="fa fa-chevron-right fa-fw text-inverse"></i> Full Height Content</a></li>
                    <li><a href="javascript:;" class="text-ellipsis text-muted"><i class="fa fa-chevron-right fa-fw text-inverse"></i> Page with Mega Menu</a></li>
                    <li><a href="javascript:;" class="text-ellipsis text-muted"><i class="fa fa-chevron-right fa-fw text-inverse"></i> Page with Light Sidebar</a></li>
                    <li><a href="javascript:;" class="text-ellipsis text-muted"><i class="fa fa-chevron-right fa-fw text-inverse"></i> Page with Large Sidebar</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4">
              <div class="f-w-700 text-inverse mb-2">Paragraph</div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis libero purus, fermentum at libero convallis, auctor dignissim mauris. Nunc laoreet pellentesque turpis sodales ornare. Nunc vestibulum nunc lorem, at sodales velit malesuada congue. Nam est tortor, tincidunt sit amet eros vitae, aliquam finibus mauris.
              </p>
              <p>
                Fusce ac ligula laoreet ante dapibus mattis. Nam auctor vulputate aliquam. Suspendisse efficitur, felis sed elementum eleifend, ipsum tellus sodales nisi, ut condimentum nisi sem in nibh. Phasellus suscipit vulputate purus at venenatis. Quisque luctus tincidunt tempor.
              </p>
            </div>
          </div>
        </div>
      </li>
      <li>
        <a href="javascript:;">
          <i class="fa fa-gem fa-fw mr-1 mr-md-0"></i> Client
        </a>
      </li>
      <li class="dropdown" ngbDropdown>
        <a href="javascript:;" class="dropdown-toggle" ngbDropdownToggle>
          <i class="fa fa-database fa-fw mr-1 mr-md-0"></i> New <b class="caret ml-1 ml-md-0"></b>
        </a>
        <div class="dropdown-menu" ngbDropdownMenu>
          <a href="javascript:;" class="dropdown-item">Action</a>
          <a href="javascript:;" class="dropdown-item">Another action</a>
          <a href="javascript:;" class="dropdown-item">Something else here</a>
          <div class="dropdown-divider"></div>
          <a href="javascript:;" class="dropdown-item">Separated link</a>
          <div class="dropdown-divider"></div>
          <a href="javascript:;" class="dropdown-item">One more separated link</a>
        </div>
      </li>
    </ul>
  </div>
  <!-- end mega-menu -->

  <!-- begin header-nav -->
  <ul class="navbar-nav navbar-right">
    <li class="dropdown" ngbDropdown>
      <a href="javascript:;" ngbDropdownToggle class="dropdown-toggle f-s-14">
        <i class="fa fa-bell"></i>
        <span class="label">0</span>
      </a>
      <div class="dropdown-menu media-list dropdown-menu-right" ngbDropdownMenu>
        <div class="dropdown-header">Notificaciones (0)</div>
        <div class="text-center width-300 p-b-10 p-t-10">
          No tienes notificaciones
        </div>
      </div>
    </li>
    <li class="dropdown navbar-language" ngbDropdown *ngIf="this.pageSettings.pageLanguageBar">
      <a href="javascript:;" class="dropdown-toggle pr-1 pl-1 pr-sm-3 pl-sm-3" ngbDropdownToggle>
        <span class="flag-icon flag-icon-us" title="us"></span>
				<span class="name d-none d-sm-inline">EN</span> <b class="caret"></b>
      </a>
      <div class="dropdown-menu" ngbDropdownMenu>
        <a href="javascript:;" class="dropdown-item"><span class="flag-icon flag-icon-us" title="us"></span> English</a>
        <a href="javascript:;" class="dropdown-item"><span class="flag-icon flag-icon-cn" title="cn"></span> Chinese</a>
        <a href="javascript:;" class="dropdown-item"><span class="flag-icon flag-icon-jp" title="jp"></span> Japanese</a>
        <a href="javascript:;" class="dropdown-item"><span class="flag-icon flag-icon-be" title="be"></span> Belgium</a>
        <div class="dropdown-divider"></div>
        <a href="javascript:;" class="dropdown-item text-center">more options</a>
      </div>
    </li>
    <li class="dropdown navbar-user" ngbDropdown *ngIf="userData$ | async as userData">
      <a href="javascript:;" class="dropdown-toggle" ngbDropdownToggle>
        <div class="image image-icon bg-black text-grey-darker">
          <i class="fa fa-user"></i>
        </div>
        <span class="d-none d-md-inline">{{userData.name}} {{userData.lastName}}</span> <b class="caret"></b>
      </a>
      <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
        <a href="javascript:;" class="dropdown-item" [routerLink]="['/user/profile']" *ngIf="isEnterpriseRole()"><i class="fa fa-user-alt fa-x2"></i> Mi perfil</a>
        <!-- <a href="javascript:;" class="dropdown-item">Edit Profile</a>
        <a href="javascript:;" class="dropdown-item"><span class="badge badge-danger pull-right">0</span> Inbox</a>
        <a href="javascript:;" class="dropdown-item">Calendar</a>
        <div class="dropdown-divider"></div> -->
        <a (click)="logout()" href="javascript:;" class="dropdown-item"><i class="fa fa-sign-in-alt fa-x2"></i> Cerrar Sesión</a>
      </div>
    </li>
    <li class="divider d-none d-md-block" *ngIf="this.pageSidebarTwo"></li>
    <li class="d-none d-md-block" *ngIf="this.pageSidebarTwo">
      <a href="javascript:;" (click)="toggleSidebarRight()" class="f-s-14">
        <i class="fa fa-th"></i>
      </a>
    </li>
  </ul>
  <!-- end header navigation right -->
</div>
<!-- end #header -->
