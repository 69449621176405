import { BaseEntity, TRole, TState } from './../../interfaces/user.interface';
import { StoreConfig, EntityState, EntityStore } from '@datorama/akita';
import { Injectable } from '@angular/core';

export interface UserFilter {
  page?: number;
  loading?: boolean;
  serial?: number;
  id?: number;
  email?: string;
  name?: string;
  lastname?: string;
}

export interface User {
  id: number;
  typeDocument: number;
  typeDocumentName?: string;
  genreType: number;
  rolId: number;
  rolName: TRole;
  rol?: BaseEntity;
  serial: number;
  name: string;
  lastname: string;
  birthdate: string;
  email: string;
  password?: string;
  phone: string;
  cellPhone: string;
  positionId?: number; //Solo cuando es rol entidad
  proffesionId?: 1; //Solo cuando es rol entidad
  managerName?: string; //Solo cuando es rol entidad
  emailCopy?: string; //Solo cuando es rol entidad
  entitiesId?: Array<number>;
  state?: TState;
}

export interface UserState extends EntityState<User, number> {
  id: number;
  typeDocument: number;
  typeDocumentName?: string;
  genreType: number;
  rolId: number;
  rolName: TRole;
  serial: number;
  name: string;
  lastname: string;
  birthdate: string;
  email: string;
  password?: string;
  phone: string;
  cellphone: string;
  positionId?: number; //Solo cuando es rol entidad
  proffesionId?: 1; //Solo cuando es rol entidad
  managerName?: string; //Solo cuando es rol entidad
  emailCopy?: string; //Solo cuando es rol entidad
  entitiesId?: Array<number>;
  state?: TState;
}

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'User' })
export class UserStore extends EntityStore<UserState> {
  constructor() {
    super();
  }
}
