<!-- begin breadcrumb -->
<ol class="breadcrumb float-xl-right">
  <li class="breadcrumb-item"><a href="javascript:;">Inicio</a></li>
</ol>
<!-- end breadcrumb -->
<!-- begin page-header -->
<h1 class="page-header">Bienvenido <small>Aquí encontrarás información acerca de la aplicación...</small></h1>
<!-- end page-header -->

<div class="row">
  <div class="col-xl-3 col-md-6">
    <div class="widget widget-stats bg-blue">
      <div class="stats-icon"><i class="fa fa-desktop"></i></div>
      <div class="stats-info">
        <h4>Peticiones Registradas</h4>
        <p>{{ resume.allTickets | number }}</p>
      </div>
      <div class="stats-link">
        <a href="javascript:;"></a>
      </div>
    </div>
  </div>
  <!-- end col-3 -->
  <!-- begin col-3 -->
  <div class="col-xl-3 col-md-6">
    <div class="widget widget-stats bg-success">
      <div class="stats-icon"><i class="fa fa-clipboard-check"></i></div>
      <div class="stats-info">
        <h4>Tickets Activos</h4>
        <p>{{ resume.activateTickets | number }}</p>
      </div>
      <div class="stats-link">
        <a href="javascript:;"></a>
      </div>
    </div>
  </div>
  <!-- end col-3 -->
  <!-- begin col-3 -->
  <div class="col-xl-3 col-md-6">
    <div class="widget widget-stats bg-red">
      <div class="stats-icon"><i class="fa fa-business-time"></i></div>
      <div class="stats-info">
        <h4>Peticiones fuera de tiempo</h4>
        <p>{{ resume.deprecateTickets | number }}</p>
      </div>
      <div class="stats-link">
        <a href="javascript:;"> </a>
      </div>
    </div>
  </div>

  <!-- begin col-3 -->
  <div class="col-xl-3 col-md-6">
    <div class="widget widget-stats bg-inverse">
      <div class="stats-icon"><i class="fa fa-clock"></i></div>
      <div class="stats-info">
        <h4>Ultimo Inicio de Sesión</h4>
        <p>{{ lastLoginTime }}</p>
      </div>
      <div class="stats-link">
        <a href="javascript:;"> </a>
      </div>
    </div>
  </div>
  <!-- end col-3 -->
</div>

<!-- begin panel -->
<panel title="Información últimos 5 días" noButton="true">
  <div>
    <div class="height-sm">
      <ngx-charts-line-chart
        [animations]="false"
        [scheme]="lineChartColor"
        [results]="resume.graphicsData"
        [xAxis]="true"
        [yAxis]="true"
        [legend]="true"
        [legendTitle]="'Entidades'"
        [showXAxisLabel]="true"
        [xAxisLabel]="'Fecha'"
        [showYAxisLabel]="true"
        [yAxisLabel]="'Peticiones Registradas'"
        [roundDomains]="true"
        [autoScale]="true">
      </ngx-charts-line-chart>
    </div>
  </div>
</panel>
<!-- end panel -->
