import { AuthService } from 'src/app/services/auth.service';
import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { map, shareReplay, tap, filter, startWith, switchMap, flatMap } from 'rxjs/operators';
import { routes } from 'src/app/config/routes';
import { RecieveMediumState, RecieveMediumStore } from './recieve_medium.store';
import { Observable } from 'rxjs-compat';
import { BaseQuery } from '../base/base.query';

@Injectable({
  providedIn: 'root'
})
export class RecieveMediumQuery extends BaseQuery<RecieveMediumState> {
  constructor(protected store: RecieveMediumStore) {
    super(store);
  }

  active$ = () => this.selectRecieveMedium().pipe(shareReplay(1));

  getSnapshot = () => this.getValue();

  isLoading$ = () => this.selectLoading();

  recieveMedium$ = () => this.selectRecieveMedium().pipe(shareReplay(1));
  
  selectRecieveMedium() {
    return this.selectAll().pipe(
      map(it => it.filter(x => x != null)),
      map(it => it.map(x => ({
        ...x,
        name: x.name
      }))),
    )
  }

}
