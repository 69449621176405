<ol class="breadcrumb float-xl-left">
  <li class="breadcrumb-item"><a [routerLink]="['/user/list']">Lista de Usuarios</a></li>
  <li class="breadcrumb-item">Detalle de usuario </li>
</ol>
<div class="clearfix"></div>

<ng-container *ngLet="userData$ | async as userData">
  <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12">
    <div class="row">
      <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
        <h1 class="page-header" *ngIf="isNewRecord">Detalle de usuario | Nuevo Usuario <small></small></h1>
        <h1 class="page-header" *ngIf="!isNewRecord">Detalle de usuario | {{ userData.id }}<small></small></h1>
      </div>
      <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6" *ngIf="!isNewRecord">
        <a href="javascript:;" (click)="changeStatus()" *ngIf="(userIsActive$() | async)" tabindex=”12” class="btn btn-success btn-block"><i class="fas fa-check-circle" ></i> Activo (Click para Inactivar)</a>
        <a href="javascript:;" (click)="changeStatus()" *ngIf="!(userIsActive$() | async)" tabindex=”12” class="btn btn-danger btn-block"><i class="fas fa-times-circle" ></i> Inactivo (Click para Activar)</a>
      </div>
    </div>
  </div>

  <div class="row">    
    <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12">
      <form [formGroup]="userForm" (ngSubmit)="onSubmit()" class="margin-bottom-0">
        <panel title="Información básica" noButton="true" variant="default" bodyClass="border-default">
          <div class="row">
            <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
              <div class="form-group row m-b-15">                
                <div class="col-md-12" *ngIf="personalTypes$ | async as personalTypes">
                  <label class="col-form-label">Tipo de persona</label>
                  <ng-select
                    appearance="outline"
                    [searchable]="false"
                    formControlName="typePerson"
                    [items]="personalTypes"
                    bindLabel="name"
                    [clearable]="false"
                    bindValue="id"
                    tabindex=”1”
                    >
                  </ng-select>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
              <div class="form-group row m-b-15" *ngIf="documentTypes$ | async as documentTypes">                
                <div class="col-md-12">
                  <label class="col-form-label">Tipo de documento</label>
                  <ng-select
                    appearance="outline"
                    [searchable]="false"
                    formControlName="typeDocumentId"
                    [items]="documentTypes"
                    bindLabel="name"
                    [clearable]="false"
                    bindValue="id"
                    tabindex=”2”
                    >
                  </ng-select>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
              <div class="form-group row m-b-15">                
                <div class="col-md-12">
                  <label class="col-form-label">Número de documento</label>
                  <input formControlName="serial" class="form-control m-b-5" placeholder="Ej: 1065240124" type="text" tabindex=”3” NumbersOnly [ngClass]="{
                    'is-valid': !serial.invalid,
                    'is-invalid': (serial.invalid)
                  }">
                </div>
              </div>
            </div>
            <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6" *ngIf="!isEnterpriseInformation()">
              <div class="form-group row m-b-15">                
                <div class="col-md-12" *ngIf="genders$ | async as genders">
                  <label class="col-form-label">Género</label>
                  <ng-select
                    appearance="outline"
                    [searchable]="false"
                    formControlName="genreType"
                    [items]="genders"
                    bindLabel="name"
                    [clearable]="false"
                    bindValue="id"
                    tabindex=”10”
                    >
                  </ng-select>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12">
              <hr/>
            </div>
            <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
              <div class="form-group row m-b-15">                
                <div class="col-md-12">
                  <label class="col-form-label">Nombres</label>
                  <input formControlName="name" class="form-control m-b-5" placeholder="Ej: Pepito / Empresa ABC" type="text" tabindex=”3” [ngClass]="{
                    'is-valid': !name.invalid,
                    'is-invalid': (name.invalid)
                  }">
                </div>
              </div>
            </div>
            <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
              <div class="form-group row m-b-15">                
                <div class="col-md-12">
                  <label class="col-form-label" *ngIf="!isEnterpriseInformation()">Apellidos</label>
                  <label class="col-form-label" *ngIf="isEnterpriseInformation()">Tipo de sociedad</label>
                  <input formControlName="lastname" class="form-control m-b-5" placeholder="Ej: Perez / S.A.S" type="text" tabindex=”3” [ngClass]="{
                    'is-valid': !lastname.invalid,
                    'is-invalid': (lastname.invalid)
                  }">
                </div>
              </div>
            </div>
            <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12">
              <hr/>
            </div>
            <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
              <div class="form-group row m-b-15">                
                <div class="col-md-12">
                  <label class="col-form-label" *ngIf="!isEnterpriseInformation()">Fecha de nacimiento <small>(Opcional)</small></label>
                  <label class="col-form-label" *ngIf="isEnterpriseInformation()">Fecha de consolidación empresa <small>(Opcional)</small></label>
                  <div class="input-group">
                    <input class="form-control" placeholder="aaaa-mm-dd" readonly (click)="d.toggle()"
                            name="dp" formControlName="birthdate" [minDate]="years_ago_init" [maxDate]="today_mark" ngbDatepicker #d="ngbDatepicker" [ngClass]="{
                            'is-valid': !birthdate.invalid,
                            'is-invalid': (birthdate.invalid)
                          }">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
                        <i class="fa fa-calendar"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </panel>

        <panel title="Información de usuario" noButton="true" variant="default" bodyClass="border-default">
          <div class="row">
            <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
              <div class="form-group row m-b-15">                
                <div class="col-md-12">
                  <label class="col-form-label">Teléfono <small>(Opcional)</small></label>
                  <input formControlName="phone" class="form-control m-b-5" placeholder="Ej: 2357812" type="text" NumbersOnly tabindex=”3” [ngClass]="{
                    'is-valid': !phone.invalid,
                    'is-invalid': (phone.invalid)
                  }">
                </div>
              </div>
            </div>
            <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
              <div class="form-group row m-b-15">                
                <div class="col-md-12">
                  <label class="col-form-label">Celular</label>
                  <input formControlName="cellPhone" class="form-control m-b-5" placeholder="Ej: 3003003030" type="text" tabindex=”3” NumbersOnly [ngClass]="{
                    'is-valid': !cellPhone.invalid,
                    'is-invalid': (cellPhone.invalid)
                  }">
                </div>
              </div>
            </div>
            <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
              <div class="form-group row m-b-15">                
                <div class="col-md-12">
                  <label class="col-form-label">Correo electrónico</label>
                  <input formControlName="email" class="form-control m-b-5" placeholder="Ej: pepito@gmail.com" type="email" tabindex=”3” [ngClass]="{
                    'is-valid': !email.invalid,
                    'is-invalid': (email.invalid)
                  }">
                </div>
              </div>
            </div>
            <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
              <div class="form-group has-feedback m-b-15">
                  <label class="col-form-label">Contraseña</label>
                  <input formControlName="password" [type]="showPassword ? 'password' : 'text'" class="form-control m-b-5" placeholder="Ej: ********" [ngClass]="{
                    'is-valid': !password.invalid,
                    'is-invalid': (password.invalid)
                  }">
                  <span class="form-control-feedback" (click)="showPassword = !showPassword"
                    style="cursor: pointer; pointer-events: all;">
                    <i class="fa" [ngClass]="{ 'fa-eye-slash': !showPassword, 'fa-eye': showPassword}"></i>
                  </span>
                  <small id="passwordHelpBlock" class="form-text text-muted">
                    La contraseña debe tener entre 8 y 16 caracteres, una letra mayúscula, una minúscula y un número.
                  </small>
              </div>
            </div>
          </div>
        </panel>
        
        <panel title="Información del sistema" noButton="true" variant="default" bodyClass="border-default">
          <div class="row">
            <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
              <div class="form-group row m-b-15">                
                <div class="col-md-12" *ngIf="roles$ | async as roles">
                  <label class="col-form-label">Rol del sistema</label>
                  <ng-select
                    appearance="outline"
                    [searchable]="false"
                    formControlName="rolId"
                    [items]="roles"
                    bindLabel="name"
                    [clearable]="false"
                    bindValue="id"
                    tabindex=”1”
                    >
                  </ng-select>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6" *ngIf="canSelectEntities()">
              <div class="form-group row m-b-15">                
                <ng-container *ngIf="isEntityRole()">
                  <div class="col-md-12" *ngIf="entities$ | async as entities">
                    <label class="col-form-label" *ngIf="isEntityRole()">Entidad</label>                    
                    <ng-select
                      appearance="outline"
                      [searchable]="false"
                      formControlName="entityId"
                      [items]="entities"
                      bindLabel="name"
                      [clearable]="false"
                      bindValue="id"
                      tabindex="1"
                      >
                    </ng-select>
                  </div>
                </ng-container>

                <ng-container *ngIf="!isEntityRole()">
                  <label class="col-form-label" *ngIf="!isEntityRole()">Entidades</label>
                  <div class="col-md-12" *ngIf="entities$ | async as entities">
                    <ng-select
                      appearance="outline"
                      [searchable]="false"
                      formControlName="entitiesId"
                      [items]="entities"
                      bindLabel="name"
                      [clearable]="false"
                      bindValue="id"
                      tabindex="1"
                      [multiple]="true"
                      >
                    </ng-select>
                  </div>
                </ng-container>
              </div>
            </div>


            <ng-container *ngIf="isEntityRole()">
              <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12">
                <hr/>
              </div>
              <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
                <div class="form-group row m-b-15">                  
                  <div class="col-md-12" *ngIf="positions$ | async as positions">
                    <label class="col-form-label">Posición</label>
                    <ng-select
                      appearance="outline"
                      [searchable]="false"
                      formControlName="positionId"
                      [items]="positions"
                      bindLabel="name"
                      [clearable]="false"
                      bindValue="id"
                      tabindex=”1”
                      >
                    </ng-select>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
                <div class="form-group row m-b-15">                  
                  <div class="col-md-12" *ngIf="profession$ | async as profession">
                    <label class="col-form-label">Profesión</label>
                    <ng-select
                      appearance="outline"
                      [searchable]="false"
                      formControlName="proffesionId"
                      [items]="profession"
                      bindLabel="name"
                      [clearable]="false"
                      bindValue="id"
                      tabindex=”1”
                      >
                    </ng-select>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
                <div class="form-group row m-b-15">                  
                  <div class="col-md-12">
                    <label class="col-form-label">Nombre del gerente</label>
                    <input formControlName="managerName" class="form-control m-b-5" placeholder="Ej: Pepito Perez" type="text" tabindex=”3” [ngClass]="{
                      'is-valid': !managerName.invalid,
                      'is-invalid': (managerName.invalid)
                    }">
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
                <div class="form-group row m-b-15">                  
                  <div class="col-md-12">
                    <label class="col-form-label">Correos con copia:</label>
                    <input formControlName="emailCopy" class="form-control m-b-5" placeholder="Ej: gerente@gmail.com" type="text" tabindex=”3” [ngClass]="{
                      'is-valid': !emailCopy.invalid,
                      'is-invalid': (emailCopy.invalid)
                    }">
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </panel>

        <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12">
          <div class="row">
            <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
              <a routerLink="/user/list" routerLinkActive="active"  tabindex=”12” class="btn btn-default btn-block"><i class="fas fa-chevron-left text-red" ></i> Atrás</a>
            </div>
            <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
              <button type="submit" tabindex=”12” class="btn btn-success btn-block" [disabled]="!userForm.valid">Guardar <i class="fas fa-save"></i></button>
            </div>
          </div>
        </div>
      <!-- end panel -->
      </form>
    </div>
  </div>
</ng-container>
