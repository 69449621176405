import { Utils } from 'src/app/common/utils';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import swal from 'sweetalert2';
import { NgSelectConfig } from '@ng-select/ng-select';
import { ToolbarService, LinkService, ImageService, HtmlEditorService } from '@syncfusion/ej2-angular-richtexteditor';
import * as _ from 'lodash';

import { NgxSpinnerService } from "ngx-spinner";
import { AuthService, IRoleDetail } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { User, UserQuery } from 'src/app/states/user';
import { TypePersonQuery } from 'src/app/states/type_person';
import { map, startWith, switchMap, tap } from 'rxjs/operators';
import { ExternalService } from 'src/app/services/external.service';
import { GenderQuery } from 'src/app/states/gender';
import { RoleQuery } from 'src/app/states/role';
import * as moment from 'moment';
import { ProfessionQuery } from 'src/app/states/profession';
import { PositionQuery } from 'src/app/states/position';
import { CompanyQuery } from 'src/app/states/company';
import { CustomValidators } from 'src/app/common/validators';


@Component({
  selector: 'users_detail',
  templateUrl: './users_detail.html',
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService]
})

export class UsersDetailPage implements OnInit  {
  showPassword = true;

  years_ago_init = {
    year: 1750,
    month: 1,
    day: 1
  }

  today_mark = {
    year: moment().year(),
    month: moment().month() + 1,
    day: moment().date()
  }

  roleDetail: IRoleDetail;
  closeResult: string;
  currentId: number;
  isActive: boolean;
  isNewRecord: boolean = true;

  userForm = new FormGroup({
    typePerson: new FormControl(null),
    typeDocumentId: new FormControl(null, Validators.required),
    genreType: new FormControl(null, Validators.required),
    rolId: new FormControl(null, Validators.required),
    serial: new FormControl(null, Validators.required),
    name: new FormControl(null, Validators.required),
    lastname: new FormControl(null, Validators.required),
    birthdate: new FormControl(null),
    email: new FormControl(null, [Validators.required, Validators.email, CustomValidators.email]),
    password: new FormControl(null, [Validators.required, CustomValidators.password]),
    phone: new FormControl(null, [Validators.minLength(7)]),
    cellPhone: new FormControl(null, [Validators.required, Validators.minLength(10)]),
    positionId: new FormControl(null),
    proffesionId: new FormControl(null),
    managerName: new FormControl(null),
    emailCopy: new FormControl(null),
    entitiesId: new FormControl([]),
    entityId: new FormControl(null),
  });

  personalTypes$ = this.typePersonQuery.typePerson$();
  documentTypes$ = this.userForm.controls.typePerson.valueChanges.pipe(
    startWith(null),
    switchMap(it => this.typePersonQuery.selectDocument(it)),
  );
  genders$ = this.genderQuery.gender$();
  roles$ = this.roleQuery.Role$();
  positions$ = this.positionQuery.Position$();
  profession$ = this.professionQuery.Profession$();
  entities$ = this.companyQuery.company$();

  ckeConfig: any;
  userData$ = this.userQuery.active$();
  isLoaded$ = this.userData$.pipe(map(() => this.isNewRecord))


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private config: NgSelectConfig,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private userService: UserService,
    private userQuery: UserQuery,
    private typePersonQuery: TypePersonQuery,
    private externalService: ExternalService,
    private genderQuery: GenderQuery,
    private roleQuery: RoleQuery,
    private positionQuery: PositionQuery,
    private professionQuery: ProfessionQuery,
    private companyQuery: CompanyQuery

  ) {
    this.config.notFoundText = 'No se encontraron resultados';
    this.config.bindValue = 'name';
    this.config.loadingText = 'Cargando...';
  }

  async ngOnInit() {
    this.spinner.show();
    await this.getSelectInformation();
    this.roleDetail = this.authService.getRoleDetail();
    const it = this.route.snapshot.params;
    if (it['id'] === 'new') {
      this.isNewRecord = true;
      this.spinner.hide();
      return;
    }
    this.currentId = parseInt(it['id'], 10);
    this.isNewRecord = false;
    await this.loadData();
    this.spinner.hide();
  }

  async loadData() {
    await this.userService.retrieveUser(this.currentId);
    await this.populateForm();
  }

  async getSelectInformation() {
    await this.externalService.getTypePersonAndDocuments();
    await this.externalService.getEntities();
    await this.externalService.getGenders();
    await this.externalService.getRoles();
    await this.externalService.getPositions();
    await this.externalService.getProfessions();
    await this.externalService.getEntities();
  }

  async populateForm() {
    const sub = this.userData$.subscribe(async (it) => {
      const typePerson = this.typePersonQuery.getTypePersonByDocumentType(it.typeDocument);
      const underSub = typePerson.subscribe(it2 => {
        this.isActive = it.state === 'ACTIVE';
        this.userForm.patchValue({
          typePerson: it2.id,
          typeDocumentId: it.typeDocument,
          genreType: it.genreType,
          rolId: it.rolId,
          serial: it.serial,
          name: it.name,
          lastname: it.lastname,
          birthdate: Utils.parseStringDateToDateJsonFormat(it.birthdate),
          email: it.email,
          phone: it.phone,
          cellPhone: it.cellPhone,
          positionId: it.positionId,
          proffesionId: it.proffesionId,
          managerName: it.managerName,
          emailCopy: it.emailCopy,
          entitiesId: it.entitiesId,
          entityId: it.entitiesId[0],
        });
      })
      underSub.unsubscribe();
    });
    sub.unsubscribe();
  }

  async onSubmit() {
    try {
      this.spinner.show();
      const formBase = this.userForm.value;
      const formData = {
        ...this.userForm.value,
        birthdate: Utils.getDate(formBase.birthdate),
        typeDocument: formBase['typeDocumentId'],
        id: this.currentId
      };
      
      delete formData['typePerson'];
      delete formData['typeDocumentId'];

      formData.emailCopy = formData.emailCopy == null ? "" : formData.emailCopy;
      formData.managerName = formData.managerName == null ? "" : formData.managerName;

      if (this.isEntityRole()) {        
        if (!formData.entityId) {
          swal.fire('Lo sentimos...', 'Asegurese de asignar una entidad para este usuario.', 'warning');  
          this.spinner.hide();
          return;
        }

        formData.entitiesId = [formData.entityId];
        formData.proffesionId = formData.proffesionId ? formData.proffesionId : this.professionQuery.getAll()[0].id;
        formData.positionId = formData.positionId ? formData.positionId : this.positionQuery.getAll()[0].id;
      }

      if (this.isNewRecord) {        
        const response = await this.userService.saveUser(formData);
        if(response == undefined) { 
          swal.fire('Excelente...', 'Has creado un usuario de manera exitosa', 'success');         
          this.spinner.hide();
          return;
        }

        swal.fire('Excelente...', 'Has creado un usuario de manera exitosa', 'success');
        this.currentId = response.data.id;
        this.isNewRecord = false;
        this.router.navigateByUrl(`user/list`);
      } else {
        await this.userService.updateUser(formData, 'MODIFY');
      }
      await this.loadData();
      this.spinner.hide();
    } catch (error) {
      console.log(error);
      this.spinner.hide();
    }
  }

  async changeStatus() {
    try {
      this.spinner.show();
      const formBase = this.userForm.value;
      const formData = {
        ...this.userForm.value,
        birthdate: Utils.getDate(formBase.birthdate),
        typeDocument: formBase['typeDocumentId'],
        id: this.currentId
      };
      delete formData['typePerson'];
      delete formData['typeDocumentId'];
      const action = this.isActive ? 'DEACTIVATE' : 'ACTIVATE';
      await this.userService.updateUser(formData, action);
      await this.loadData();
      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
    }
  }

  get typePerson() {
    return this.userForm.get('typePerson');
  }

  get typeDocumentId() {
    return this.userForm.get('typeDocumentId');
  }

  get serial() {
    return this.userForm.get('serial');
  }

  get name() {
    return this.userForm.get('name');
  }

  get email() {
    return this.userForm.get('email');
  }

  get lastname() {
    return this.userForm.get('lastname');
  }

  get birthdate() {
    return this.userForm.get('birthdate');
  }

  get password() {
    return this.userForm.get('password');
  }

  get rolId() {
    return this.userForm.get('rolId');
  }

  get managerName() {
    return this.userForm.get('managerName');
  }

  get emailCopy() {
    return this.userForm.get('emailCopy');
  }

  get cellPhone() {
    return this.userForm.get('cellPhone');
  }

  get phone() {
    return this.userForm.get('phone');
  }  

  get proffesionId() {
    return this.userForm.get('proffesionId');
  } 

  get positionId() {
    return this.userForm.get('positionId');
  } 

  isEnterpriseInformation() {
    if (this.typePerson.value == null) { return false; }
    return parseInt(this.typePerson.value, 10) === 2;
  }

  isEntityRole() {
    if (this.rolId.value == null) { return false; }
    return parseInt(this.rolId.value, 10) === Utils.ENTITY_ROLE_ID;
  }

  canSelectEntities() {
    if (this.rolId.value == null) { return false; }
    return Utils.ROLES_CAN_SELECT_ENTITIES.includes(parseInt(this.rolId.value, 10)); 
  }

  userIsActive$() {
    return this.userData$.map(it => it.state === 'ACTIVE');
  }

}
