import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { map, shareReplay, tap, filter, startWith, switchMap, flatMap } from 'rxjs/operators';
import { ChannelState, ChannelStore } from './channel.store';

@Injectable({
  providedIn: 'root'
})
export class ChannelQuery extends QueryEntity<ChannelState> {
  constructor(protected store: ChannelStore) {
    super(store);
  }

  active$ = () => this.selectChannel().pipe(shareReplay(1));

  getSnapshot = () => this.getValue();

  isLoading$ = () => this.selectLoading();

  channel$ = () => this.selectChannel();

  selectChannel() {
    return this.selectAll().pipe(
      map(it => it.filter(x => x != null))
    )
  }

  setLoading(isLoading) {
    this.store.setLoading(isLoading);
  }

  setState(data, activeId) {
    this.store.set(data, activeId);
  }

}