import { BaseService, ApiResponse, PaginateApiResponse, BaseEntity } from './base.service';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Utils } from '../common/utils';
import { UserQuery } from '../states/user';
import { User, UserFilter } from '../states/user/user.store';

@Injectable()
export class UserService extends BaseService {

  constructor(
    public http: HttpClient,
    private userQuery: UserQuery,
    
    ) {
    super(http);
  }

  async listUsers(userFilter: UserFilter) {
    const queryParams = Utils.parseFilterToQueryString(userFilter);
    const sma_url = `${this.baseUrl}/user${queryParams}`;
    const response = await this.paginatedGet<User>(sma_url);
    return response;
  }

  async retrieveUser(consecutive: number) {
      const sma_url = `${this.baseUrl}/user?id=${consecutive}`;
      const response = await this.paginatedGet<User>(sma_url);
      const singleRecord = response.data.data[0];
      this.userQuery.setState([{...singleRecord}], {activeId: singleRecord.id});
      return response;
  }

  // User Actions

  async saveUser(data) {
    const sma_url = `${this.baseUrl}/user/create`;
    return await this.post<any, User>(sma_url, data);
  }

  async updateUser(data, action: 'MODIFY' | 'DEACTIVATE' | 'ACTIVATE' = 'MODIFY') {
    let response = null;
    const sma_url = `${this.baseUrl}/user/update?action=${action}`;
    if (action === 'MODIFY') {
      response = await this.post<any, User>(sma_url, data);
    } else {
      response = await this.post<any, any>(sma_url, data);
    }
    return response;
  }
}
