<div class="login-cover">
  <div class="login-cover-image" style="background-image: url(../assets/img/cover/home-cover.jpg)"
    data-id="login-cover-image"></div>
  <div class="login-cover-bg-purple"></div>
</div>

<div class="btn-corner p-10">
  <a routerLink="/login" routerLinkActive="active" class="btn btn-l-red">
    <i class="fas fa-lock text-white"></i> &nbsp; Iniciar sesión
  </a>
</div>

<div class="container">
  <br />
  <br />

  <div class="row">
    <div class="col-lg-1 col-xl-1"></div>
    <div class="card border-0 col-lg-10 col-xl-10">
      <div class="card-body">
        <div class="row">
          <div class="col-xl-12 col-lg-12">
            <div class="card bg-complaint border-0 text-white">
              <div class="card bg-white-lighter-transparent border-0 text-white">
                <div class="card-body row p-t-0 p-b-0" *ngIf="currentEntity$ | async as currentEntity">
                  <div class="col-lg-8">       
                    <br>             
                    <a routerLink="/home" routerLinkActive="active" class="btn btn-sm btn-warning"><i
                        class="fas fa-chevron-left text-white"></i> Volver al inicio</a>
                    <p class="card-text text-dark m-t-30 m-b-5"><b>Se iniciara un proceso de reclamación contra la entidad:</b></p>
                    <h4 class="card-title text-dark">{{currentEntity.name}}</h4>
                  </div>
                  <div class="col-lg-4">
                    <div class="pull-right">
                      <img height="150" style="margin: -10px 0;" class="media-object" [src]="currentEntity.logo"
                        [alt]="currentEntity.name" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br>
            <div class="clearfix" style="text-align: left;">
              <h4 class="card-title text-dark" *ngIf="currentStep < 5">
                <span class="badge badge-success" style="padding: 5px 15px 4px;margin-right: 10px;">Paso
                  {{currentStep}}</span>
                <span *ngIf="currentStep == 1">Información personal</span>
                <span *ngIf="currentStep == 2">Acerca de la reclamación</span>
                <span *ngIf="currentStep == 3">Soportes de la reclamación</span>
                <span *ngIf="currentStep == 4">Politicas de privacidad</span>
              </h4>
              <h4 class="card-title text-dark" *ngIf="currentStep == 5"> Hemos Terminado!</h4>
              <hr>
            </div>
          </div>
          <div class="col-xl-12 col-lg-12" *ngIf="currentStep == 1">
            <div class="clearfix">
              <form [formGroup]="personalInfoForm" (ngSubmit)="onSendStep1()" class="margin-bottom-0">
                <div class="row">
                  <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
                    <div class="form-group row m-b-15">
                      <label class="col-form-label">Número de documento</label>
                      <div class="col-md-12">
                        <input formControlName="serial" class="form-control m-b-5" placeholder="Ej: 1065240124"
                          (change)="searchUserMoanfulData($event.target.value)" type="text" NumbersOnly [ngClass]="{
                          'is-valid': !serial.invalid,
                          'is-invalid': (serial.invalid)
                        }">
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
                    <div class="form-group row m-b-15">
                      <label class="col-form-label">Tipo de persona</label>
                      <div class="col-md-12" *ngIf="personalTypes$ | async as personalTypes">
                        <ng-select appearance="outline" [searchable]="false" formControlName="typePerson"
                          [items]="personalTypes" bindLabel="name" [clearable]="false" bindValue="id">
                        </ng-select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
                    <div class="form-group row m-b-15" *ngIf="documentTypes$ | async as documentTypes">
                      <label class="col-form-label">Tipo de documento</label>
                      <div class="col-md-12">
                        <ng-select appearance="outline" [searchable]="false" formControlName="typeDocumentId"
                          [items]="documentTypes" bindLabel="name" [clearable]="false" bindValue="id">
                        </ng-select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
                    <div class="form-group row m-b-15">
                      <label class="col-form-label">Fecha de expedición</label>
                      <div class="col-md-12">
                        <div class="input-group">
                          <input class="form-control" placeholder="aaaa-mm-dd" readonly name="dp"
                            formControlName="documentExpeditionDate" [minDate]="years_ago_init" [maxDate]="today_mark"
                            ngbDatepicker #d="ngbDatepicker" [ngClass]="{
                                  'is-valid': !documentExpeditionDate.invalid,
                                  'is-invalid': (documentExpeditionDate.invalid)
                                }">
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
                              <i class="fa fa-calendar"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
                    <div class="form-group row m-b-15">
                      <label class="col-form-label" *ngIf="!isEnterpriseInformation()">Nombre completo</label>
                      <label class="col-form-label" *ngIf="isEnterpriseInformation()">Razón social</label>
                      <div class="col-md-12">
                        <input formControlName="name" class="form-control m-b-5"
                          placeholder="Ej: Juan Perez / Empresa S.A.S" type="text" [ngClass]="{
                          'is-valid': !name.invalid,
                          'is-invalid': (name.invalid)
                        }">
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
                    <div class="form-group row m-b-15">
                      <label class="col-form-label">¿Condicion especial? <small>Deberás adjuntar el
                          certificado.</small></label>
                      <div class="col-md-12" *ngIf="specialCondition$ | async as conditions">
                        <ng-select appearance="outline" [searchable]="true" formControlName="specialConditionId"
                          [items]="conditions" bindLabel="name" [clearable]="false" bindValue="id"
                          [virtualScroll]="true">
                        </ng-select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
                    <div class="form-group row m-b-15">
                      <label class="col-form-label">Correo electrónico</label>
                      <div class="col-md-12">
                        <input formControlName="email" class="form-control m-b-5" placeholder="Ej: juan@gmail.com"
                          type="email" [ngClass]="{
                          'is-valid': !email.invalid,
                          'is-invalid': (email.invalid)
                        }">
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
                    <div class="form-group row m-b-15">
                      <label class="col-form-label">Teléfono de contacto</label>
                      <div class="col-md-12">
                        <input formControlName="cellPhone" class="form-control m-b-5" placeholder="Ej: 3003003030"
                          type="number" minlength="7" maxlength="10" [ngClass]="{
                          'is-valid': !cellPhone.invalid,
                          'is-invalid': (cellPhone.invalid)
                        }">
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12">
                    <div class="form-group row m-b-15">
                      <label class="col-form-label">Dirección de domicilio</label>
                      <div class="col-md-12">
                        <input formControlName="address" class="form-control m-b-5" placeholder="Ej: Calle 91a 25-00"
                          type="text" [ngClass]="{
                          'is-valid': !address.invalid,
                          'is-invalid': (address.invalid)
                        }">
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="!isEnterpriseInformation()">
                  <hr>
                  <div class="row">
                    <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
                      <div class="form-group row m-b-15">
                        <label class="col-form-label">Genero</label>
                        <div class="col-md-12" *ngIf="genders$ | async as genders">
                          <ng-select appearance="outline" [searchable]="false" formControlName="genreTypeId"
                            [items]="genders" bindLabel="name" [clearable]="false" bindValue="id">
                          </ng-select>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
                      <div class="form-group row m-b-15">
                        <label class="col-form-label">¿Eres jubilado o pensionado?</label>
                        <div class="col-md-12">
                          <ng-select appearance="outline" [searchable]="false" formControlName="retire"
                            [items]="jubilateOptions" bindLabel="name" [clearable]="false" bindValue="id">
                          </ng-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr>
                <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12">
                  <div class="form-group">
                    <div class="col-md-12">
                      <button type="submit" class="btn btn-success btn-block btn-lg pull-right"
                        [disabled]="!personalInfoForm.valid">Siguiente Paso</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div class="col-xl-12 col-lg-12" *ngIf="currentStep == 2">
            <div class="clearfix">
              <form [formGroup]="complaintInfoForm" (ngSubmit)="onSendStep2()" class="margin-bottom-0">
                <div class="row">
                  <div class="col-md-6 col-xl-6 col-xs-6 col-sm-6 col-lg-6">
                    <div class="form-group row m-b-15">
                      <label class="col-form-label">Producto asociado a tu reclamación</label>
                      <div class="col-md-12" *ngIf="products$ | async as products">
                        <ng-select appearance="outline" [searchable]="true" formControlName="product" [items]="products"
                          bindLabel="name" [clearable]="false" bindValue="id" tabindex=”1”>
                        </ng-select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
                    <div class="form-group row m-b-15">
                      <label class="col-form-label col-md-12 text-left">¿Donde ocurrió tu incidente?</label>
                      <div class="col-md-4">
                        <ng-select appearance="outline" [searchable]="true" formControlName="country"
                          [items]="countryOptions" bindLabel="name" [clearable]="false" bindValue="id"
                          [virtualScroll]="true" (change)="selectionCountryChanged($event)">
                        </ng-select>
                      </div>
                      <div class="col-md-8" *ngIf="cities$ | async as cities">
                        <ng-select appearance="outline" [searchable]="true" formControlName="city" [items]="cities"
                          bindLabel="name" [clearable]="false" bindValue="id" [virtualScroll]="true">
                        </ng-select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12">
                    <div class="form-group row m-b-15">
                      <label class="col-form-label col-md-12 text-left">Asunto de la reclamación <small
                          class="f-s-12 text-grey-darker">(Breve resumen del incidente)</small></label>
                      <div class="col-md-12">
                        <input formControlName="subject" class="form-control m-b-5"
                          placeholder="Ej: Mi tarjeta me ha cobrado mas de lo debido" type="text" tabindex=”4”
                          [ngClass]="{
                          'is-valid': !subject.invalid,
                          'is-invalid': (subject.invalid)
                        }">
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12">
                    <div class="form-group row m-b-15 text-left">
                      <label class="col-md-12 col-form-label">Hechos <small
                          class="f-s-12 text-grey-darker">(Min. 20
                          caracteres / Max. 1000 caracteres)</small></label>
                      <div class="col-md-12">
                        <textarea class="form-control" rows="6"
                          placeholder="Indícanos detalladamente qué fue lo que sucedio con la entidad"
                          formControlName="acts" [ngClass]="{
                          'is-valid': !acts.invalid,
                          'is-invalid': (acts.invalid)
                          }">
                        </textarea>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12">
                    <div class="form-group row m-b-15 text-left">
                      <label class="col-md-12 col-form-label">Petición <small
                          class="f-s-12 text-grey-darker">(Min. 20 caracteres / Max. 1000 caracteres)</small></label>
                      <div class="col-md-12">
                        <textarea class="form-control" rows="6"
                          placeholder="¿Qué te gustaría que se hiciera al respecto o como podríamos ayudarte?"
                          formControlName="request" [ngClass]="{
                          'is-valid': !request.invalid,
                          'is-invalid': (request.invalid)
                          }">
                        </textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12">
                    <button type="button" (click)="prevStep()" class="btn btn-default  pull-left">Atras</button>
                    <button type="submit" class="btn btn-success  pull-right"
                      [disabled]="!complaintInfoForm.valid || !personalInfoForm.valid">Siguiente Paso</button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div class="col-xl-12 col-lg-12 text-center" *ngIf="currentStep == 3">
            <label><b>Si cuentas con imagenes, documentos o videos que puedan ayudar a tener mayor claridad acerca de tu
                solicitud, puedes incluirlas en tu reclamación.</b></label>
            <form [formGroup]="requestInfoForm" (ngSubmit)="onSendStep3()" class="margin-bottom-0">
              <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12">
                <div class="dnd-container-sm" style="height: 120px;" appDnd (fileDropped)="onFileDropped($event)">
                  <input type="file" #fileDropRef id="fileDropRef" multiple
                    accept=".pdf,.jpg,.jpeg,.png,.mp4,.doc,.docx,.xls,.xlsx,.bmp,.mp3,.msg"
                    (change)="fileBrowseHandler($event.target.files)" />
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 100 100">
                    <g fill="#3B454F" fill-rule="nonzero">
                      <path
                        d="M42.656 15.135a1.953 1.953 0 0 1-1.391-.578L31.5 4.795l-9.765 9.762a1.97 1.97 0 1 1-2.785-2.785L30.106.616a1.97 1.97 0 0 1 2.785 0l11.157 11.156a1.97 1.97 0 0 1-1.392 3.363z" />
                      <path
                        d="M31.5 36.791a1.97 1.97 0 0 1-1.969-1.969V2.01a1.97 1.97 0 0 1 3.938 0v32.812a1.97 1.97 0 0 1-1.969 1.969z" />
                      <path
                        d="M55.781 63.041H7.22A7.225 7.225 0 0 1 0 55.822V41.385a4.599 4.599 0 0 1 4.594-4.594h7.234a4.567 4.567 0 0 1 4.402 3.276l2.814 9.382a.658.658 0 0 0 .628.467h23.656a.658.658 0 0 0 .628-.467l2.814-9.385a4.572 4.572 0 0 1 4.402-3.273h7.234A4.599 4.599 0 0 1 63 41.385v14.437a7.225 7.225 0 0 1-7.219 7.219zM4.594 40.729a.656.656 0 0 0-.657.656v14.437a3.286 3.286 0 0 0 3.282 3.282H55.78a3.286 3.286 0 0 0 3.282-3.282V41.385a.656.656 0 0 0-.657-.656h-7.234a.65.65 0 0 0-.628.467L47.73 50.58a4.628 4.628 0 0 1-4.402 3.274H19.672a4.567 4.567 0 0 1-4.402-3.276l-2.814-9.382a.65.65 0 0 0-.628-.467H4.594z" />
                    </g>
                  </svg>

                  <h4>Arrastra aquí tus archivos</h4>
                  <button class="btn btn-default" for="fileDropRef">Seleccionar archivos</button>
                </div>
                <div class="dnd-files-list">
                  <div class="single-file text-center" *ngFor="let file of files_; let i = index">
                    <div class="m-l-5">
                      <i class="fa fa-file fa-2x text-muted" aria-hidden="true"></i>
                    </div>
                    <div class="info">
                      <h4 class="name">
                        {{ file.name }}
                      </h4>
                      <p class="size m-b-0">
                        {{ formatBytes(file?.size) }}
                      </p>
                    </div>
                    <div (click)="deleteFile(i)" class="m-r-5">
                      <i class="fa fa-trash text-danger" style="font-size: 15px;" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12">
                  <div class="form-group">
                    <div class="col-md-12">
                      <button type="button" (click)="prevStep()" class="btn btn-default  pull-left">Atras</button>
                      <button type="submit" class="btn btn-success  pull-right">Siguiente Paso</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div class="col-xl-12 col-lg-12" *ngIf="currentStep == 4">
            <form [formGroup]="termsInfoForm" (ngSubmit)="onSendStep4()" class="margin-bottom-0">

              <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12">
                <div class="card border-black-info text-info m-b-10">
                  <div class="card-body">
                    <blockquote class="blockquote mb-0">
                      <div class="col-md-12 text-center">
                        <span>
                          <i *ngIf="!reclamation_accept_terms_and_conditions.value"
                            class="fas fa-exclamation-triangle text-warning fa-2x pull-left"></i>
                          <i *ngIf="reclamation_accept_terms_and_conditions.value == true"
                            class="fas fa-thumbs-up text-success fa-2x pull-left"></i>
                        </span>
                        <button *ngIf="!reclamation_accept_terms_and_conditions.value" type="button"
                          (click)="open(modalDialog)" class="btn btn-link">Necesitamos que leas y aceptes nuestra
                          Política de Privacidad... (Click Aquí <i class="fas fa-hand-pointer"></i>)</button>
                        <button *ngIf="reclamation_accept_terms_and_conditions.value == true" type="button"
                          (click)="open(modalDialog)" class="text-success btn btn-link">Has aceptado nuestras politicas
                          de tratamiento de datos... (Click Aquí <i class="fas fa-hand-pointer"></i>)</button>
                      </div>
                    </blockquote>
                  </div>
                </div>
              </div>

              <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12">
                <div class="card border-black-info text-info m-b-10">
                  <div class="card-body text-center">
                    <re-captcha class="g-recaptcha" (resolved)="resolveCaptcha()"></re-captcha>
                  </div>
                </div>
              </div>

              <hr>
              <div class="row">
                <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12">
                  <div class="form-group">
                    <div class="col-md-12">
                      <button type="button" (click)="prevStep()" class="btn btn-default  pull-left">Atras</button>
                      <button type="submit" class="btn btn-success  pull-right"
                        [disabled]="!complaintInfoForm.valid || !personalInfoForm.valid || !termsInfoForm.valid">Enviar
                        solicitud</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div class="col-xl-12 col-lg-12 text-center" *ngIf="currentStep == 5">
            <div class="card" style="background-color: #0027ff12; border: 1px dashed #3b457dc2;">
              <div class="card-body">
                <i class="fas fa-check-circle fa-5x m-b-30 text-success"></i>

                <h4><b>Hemos recibido tu solicitud exitosamente!</b></h4>
                <p style="padding: 0 5%;">
                  Gracias por permitirnos saber el objeto de tu solicitud, nuestro equipo
                  dará una respuesta lo más pronto posible, para consultar el estado de tu solicitud
                  de manera más detallada se ha asignado la queja <b>Nº {{ ticketId }}</b>
                </p>
                <br />
                <a routerLink="/home" routerLinkActive="true" class="btn btn-laurado-purple text-white"><i
                    class="fas fa-home"></i> Volver a la página principal</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <br />
  <br />
</div>

<!-- Política de privacidad y de uso -->
<ng-template #modalDialog let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Política de privacidad y de uso</h4>
    <button type="button" class="close" (click)="c('Cross click')">×</button>
  </div>
  <div class="modal-body">
    <div id="EntitiesContent">
      <div class="row row-space-20">
        <div class="col-md-12">
          <div class="card border-info-completed text-black m-b-10">
            <div class="card-body">
              <blockquote class="blockquote mb-0 onScrollBar" appCustomScroll #appScrollElement="appCustomScroll"
                style="height:250px; overflow-y:auto;" #scroll>
                <p style="text-align: justify;">El presente documento contiene la política de privacidad y tratamiento
                  de datos personales acerca del sitio web de la Defensoría del Consumidor Financiero.</p>
                <p style="text-align: justify;"><strong>1.- Defensoría del Cliente Laguado Giraldo Ltda</strong>, con
                  domicilio en la ciudad de Bogotá, correo electrónico: <a
                    href="mailto:reclamaciones@defensorialg.com.co">reclamaciones@defensorialg.com.co</a> y teléfonos:
                  (601) 5970412 – (601) 3736697 - 3203981187; será responsable de la información personal suministrada 
                  y contenida en el presente sitio web así como sus bases de datos.</p>
                <p style="text-align: justify;"><strong>2.-</strong> Quien presenta una queja ante la Defensoría del
                  Consumidor Financiero por el simple hecho de presentarla se da por sentado que autoriza compartir el
                  contenido de la queja con a la Entidad Financiera, pues de otra manera sería imposible atender la
                  solicitud que le interesa.&nbsp;</p>
                <p style="text-align: justify;"><strong>3.-</strong> El titular de la información acepta y autoriza 
                  de manera expresa e irrevocable a la Defensoría del Cliente Laguado Giraldo Ltda, incluyendo a 
                  terceros con quienes ésta tiene suscritos convenios con tal propósito, para la recolección y 
                  tratamiento de datos de carácter personal y reservado relacionados con la prestación, gestión, 
                  administración, actualización y mejora de los trámites, bienes y servicios de esta Defensoría. 
                  Esto incluye trámite de las quejas, elaboración de informes con destino a la E.V., al coordinador, 
                  a la Junta Directiva y a las áreas especializadas de la entidad financiera, o informes a la SFC, 
                  o a las autoridades de la República tales como jueces que conozcan de acciones de tutela y la 
                  Procuraduría General de la Nación, así como el tratamiento de datos para la elaboración y práctica 
                  de encuestas para medir indicadores de calidad en la prestación de los servicios de la Defensoría.</p>
                <p style="text-align: justify;"><strong>4.-</strong> El titular acepta y autoriza de manera expresa para 
                  que la Defensoría del Cliente Laguado Giraldo Ltda, directamente o a través de medios electrónicos, 
                  informáticos y telemáticos, realice la verificación y uso de la información suministrada por el 
                  afiliado/ciudadano en su documento de identidad y en los demás que aporte a la Defensoría del Cliente 
                  Laguado Giraldo Ltda, en las entidades u organismos pertinentes.</p>
                <p style="text-align: justify;"><strong>5.-</strong> La información obtenida sólo será usada para efectos 
                  propios de la Defensoría del Cliente Laguado Giraldo Ltda. y para las finalidades indicadas en este documento.</p>
                <p style="text-align: justify;"><strong>6.-</strong> El titular acepta y autoriza de manera expresa e 
                  irrevocable a la Defensoría del Cliente Laguado Giraldo Ltda, para la actualización de los datos de 
                  contacto contenidos en las bases de datos de la Defensoría con la información registrada en este formulario.</p>
                <p style="text-align: justify;"><strong>7.-</strong> El titular acepta y autoriza de manera expresa para 
                  que la Defensoría del Cliente Laguado Giraldo Ltda, envíe notificaciones y demás comunicaciones 
                  relacionadas con sus trámites y/o solicitudes a través de medios electrónicos, informáticos y telemáticos 
                  (incluye correo electrónico, página web, mensajes móviles).</p>
                <p style="text-align: justify;"><strong>8.-</strong> Los derechos que le asisten a los Titulares de la
                  información personal son los establecidos en el artículo 8° de la Ley 1581 de 2012, a saber:</p>
                <p style="text-align: justify;"><strong>a.-</strong> Conocer, actualizar y rectificar sus datos
                  personales frente a los Responsables del Tratamiento o Encargados del Tratamiento. Este derecho se
                  podrá ejercer, entre otros frente a datos parciales, inexactos, incompletos, fraccionados, que
                  induzcan a error, o aquellos cuyo tratamiento esté expresamente prohibido o no haya sido autorizado.</p>
                <p style="text-align: justify;"><strong>b.-</strong> Solicitar prueba de la autorización otorgada al
                  Responsable del Tratamiento salvo cuando expresamente se exceptúe como requisito para el Tratamiento,
                  de conformidad con lo previsto en el artículo 10 de la Ley 1581 de 2012.</p>
                <p style="text-align: justify;"><strong>c.-</strong> Ser informado por el Responsable del Tratamiento o
                  el Encargado del Tratamiento, previa solicitud, respecto del uso que le ha dado a sus datos
                  personales.</p>
                <p style="text-align: justify;"><strong>d.-</strong> Presentar ante la Superintendencia de Industria y
                  Comercio quejas por infracciones a lo dispuesto en la ley de protección de datos y las demás normas
                  que la modifiquen, adicionen o complementen.</p>
                <p style="text-align: justify;"><strong>e.-</strong> Revocar la autorización y/o solicitar la supresión
                  del dato cuando en el Tratamiento no se respeten los principios, derechos y garantías constitucionales
                  y legales. La revocatoria y/o supresión procederá cuando la Superintendencia de Industria y Comercio
                  haya determinado que en el Tratamiento el Responsable o Encargado han incurrido en conductas
                  contrarias a esta ley y a la Constitución;</p>
                <p style="text-align: justify;"><strong>f.-</strong> Acceder en forma gratuita a sus datos personales que
                  hayan sido objeto de Tratamiento.</p>
                <p style="text-align: justify;"><strong>9.-</strong> Las políticas de la DCF &nbsp;frente al tratamiento
                  de los datos personales son las siguientes:</p>
                <p style="text-align: justify;"><strong>(i)</strong> La DCF busca la protección de los derechos de los
                  Titulares de los Datos Personales; </p> 
                <p style="text-align: justify;"><strong>(ii)</strong> La DCF garantiza la&nbsp; confidencialidad,
                  integridad y seguridad de los Datos Personales;</p>
                <p style="text-align: justify;"><strong>(iii)</strong> La DCF usa los datos personales
                  para el trámite de quejas propuestas por los Titulares de los Datos Personales, de acuerdo con lo aquí
                  establecido;</p>
                <p style="text-align: justify;"><strong>(iv)</strong> La DCF garantiza al Titular, en todo tiempo, el pleno y efectivo
                  ejercicio de su derecho de hábeas data;</p>
                <p style="text-align: justify;"><strong>(v)</strong> La DCF informará al Titular sobre el uso
                  de los Datos Personales y la finalidad de la recolección de sus datos y de los derechos que le asisten
                  por virtud de la autorización que haya otorgado a La DCF;</p>
                <p style="text-align: justify;"><strong>(vi)</strong> La DCF conservará la
                  autorización dada por el Titular de los Datos y su información bajo la condiciones de seguridad
                  necesarias para impedir su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento;</p>
                <p style="text-align: justify;"><strong>(vii)</strong> La DCF garantizará que la información que transfiera 
                  o transmita a cualquier tercero autorizado (Encargado), dentro de los parámetros establecidos en la ley, 
                  sea veraz, completa, exacta, actualizada, comprobable, y comprensible;</p>
                <p style="text-align: justify;"><strong>(viii)</strong> Consultorías en La DCF
                  &nbsp;actualizará la información a quien transfiera o transmita la información;</p>
                <p style="text-align: justify;"><strong>(ix)</strong> La DCF rectificará la información cuando sea 
                  incorrecta y comunicará lo pertinente a cualquier tercero autorizado (Encargado);</p>
                <p style="text-align: justify;"><strong>(x)</strong> La DCF tramitará las consultas y reclamos formulados por
                  los Titulares de los Datos Personales en los términos señalados en su Manual Interno de Políticas y
                  Procedimientos en concordancia con lo establecido en la Ley;</p>
                <p style="text-align: justify;"><strong>(xi)</strong> La DCF realizará oportunamente la actualización, 
                  rectificación o supresión de los datos en los términos de la ley;</p>
                <p style="text-align: justify;"><strong>(xii)</strong> La DCF suministrará al Encargado del Tratamiento, 
                  según el caso, únicamente datos cuyo Tratamiento esté previamente autorizado de conformidad 
                  con lo previsto en la ley;</p>
                <p style="text-align: justify;"><strong>(xiii)</strong> La DCF exigirá al Encargado del Tratamiento en 
                  todo momento, el respeto a las condiciones de seguridad y privacidad de la información del Titular;</p>
                <p style="text-align: justify;"><strong>(xiv)</strong> La DCF informará al Encargado del Tratamiento 
                  cuando determinada información se encuentre en discusión por parte del Titular, una vez se haya 
                  presentado la reclamación y no haya finalizado el trámite respectivo;</p>
                <p style="text-align: justify;"><strong>(xv)</strong> La DCF informará a la autoridad de protección de datos cuando se
                  presenten violaciones a los códigos de seguridad y existan riesgos en la administración de la
                  información de los Titulares;</p>
                <p style="text-align: justify;"><strong>(xvi)</strong> La DCF cumplirá las instrucciones y
                  requerimientos que imparta la Superintendencia de Industria y Comercio.</p>
                <p style="text-align: justify;"><strong>10.-</strong> &nbsp;Los canales a través de los cuales los
                  titulares de la información podrán ejercer sus derechos a conocer, actualizar, rectificar, y suprimir
                  el dato y revocar la autorización dada a la DCF para tratar los datos personales son: a través del
                  correo electrónico <a
                    href="mailto:reclamaciones@defensorialg.com.co">reclamaciones@defensorialg.com.co</a>, en las
                  oficinas de la DCF ubicadas en la calle 70A N° 11-83 &nbsp;en Bogotá D.C. o en los teléfonos 
                  (601) 5970412 – (601) 3736697 - 3203981187; en la ciudad de Bogotá D.C.</p>
                <p style="text-align: justify;"><strong>11.-</strong> &nbsp;El procedimiento para conocer, actualizar,
                  rectificar y suprimir el dato y revocar la autorización que haya dado es el siguiente: usted podrá
                  solicitar a través de los canales de contacto ya citados la actualización, supresión, rectificación de
                  la información y/o efectuar consultas o reclamos relacionados con su información personal. La DCF
                  validará su identificación y la calidad en la que actúa y analizará, clasificará, y emitirá respuesta
                  a su solicitud en los tiempos establecidos en las normas vigentes y de acuerdo con las reglas
                  establecidas en las normas vigentes (ver artículos 14 y 15 de la Ley 1581 de 2012). La respuesta a su
                  consulta o queja será informada al Titular de la información por el mismo medio por el cual se recibe
                  la solicitud o por el medio que el titular de la información especifique en su solicitud.</p>
                <p style="text-align: justify;"><strong>12.-</strong> Nos reservamos el derecho de efectuar en cualquier
                  momento modificaciones o actualizaciones al presente aviso de privacidad. Estas modificaciones estarán
                  disponibles al público a través de la página web defensorialg.com.co o se las haremos llegar a la
                  última dirección física o electrónica que nos haya proporcionado.</p>
                <p style="text-align: justify;"><strong>13.-</strong> La DCF no se hace responsable de la información que
                  suministren los quejosos, la responsabilidad de los datos, información y documentos aportados corre
                  por cuenta de los quejosos, quienes aceptan en su integridad los presentes TÉRMINOS Y CONDICIONES.</p>
                <p style="text-align: justify;"><strong>14.-</strong> La Ley aplicable será la colombiana, en especial al
                  Ley 1581 de 2012 sobre tratamiento de datos personales.</p>
                <p style="text-align: justify;"><strong>15.-</strong> Esta POLÍTICA DE PRIVACIDAD &nbsp;Y DE USO se
                  encuentra vigente desde el año 2018 hasta la fecha.</p>
              </blockquote>
            </div>
          </div>
        </div>
        <div *ngIf="!reclamation_accept_terms_and_conditions.value" class="col-md-12 text-center">
          <button [disabled]="appScrollElement.disableBtn" (click)="acceptTerms();c('Close click')"
            class="btn btn-primary pull-right">Aceptar términos y condiciones</button>
          <button [disabled]="appScrollElement.disableBtn" (click)="rejectTerms();c('Close click')"
            class="btn btn-danger pull-left">No Acepto</button>
        </div>
        <div *ngIf="reclamation_accept_terms_and_conditions.value == true" class="col-md-12 text-center">
          <div class="card border-success text-success m-b-10">
            <div class="card-body">
              <blockquote class="blockquote mb-0">
                <p class="mb-2">Has aceptado nuestras politicas de tratamiento de datos.</p>
              </blockquote>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</ng-template>