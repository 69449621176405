import { Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import swal from 'sweetalert2';
import { CompanyQuery } from 'src/app/states/company';
import { ExternalService } from 'src/app/services/external.service';

@Component({
  selector: 'not_found',
  templateUrl: './not_found.html'
})

export class NotFoundPage implements OnDestroy, OnInit  {

  constructor(private router: Router) {}

  async ngOnInit() {
  }

  ngOnDestroy() {
  }
}
