import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from './base.service';
import { Audit } from '../states/audit';
import { Utils } from '../common/utils';

@Injectable()
export class AuditService extends BaseService {

    constructor(public http: HttpClient) {
        super(http);
    }

    async listAuditEntries(filter: Object) {      
        const queryParams = Utils.parseFilterToQueryString(filter);
        const sma_url = `${this.baseUrl}/audits${queryParams}`;
        return await this.paginatedGet<Audit>(sma_url);
    }

}