export interface IColumnHeaders {
    title: string;
    name: string;
    sort?: boolean;
}

export const ColumnHeaders: Array<IColumnHeaders> = [
    {
        title: 'Consecutivo',
        name: 'consecutive',
    },
    {
        title: 'Fecha de creación',
        name: 'creationDate',
        sort: false,
    }, 
    {
        title: 'Fecha de respuesta entidad',
        name: 'responseEntityDate',
        sort: false,
    }, 
    {
        title: 'Entidad',
        name: 'entityName',
        sort: false,
    },
    {
        title: 'Estado actual',
        name: 'stateTicketName',
        sort: false,
    }
];

export const PaginationConfig = () => {
    return {
        baseConfig: {
            paging: true,
            sorting: { columns: ColumnHeaders },
            className: ['table-striped', 'table-bordered', 'm-b-0']
        },
        maxSize: 3,
        numPages: 1
    }
}
