import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Utils } from 'src/app/common/utils';
import { TicketService } from 'src/app/services/ticket.service';
import { TicketLogQuery } from 'src/app/states/ticket_log';
import { NgxSpinnerService } from 'ngx-spinner';
import { TicketQuery } from 'src/app/states/ticket';

@Component({
  selector: 'complaint_logs',
  templateUrl: './complaint_logs.html'
})

export class ComplaintLogsPage implements OnInit {
  public ticketLogs$ = this.ticketLogQuery.ticketLog$();
  public ticketData$ = this.ticketQuery.ticket$();

  constructor(
    private route: ActivatedRoute,
    private ticketLogQuery: TicketLogQuery,
    private spinner: NgxSpinnerService,
    private ticketService: TicketService,
    private ticketQuery: TicketQuery,
    ) {

  }

  public async ngOnInit() {
    await this.fetchData();
  }

  public async fetchData() {
    this.spinner.show();
    const params = this.route.snapshot.params;
    await this.ticketService.getTicketLogs(params['id'])
    await this.spinner.hide();
  }

  public getFormatedDate(dateString: string) {
    return Utils.getDateWithHourFromString(dateString);
  }

  public getFormatedLog(state: string, userName, ticketData, logData) {
    return Utils.getLogByData(state, userName, ticketData, logData)
  }
}
