<div class="row">
  <div class="col-md-12 col-xs-12 col-sm-12">
    <panel variant="danger" title="Logs de la solicitud" noButton="true" bodyClass="border-danger bg-base-gray">
      <ul class="timeline" *ngIf="ticketData$ | async as ticketData">
        <li *ngFor="let ticketLog of ticketLogs$ | async">
          <div class="timeline-time">
            <span class="date">{{getFormatedDate(ticketLog.date).date}}</span>
            <span class="time">{{getFormatedDate(ticketLog.date).hour}}</span>
          </div>
          <div class="timeline-icon">
            <a href="javascript:;">&nbsp;</a>
          </div>
          <div class="timeline-body">
            <div class="timeline-header">
              <span class="userimage text-center">
                <i class="fas fa-clipboard-list fa-2x"></i>
              </span>
              <span class="username">
                <a href="javascript:;">{{ ticketLog.user.name | titlecase }}</a>
                <small></small>
              </span>
              <span class="pull-right text-muted">{{getFormatedLog(ticketLog.stateTicket.id, ticketLog.user.name, ticketData, ticketLog).messageHeader}}</span>
            </div>
            <div class="timeline-content">
              <div [innerHTML]="getFormatedLog(ticketLog.stateTicket.id, ticketLog.user.name, ticketData, ticketLog).messageContent"></div>
            </div>
            <div class="timeline-footer">
              <a class="m-r-15 text-inverse-lighter" href="javascript:;">
                <i class="fa fa-laptop fa-fw fa-lg m-r-3"></i> Consecutivo: {{ticketLog.ticketConsecutive}}
              </a>
              <a class="m-r-15 text-inverse-lighter float-right" href="javascript:;">
                <i class="fa fa-clock fa-fw fa-lg m-r-3"></i> Historico: {{ticketLog.id}}
              </a>
            </div>
          </div>
        </li>
      </ul>
    </panel>
  </div>
</div>