export interface IColumnHeaders {
  title: string;
  name: string;
  sort?: boolean;
}

export const BaseTableColumnHeaders: Array<IColumnHeaders> = [
  {
    title: 'ID',
    name: 'id',
  },
  {
    title: 'Tipo de documento',
    name: 'typeDocumentName',
    sort: false,
  },
  {
    title: 'NIT/Documento',
    name: 'serial',
    sort: false,
  },
  {
    title: 'Rol',
    name: 'rolName',
    sort: false,
  },
  {
    title: 'Nombre completo',
    name: 'name',
    sort: false,
  },
  {
    title: 'Correo',
    name: 'email',
    sort: false,
  }
];

export const PaginationConfig = (role) => {
  const columns = getRoleColumnHeaders(role);
  function getRoleColumnHeaders(role) {
    switch (role) {
      case 'ADMIN':
        return BaseTableColumnHeaders;
      default:
        return BaseTableColumnHeaders;
    }
  }
  return {
    baseConfig: {
      paging: true,
      sorting: {columns: columns},
      className: ['table-striped', 'table-bordered', 'm-b-0']
    },
    maxSize: 3,
    numPages: 1
  }
}