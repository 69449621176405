<div class="row" *ngIf="ticketData$ | async as ticketData">
  <div class="col-md-6 col-xs-12 col-sm-12">
    <panel variant="primary" title="Datos de la solicitud" noButton="true" bodyClass="border-blue">
      <table class="table table-striped">
        <tbody>
          <tr>
            <td>Consecutivo</td>
            <td><b>{{ticketData.consecutive}}</b></td>
          </tr>
          <tr>
            <td>Fecha de radicación</td>
            <td>{{ticketData.creationDate | date: 'yyyy-MM-dd'}}</td>
          </tr>
          <tr>
            <td>Tipo de solicitud</td>
            <td>{{ticketData.typeTicketName}}</td>
          </tr>
          <tr>
            <td>Medio de recepción</td>
            <td>{{ticketData.receivingMediumName}}</td>
          </tr>
          <tr>
            <td>Canal</td>
            <td>{{ticketData.channelName}}</td>
          </tr>
          <tr>
            <td>Producto digital</td>
            <td>{{ticketData.digitalProduct != 'YES' ? 'NO' : 'SI'}}</td>
          </tr>
          <tr *ngIf="ticketData.stateTicket == 4">
            <td>Fecha límite</td>
            <td>{{ticketData.maxDate}}</td>
          </tr>
          <ng-template *ngIf="!roleDetail.isMoanful">
            <tr>
              <td>Tiempo restante para emitir respuesta</td>
              <td>{{ticketData.counterDays}} días hábiles</td>
            </tr>
            <tr>
              <td>Solicita Prórroga</td>
              <td><b>{{!ticketData.extesionMaxDate ? 'No' : 'Si'}}</b></td>
            </tr>
            <tr>
              <td>Fecha límite para emitir respuesta</td>
              <td><b>{{ticketData.extesionMaxDate ? ticketData.extesionMaxDate : ticketData.maxDate | date: 'yyyy-MM-dd'}}</b></td>
            </tr>
            <tr>
              <td>Entidad</td>
              <td>{{ticketData.entityName}}</td>
            </tr>
            <tr>
              <td>Producto</td>
              <td><b>{{ticketData.productName}}</b></td>
            </tr>
            <tr>
              <td>Motivo</td>
              <td> {{ ticketData.typeFormat378Name ? ticketData.typeFormat378Name : '-' }} </td>
            </tr>

          </ng-template>
          
          
        </tbody>
      </table>
    </panel>
  </div>
  
  <div class="col-md-6 col-xs-12 col-sm-12">
    <panel variant="info" title="Estados de la solicitud" noButton="true" bodyClass="border-info">
      <table class="table table-striped">
        <tbody *ngIf="ticketLogs$ | async as ticketLogs">
          <tr>
            <td>Estado actual de solicitud</td>
            <td><b>{{ticketData.stateTicketName}}</b></td>
          </tr>          
          <tr>
            <td>Fecha de envio a la entidad</td>
            <td><b>{{ticketData.sendToEntity}}</b></td>
          </tr>
          <tr *ngIf="haveState(ticketLogs, ticketStates.PRONOUNCEMENT)">
            <td>Fecha de pronunciamiento</td>
            <td><b>{{getCreationDateState(ticketLogs, ticketStates.PRONOUNCEMENT_APRPOVE)}}</b></td>
          </tr>
          <tr *ngIf="haveState(ticketLogs, ticketStates.SPOKESPERSON)">
            <td>Fecha de vocería</td>
            <td><b>{{getCreationDateState(ticketLogs, ticketStates.SPOKESPERSON)}}</b></td>
          </tr>
          <tr *ngIf="haveState(ticketLogs, ticketStates.ATTONERY_ANSWER)">
            <td>Fecha de apremio</td>
            <td><b>{{getCreationDateState(ticketLogs, ticketStates.ATTONERY_ANSWER)}}</b></td>
          </tr>          
        </tbody>
      </table>
    </panel>
  </div>
</div>