import { Directive, HostListener, ElementRef} from '@angular/core';

@Directive({
  selector: '[appCustomScroll]',
  exportAs: 'appCustomScroll'
})
export class CustomScrollDirective {

    disableBtn = true;
    top: number;
    offSetHeight: number;
    scrollHeight: number;

    errorMargin: number = 10;

    @HostListener('scroll', ['$event'])
    onScrollEvent($event) {
      var scrollPosition = $event.target.offsetHeight + $event.target.scrollTop + this.errorMargin;
      if (scrollPosition >= $event.target.scrollHeight) {
        this.disableBtn = false;
      }
    }
}
