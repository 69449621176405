import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { TicketAnswer, TicketAnswerState, TicketAnswerStore } from './ticket_answer.store';
import { BaseQuery } from '../base/base.query';
import { Utils } from 'src/app/common/utils';

@Injectable({
  providedIn: 'root'
})
export class TicketAnswerQuery extends BaseQuery<TicketAnswerState> {
  constructor(protected store: TicketAnswerStore) {
    super(store);
  }

  typeFilter = null;
  naturalActive = () => this.getActive() as TicketAnswer;
  active$ = () => this.selectActive();

  getSnapshot = () => this.getValue();

  isLoading$ = () => this.selectLoading();



  ticketAnswer$ = () => this.selectTicketAnswer();
  ticketAnswerCount$ = () => this.selectCount(it => it != null);
  ticketAnswerCountByType$ = (type) => this.selectCountByType(type);
  ticketAnswerCountByNoInType$ = (type) => this.selectCountByNoInType(type);

  selectCountByType(type) {
    return this.filterByType(type).pipe(
      map(x => x.length)
    )
  }

  selectCountByNoInType(types) {
    return this.getNotInTypesFilter(types).pipe(
      map(x => x.length)
    )
  }

  selectTicketAnswer() {
    return this.selectAll({
      filterBy: [
        this.typeFilter ? entity => entity.typeExtraRequest.id == (this.typeFilter) : null
      ].filter(x => x !== null)
    }).pipe(
      map(it => it.filter(x => x != null)),
    )
  }

  selectTicketAnswerNoType() {
    return this.selectAll({
      filterBy: [
        this.typeFilter ? entity => !this.typeFilter.includes(entity.typeExtraRequest.id) : null
      ].filter(x => x !== null)
    }).pipe(
      map(it => it.filter(x => x != null)),
    )
  }

  getNotInTypesFilter(types) {
    return this.selectAll().pipe(
      map(it => it.filter(x => x != null)),
    ).pipe(
      map(x => x.filter(y => !types.includes(y.typeExtraRequest.id)))
    );
  }

  filterByType(type) {
    return this.selectAll().pipe(
      map(it => it.filter(x => x != null)),
    ).pipe(
      map(x => x.filter(y => y.typeExtraRequest.id == (type)))
    );
  }

  setTypeFilter(value) {
    this.typeFilter = this.switchValue(value);
    if (typeof this.typeFilter == 'number') {
      this.ticketAnswer$ = () => this.selectTicketAnswer();
    } else {
      this.ticketAnswer$ = () => this.selectTicketAnswerNoType();
    }
  }

  private switchValue(value) {
    switch (value) {
      case 'all':
        return null;
      case 'attonery_answer':
        return Utils.extraTicketStates.APREMIO_REQUEST;
      case 'pronouncement':
        return Utils.extraTicketStates.PRONOUNCEMENT;
      case 'rejection':
        return Utils.extraTicketStates.REJECTION;
      case 'spokesperson':
        return Utils.extraTicketStates.VOCERIA_CREATION;
      default:
        return [
          Utils.extraTicketStates.VOCERIA_CREATION,
          Utils.extraTicketStates.REJECTION,
          Utils.extraTicketStates.PRONOUNCEMENT,
          Utils.extraTicketStates.APREMIO_REQUEST,
        ];
    }
  }

}
