import { StoreConfig, EntityState, EntityStore } from '@datorama/akita';
import { Injectable } from '@angular/core';

export interface SpecialCondition  {
  id: number;
  name: string;
  code: string;
  state: string
}


export interface SpecialConditionState extends EntityState<SpecialCondition, number> {
    id: number;
    name: string;
    code: string;
    state: string;
}

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'SpecialCondition' })
export class SpecialConditionStore extends EntityStore<SpecialConditionState> {
  constructor() {
    super();
    this.set({1: null}, {activeId: 1});
  }
}