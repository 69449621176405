<style>
  .form-control {
    min-height: 36px;
  }

  .col-form-label {
    font-weight: bold;
  }
</style>
<ol class="breadcrumb float-xl-right">
  <li class="breadcrumb-item"><a href="javascript:;">Inicio</a></li>
</ol>

<h1 class="page-header">Listado de solicitudes</h1>

<panel title="Solicitudes realizadas" noButton="true">
  <ng-container *ngIf="getComponentLoading()">
    <div class="row alert alert-info">
      <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
        <a class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12 text-center btn btn-info text-white m-r-10"
          (click)="open(modalDialog)">
          <div class="">
            <i class="fas fa-search fa-5x"></i>
          </div>
          Deseo filtrar resultados
        </a>
      </div>

      <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
        <a class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12 text-center btn btn-success text-white"
          (click)="downloadExcel()">
          <div class="">
            <i class="fas fa-file-excel fa-5x"></i>
          </div>
          Generar reporte de solicitudes
        </a>
      </div>

    </div>
    <ng-container outsideBody>
      <div class="table-responsive text-nowrap">
        <table class="table table-reversed table-hover">
          <thead>
            <tr>
              <th *ngFor="let column of columns">{{column.title}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="paginateData.data.length == 0">
              <td [attr.colspan]="columns.length" style="text-align: center;">
                <i class="fa fa-exclamation-circle fa-3x m-t-15"></i><br />
                <h5 class="m-t-15">No se han encontrado resultados</h5>
              </td>
            </tr>
            <tr *ngFor="let row of paginateData.data">

              <td *ngFor="let keyD of columns" [innerHTML]="getFormatedRow(row, keyD.name)" [class]="getRowClass(row)"
                (click)="onCellClick(row)"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
    <ng-container footer>
      <div class="clearfix"></div>
      <ngb-pagination *ngIf="config.baseConfig.paging" class="d-flex justify-content-center m-t-15"
        (pageChange)="changePage($event, true)" [page]="filters.page" [currentPage]="filters.page"
        [maxSize]="config.maxSize" [boundaryLinks]="true" [collectionSize]="paginateData.totalRegister" [rotate]="true"
        (numPages)="config.numPages = $event"></ngb-pagination>
    </ng-container>
    <div class="row">
      <div class="clearfix"></div>
      <div class="alert py-0 mb-0 width-full">
        <div class="col-md-12 text-center font-weight-bold">
          <label class="col-form-label text-center" *ngIf="config.baseConfig.paging">Página: {{filters.page}} /
            {{paginateData.totalPage}}</label>
        </div>
      </div>
    </div>
  </ng-container>
</panel>

<ng-template #modalDialog let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Filtrar solicitudes</h4>    
    <!--button type="button" class="close" (click)="c('Cross click')">×</button-->
  </div>
  <div class="modal-body">
    <form [formGroup]="filtersForm" (ngSubmit)="submitFilters(c)" class="margin-bottom-0">
      <div class="row">
        <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
          <div class="form-group">
            <label class="col-form-label">Consecutivo</label>
            <input formControlName="consecutive" class="form-control" placeholder="Ej: 20-01-0010" type="search">
          </div>
        </div>

        <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
          <div class="form-group" *ngIf="ticketStates$ | async as ticketStates">
            <label class="col-form-label">Estado de la solicitud</label>
            <ng-select appearance="outline" [searchable]="true" formControlName="stateTicket" [items]="ticketStates"
              bindLabel="detalle" [clearable]="true" bindValue="detalle" placeholder="Seleccione el estado">
            </ng-select>
          </div>
        </div>

        <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6" *ngIf="!roleDetail.isEntity">
          <div class="form-group" *ngIf="entities$ | async as entities">
            <label class="col-form-label">Entidad</label>
            <ng-select appearance="outline" [searchable]="true" formControlName="entity" [items]="entities"
              bindLabel="name" [clearable]="true" bindValue="id" placeholder="Seleccione la entidad">
            </ng-select>
          </div>
        </div>

        <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6" *ngIf="!roleDetail.isEntity">
          <div class="form-group" *ngIf="lawyers$ | async as lawyers">
            <label class="col-form-label">Abogado asignado</label>
            <ng-select appearance="outline" [searchable]="true" formControlName="lawyerId" [items]="lawyers"
              bindLabel="fullName" [clearable]="true" bindValue="id" placeholder="Seleccione el abogado">
            </ng-select>
          </div>
        </div>

        <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6" *ngIf="!roleDetail.isMoanful">
          <div class="form-group">
            <label class="col-form-label">Documento del solicitante</label>
            <input formControlName="moanfulSerial" class="form-control" placeholder="Ej: 10202703030" type="text">
          </div>
        </div>

        <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6" *ngIf="!roleDetail.isMoanful">
          <div class="form-group">
            <label class="col-form-label">Nombre del solicitante</label>
            <input formControlName="moanfulName" class="form-control" placeholder="Ej: Pedro Perez" type="text">
          </div>
        </div>

        <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12">
          <hr>
          <div class="row">
            <label class="col-form-label col-md-6 p-t-0 p-b-0">Fecha de generación</label>
            <div style="right: 0px !important; position: absolute;">
              <button type="button" (click)="setLastDaysForFilter(30)" class="btn btn-default btn-xs m-r-5">Ult. 30 dias</button>
              <button type="button" (click)="setCurrentYearForFilter()" class="btn btn-default btn-xs m-r-5">Año actual</button>
              <button type="button" (click)="setCurrentMonthForFilter()" class="btn btn-default btn-xs m-r-5">Mes actual</button>              
              <button type="button" title="Limpiar" (click)="clearDatesForFilter()" class="btn btn-danger btn-xs m-r-5"><span
                  class="fas fa-trash" aria-hidden="true"></span></button>
            </div>
          </div>

          <div class="row" formGroupName="creationDate">
            <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
              <div class="form-group">
                <label class="col-form-label" style="font-weight: lighter;">Desde</label>
                <input formControlName="creationDateStart" class="form-control" type="date" max="{{maxDate}}">
              </div>
            </div>
            <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
              <div class="form-group">
                <label class="col-form-label" style="font-weight: lighter;">Hasta</label>
                <input formControlName="creationDateEnd" class="form-control" type="date" max="{{maxDate}}">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="row">
          <button type="button" class="btn btn-default m-r-5" (click)="c('Close click')">Cerrar</button>
          <button type="reset" class="btn btn-danger m-r-5" (click)="resetFilterForm()">Limpiar</button>
          <button type="submit" class="btn btn-primary">
            <i class="fas fa-search"></i> Mostrar resultados
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>