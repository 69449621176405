import { AuthService } from 'src/app/services/auth.service';
import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { map, shareReplay, tap, filter, startWith, switchMap, flatMap } from 'rxjs/operators';
import { routes } from 'src/app/config/routes';
import { Observable } from 'rxjs-compat';
import { CompanyState, CompanyStore } from './company.store';

@Injectable({
  providedIn: 'root'
})
export class CompanyQuery extends QueryEntity<CompanyState> {
  constructor(protected store: CompanyStore) {
    super(store);
  }

  active$ = () => this.selectCompany().pipe(shareReplay(1));

  getSnapshot = () => this.getValue();

  isLoading$ = () => this.selectLoading();

  company$ = () => this.selectCompany();

  selectCompany() {
    return this.selectAll().pipe(
      map(it => it.filter(x => x != null))
    )
  }

  setLoading(isLoading) {
    this.store.setLoading(isLoading);
  }


  setState(data, activeId) {
    this.store.set(data, activeId);
  }

}
