<!-- begin breadcrumb -->
<ol class="breadcrumb float-xl-right">
  <li class="breadcrumb-item"><a href="javascript:;">Inicio</a></li>
</ol>
<!-- end breadcrumb -->
<!-- begin page-header -->
<h1 class="page-header">Solicitudes <small>Listado de solicitudes realizadas...</small></h1>
<!-- end page-header -->

<!-- begin panel -->
<panel title="Solicitudes realizadas" noButton="true">
  <ng-container *ngIf="getComponentLoading()">
    <div class="row alert alert-info">
      <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
        <a class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12 text-center btn btn-info text-white m-r-10"
          (click)="open(modalDialog)">
          <div class="">
            <i class="fas fa-search fa-5x"></i>
          </div>
          Filtrar resultados
        </a>
      </div>

      <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
        <a class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12 text-center btn btn-success text-white"
          routerLink="/user/detail/new" routerLinkActive="active">
          <div class="">
            <i class="fas fa-user-plus fa-5x"></i>
          </div>
          Agregar nuevo usuario
        </a>
      </div>

    </div>
    <ng-container outsideBody>
      <div class="table-responsive text-nowrap">
        <table class="table table-reversed table-hover">
          <thead>
            <tr>
              <th *ngFor="let column of columns">{{column.title}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of paginateData.data">
              <td *ngFor="let keyD of columns" [innerHTML]="getFormatedRow(row, keyD.name)" [class]="getRowClass(row)"
                (click)="onCellClick(row)"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
    <ng-container footer>
      <div class="clearfix"></div>
      <ngb-pagination *ngIf="config.baseConfig.paging" class="d-flex justify-content-center m-t-15"
        (pageChange)="changePage($event, true)" [page]="filters.page" [currentPage]="filters.page"
        [maxSize]="config.maxSize" [boundaryLinks]="true" [collectionSize]="paginateData.totalRegister" [rotate]="true"
        (numPages)="config.numPages = $event"></ngb-pagination>
    </ng-container>
    <div class="row">
      <div class="clearfix"></div>
      <div class="alert alert-info width-full">
        <div class="col-md-12 text-center">
          <label class="col-form-label text-center" *ngIf="config.baseConfig.paging">Página: {{filters.page}} /
            {{paginateData.totalPage}}</label>
        </div>
      </div>
    </div>
  </ng-container>
</panel>

<!-- Filters Modal -->
<ng-template #modalDialog let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Filtros disponibles</h4>
    <button type="button" class="close" (click)="c('Cross click')">×</button>
  </div>
  <form [formGroup]="filtersForm" (ngSubmit)="submitFilters(c)" class="margin-bottom-0">
    <div class="modal-body">
      <div class="row">
        <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
          <div class="form-group">
            <label class="col-form-label">ID de usuario</label>
            <input formControlName="id" class="form-control m-b-5" placeholder="Ej: 123456" type="search" tabindex=”0”>
          </div>
        </div>
        <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
          <div class="form-group">
            <label class="col-form-label">Nº de Documento</label>
            <input formControlName="serial" class="form-control m-b-5" placeholder="Ej: 10202703030" NumbersOnly
              type="text" tabindex=”1”>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="!roleDetail.isEntity && !roleDetail.isLawyer && !roleDetail.isMoanful">
        <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
          <div class="form-group">
            <label class="col-form-label">Nombre</label>
            <input formControlName="name" class="form-control m-b-5" placeholder="Ej: Camilo" type="search"
              tabindex=”2”>
          </div>
        </div>
        <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
          <div class="form-group">
            <label class="col-form-label">Apellido</label>
            <input formControlName="lastname" class="form-control m-b-5" placeholder="Ej: Castro" type="search"
              tabindex=”2”>
          </div>
        </div>
        <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12">
          <div class="form-group">
            <label class="col-form-label">Correo electrónico</label>
            <input formControlName="email" class="form-control m-b-5" placeholder="Ej: pepito@gmail.com" type="email"
              tabindex=”2”>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary m-r-10">Filtrar</button>
      <button type="reset" class="btn btn-danger m-r-10" (click)="resetForm()">Limpiar</button>
      <button type="submit" class="btn btn-default" (click)="c('Close click')">Cerrar</button>
    </div>
  </form>
</ng-template>