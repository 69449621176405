import { Inject, Injectable, Injector } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../services/auth.service';

@Injectable()
export class TokenFactory {
    private authService: AuthService;
    private cookieService: CookieService;

    public skipWhenExpired: boolean = true;
    public whitelistedDomains: string[];
    public blacklistedRoutes: string[];

    constructor(inj: Injector) {
        // https://github.com/angular/angular/issues/18224
        // https://github.com/angular/angular/issues/18224#issuecomment-316951787
        setTimeout(() => {
            this.authService = inj.get(AuthService);
            this.cookieService = inj.get(CookieService);

            // this.whitelistedDomains = this.configService.get('environment.jwt.whitelistedDomains');
            this.whitelistedDomains = [];
            // this.blacklistedRoutes = this.configService.get('environment.jwt.blacklistedRoutes');
            this.blacklistedRoutes = [];
        });
    }

    public tokenGetter = () => this.authService.getAccessToken();

}