import { ApiResponse, NoValidatedRoutes } from '../services/base.service';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import swal from 'sweetalert2';
import { AuthService, LoginBody } from '../services/auth.service';
import { HeaderComponent } from '../components/header/header.component';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

export type ApiError<T> = HttpErrorResponse & ApiResponse<T>;

export class HttpErrorInterceptor {

  constructor(
    private authService: AuthService,
    private router: Router,
    private spinner: NgxSpinnerService,
  ) {

  }

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private defaultApplicationHeaders = {};

  buildRequestHeaders(request: HttpRequest<any>): HttpHeaders {
    let headers = this.defaultApplicationHeaders;
    if (this.authService.getAccessToken() !== null) {
      const authHeaderTpl = `Bearer ${this.authService.getAccessToken()}`;
      headers['Authorization'] = authHeaderTpl;
    }
    if (request.url.includes('/refresh')) {
      delete headers['Authorization'];
    }
    return new HttpHeaders(headers);
  }

  private addToken(request: HttpRequest<any>, token: string) {
    const headers = this.buildRequestHeaders(request);
    return request.clone({ headers });
  }

  private async handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    this.isRefreshing = false;
    if (request.url.includes('api/refresh')) {
      this.authService.logout();
      this.router.navigate(['login']);
    }
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      this.spinner.show();
      const token = await this.authService.refreshToken();
      this.spinner.hide();
      this.isRefreshing = false;
      this.refreshTokenSubject.next(token.accessToken);
      return await next.handle(this.addToken(request, token.accessToken)).toPromise();
    } else {
      const token = await this.refreshTokenSubject.toPromise();
      this.isRefreshing = true;
      this.spinner.hide();
      return await next.handle(this.addToken(request, token)).toPromise();
    }
  }

  private handle400Error(errorResponse: HttpErrorResponse) {
    var errorHeader = 'INVALID_FORMAT';
    var errorMsg = 'El recurso que intentas Buscar/Enviar no es válido';

    if (errorResponse.error && errorResponse.error.error) {
      errorHeader = 'INVALID_OPERATION';
      errorMsg = errorResponse.error.error;
    }

    return this.doAlert(errorHeader, errorMsg);
  }

  private doAlert(code, error) {
    swal.fire(
      'Ooops!!!',
      `<small style="font-size: 10px;">${code}</small><br/> ${error}`,
      'error'
    );
    return `Error: ${error}`
  }

  async intercept(request: HttpRequest<any>, next: HttpHandler) {
    if (this.authService.getAccessToken() !== null) {
      if (NoValidatedRoutes.find(x => request.url.includes(x)) === undefined) {
        request = this.addToken(request, this.authService.getAccessToken());
      }
    }
    return await next.handle(request)
      .pipe(
        catchError(async (error: ApiError<any>) => {
          let errorMsg = '';          
          if (error.error instanceof ErrorEvent) {
            errorMsg = this.doAlert(0, error.error.message);
          } else {
            if (error instanceof HttpErrorResponse && error.status === 401 && !request.url.includes('/login')) {
              return await this.handle401Error(request, next);
            }

            if (error instanceof HttpErrorResponse && error.status === 417 && request.url.includes('/verification')) {
              return `Error: ${error}`;
            }
            
            if (error instanceof HttpErrorResponse && error.status === 400 && !request.url.includes('/login')) {
              errorMsg = this.handle400Error(error);            
            } else if (error instanceof HttpErrorResponse && error.status === 0 && !request.url.includes('/login')) {
              errorMsg = this.doAlert('UNKNOWN_ERROR', 'Parece que algo anda mal, por favor intenta nuevamente.');
            } else {
              if (error instanceof HttpErrorResponse && !request.url.includes('/external/userMoanful/')) {
                const errorContent = error.error;
                if (errorContent !== null) {
                  if (errorContent.error !== undefined) {
                    errorMsg = this.doAlert(errorContent.code, errorContent.error);
                  } else {
                    errorMsg = this.doAlert(error.status, error.message);
                  }
                } else {
                  errorMsg = this.doAlert(0, 'Parece que algo anda mal, por favor consulta a la entidad o al administrador');
                }
              } else {
                errorMsg = this.doAlert(0, 'Parece que algo anda mal, por favor consulta a la entidad o al administrador');
              }
            }
          }

          return throwError(new Error(errorMsg));
        })
      ).toPromise();
  }
}
