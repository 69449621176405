import { guid, StoreConfig, Store, EntityState, EntityStore } from '@datorama/akita';
import pageSettings from '../../config/page-settings';
import { Injectable } from '@angular/core';

export interface TypeTicket  {
  id: number;
  name: string;
  state: string;
  minDays: number;
  maxDays: number;
}

export interface TypeTicketState extends EntityState<TypeTicket, number> {
  id: number;
  name: string;
  state: string;
  minDays: number;
  maxDays: number;
}

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'TypeTicket' })
export class TypeTicketStore extends EntityStore<TypeTicketState> {
  constructor() {
    super();
    this.set({1: null}, {activeId: 1});
  }
}
