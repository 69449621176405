import { AuthService } from 'src/app/services/auth.service';
import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { map, shareReplay, tap, filter, startWith, switchMap, flatMap } from 'rxjs/operators';
import { routes } from 'src/app/config/routes';
import { Department, DepartmentState, DepartmentStore } from './department.store';
import { Observable } from 'rxjs-compat';
import { Utils } from 'src/app/common/utils';

@Injectable({
  providedIn: 'root'
})
export class DepartmentQuery extends QueryEntity<DepartmentState> {
  constructor(protected store: DepartmentStore) {
    super(store);
  }

  active$ = () => this.selectDepartment().pipe(shareReplay(1));

  getSnapshot = () => this.getValue();

  isLoading$ = () => this.selectLoading();

  department$ = () => this.selectDepartment().pipe(shareReplay(1));
  
  selectDepartment() {
    return this.selectAll().pipe(
      map(it => it.filter(x => x != null)),
      map(this.getCityString),
    )
  }

  getCityString(departments: Department[]) {
    const map_ = departments.map(
      x => {
        if(typeof x.cities == 'undefined') { return []; }
        return x.cities.map(it => ({ id: it.id, name: `${it.name} / ${x.name}`.toUpperCase() }))
      }
    )
    return Utils.flatArray(map_)
  }

  setLoading(isLoading) {
    this.store.setLoading(isLoading);
  }


  setState(data, activeId) {
    this.store.set(data, activeId);
  }

}
