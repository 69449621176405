import { BaseService, ApiResponse, BaseEntity } from './base.service';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TypePerson, TypePersonQuery } from '../states/type_person';
import { Company, CompanyQuery } from '../states/company';
import { DepartmentQuery, Department } from '../states/department';
import { EducationLevelQuery, EducationLevel } from '../states/education_level';
import { EntityProductQuery, EntityProduct } from '../states/entity_product';
import { GenderQuery, Gender } from '../states/gender';
import { TypeComplaintQuery, TypeComplaint } from '../states/type_complaint';
import { AttachedFilesJsonResponse, AttachedFilesRequestPayload, Position, Profession, RecieveMedium, RejectionType, Role, TicketJsonResponse, TicketRequestPayload, TicketState, TypeFormat, UserMoanfulDataJsonResponse } from '../interfaces/external.interface';
import { TypeTicket, TypeTicketQuery } from '../states/type_ticket';
import { TypeFormatQuery } from '../states/type_format';
import { RejectionTypeQuery } from '../states/rejection_type';
import { TicketStateQuery } from '../states/ticket_state';
import { Lawyer, LawyerQuery } from '../states/lawyer';
import { RecieveMediumQuery } from '../states/recieve_medium';
import { RoleQuery } from '../states/role';
import { PositionQuery } from '../states/position';
import { ProfessionQuery } from '../states/profession';
import { Channel, ChannelQuery } from '../states/channel';
import { SpecialCondition, SpecialConditionQuery } from '../states/special_condition';

@Injectable()
export class ExternalService extends BaseService {

  constructor(
    public http: HttpClient,
    private typePersonQuery: TypePersonQuery,
    private departmentQuery: DepartmentQuery,
    private genderQuery: GenderQuery,
    private educationLevelQuery: EducationLevelQuery,
    private entityProductQuery: EntityProductQuery,
    private channelQuery: ChannelQuery,
    private typeComplaintQuery: TypeComplaintQuery,
    private typeTicketQuery: TypeTicketQuery,
    private companyQuery: CompanyQuery,
    private typeFormatQuery: TypeFormatQuery,
    private rejectionTypeQuery: RejectionTypeQuery,
    private ticketStateQuery: TicketStateQuery,
    private lawyerQuery: LawyerQuery,
    private recieveMediumQuery: RecieveMediumQuery,
    private roleQuery: RoleQuery,
    private positionQuery: PositionQuery,
    private professionQuery: ProfessionQuery,
    private specialConditionQuery: SpecialConditionQuery
    ) {
    super(http);
  }

  async getEntities() {
    const sma_url = `${this.baseUrl}/external/entity`;
    const request = await this.get<Company[]>(sma_url);
    this.companyQuery.setState(request.data, 0);
  }

  async getPrivateEntities() {
    const sma_url = `${this.baseUrl}/entity`;
    const request = await this.get<Company[]>(sma_url);
    this.companyQuery.setState(request.data, 0);
  }

  async getSpecialConditions() {
    const sma_url = `${this.baseUrl}/external/specialCondition`;
    const request = await this.get<SpecialCondition[]>(sma_url);
    this.specialConditionQuery.setState(request.data, 0);
  }

  async getChannels() {
    const sma_url = `${this.baseUrl}/external/channel`;
    const request = await this.get<Channel[]>(sma_url);
    this.channelQuery.setState(request.data, 0);
  }

  async getTypePersonAndDocuments() {
    const sma_url = `${this.baseUrl}/external/typePerson`;
    const request = await this.get<TypePerson[]>(sma_url);
    this.typePersonQuery.setState(request.data, 0);
  }

  async getDepartmentsAndCities() {
    const sma_url = `${this.baseUrl}/external/state`;
    const request = await this.get<Department[]>(sma_url);
    this.departmentQuery.setState(request.data, 0);
  }

  async getGenders() {
    const sma_url = `${this.baseUrl}/external/genre`;
    const request = await this.get<Gender[]>(sma_url);
    this.genderQuery.setState(request.data, 0);
  }

  async getEducationLevels() {
    const sma_url = `${this.baseUrl}/external/academicLevel`;
    const request = await this.get<EducationLevel[]>(sma_url);
    this.educationLevelQuery.setState(request.data, 0);
  }

  async getProductsByEntity(id: number) {
    const sma_url = `${this.baseUrl}/external/product/${id}`;
    const request = await this.get<EntityProduct[]>(sma_url);
    this.entityProductQuery.setState(request.data, 0);
  }

  async getTypeComplaintByEntity(id: number) {
    const sma_url = `${this.baseUrl}/external/typeComplaint/${id}`;
    const request = await this.get<TypeComplaint[]>(sma_url);
    this.typeComplaintQuery.setState(request.data, 0);
  }

  async getTypeComplaintByRole() {
    const sma_url = `${this.baseUrl}/ticket/typeComplaint`;
    const request = await this.get<TypeComplaint[]>(sma_url);
    this.typeComplaintQuery.setState(request.data, 0);
  }

  async getTypeTicket() {
    const sma_url = `${this.baseUrl}/external/typeTicket`;
    const request = await this.get<TypeTicket[]>(sma_url);
    this.typeTicketQuery.setState(request.data, 0);
  }

  async getTicketStates() {
    const sma_url = `${this.baseUrl}/state-ticket`;
    const request = await this.get<TicketState[]>(sma_url);
    this.ticketStateQuery.setState(request.data, 0);
  }

  async getRecieveMedimums() {
    const sma_url = `${this.baseUrl}/receiving-medium`;
    const request = await this.get<RecieveMedium[]>(sma_url);
    this.recieveMediumQuery.setState(request.data, 0);
  }

  async getUserMoanfulData(documentNumber: number) {
    try {
      const sma_url = `${this.baseUrl}/external/userMoanful/${documentNumber}`;
      const request = await this.get<UserMoanfulDataJsonResponse>(sma_url);
      return request.data;
    } catch (error) {
      return null;
    }
    
  }
  
  // Save ticket
  async sendTicket(data: TicketRequestPayload) {
    const sma_url = `${this.baseUrl}/external/ticket`;
    const request = await this.post<TicketJsonResponse, TicketRequestPayload>(sma_url, data);
    return request;
  }

  // Save ticket manually
  async sendTicketManually(data: TicketRequestPayload) {
    const sma_url = `${this.baseUrl}/ticket/ticket-radicador`;
    const request = await this.post<TicketJsonResponse, TicketRequestPayload>(sma_url, data);
    return request;
  }

  async attachTicketFiles(data: AttachedFilesRequestPayload) {
    const sma_url = `${this.baseUrl}/external/attached`;
    const request = await this.postAttachMultipart<AttachedFilesJsonResponse[]>(sma_url, data.ticketId, data.files);
    return request;
  }

  async getRejectionType() {
    const sma_url = `${this.baseUrl}/rejection-type`;
    const request = await this.get<RejectionType[]>(sma_url);
    this.rejectionTypeQuery.setState(request.data, {activeId: request.data[0].id})
    return request;
  }

  async getTypeFormatAssign() {
    const sma_url = `${this.baseUrl}/type-format`;
    const request = await this.get<TypeFormat[]>(sma_url);
    this.typeFormatQuery.setState(request.data, {activeId: request.data[0].id})
    return request;
  }

  async getRoles() {
    const sma_url = `${this.baseUrl}/roles`;
    const request = await this.get<Role[]>(sma_url);
    this.roleQuery.setState(request.data, 0);
    return request;
  }

  async getPositions() {
    const sma_url = `${this.baseUrl}/positions`;
    const request = await this.get<Position[]>(sma_url);
    this.positionQuery.setState(request.data, 0);
    return request;
  }

  async getProfessions() {
    const sma_url = `${this.baseUrl}/professions`;
    const request = await this.get<Profession[]>(sma_url);
    this.professionQuery.setState(request.data, 0);
    return request;
  }

  // Get Lawyers
  async getAllLawyers(ticketId=null, entityId=null) {
    let sma_url = `${this.baseUrl}/user/lawyer`;
    if (ticketId) { 
      sma_url = `${sma_url}?ticketId=${ticketId}`
      if (entityId) { sma_url = `${sma_url}&entityId=${entityId}` }
    } else {
      if (entityId) { sma_url = `${sma_url}?entityId=${entityId}` }
    }
    const request = await this.get<Lawyer[]>(sma_url);
    var fulldata = request.data.map(item => {
      item.fullName = item.name + ' ' + item.lastname
      return item
    })
    this.lawyerQuery.setState(fulldata, {activeId: null})
    return request;
  }
}
