import { AuthService } from 'src/app/services/auth.service';
import { TicketService } from 'src/app/services/ticket.service';
import { Component, OnInit } from '@angular/core';
import * as global from '../../config/globals';
import { AppResume } from 'src/app/interfaces/ticket.interface';
import { Observable, timer } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { PageSettingsQuery } from 'src/app/states/page_settings';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.html'
})

export class DashboardPage implements OnInit{

  constructor(
    private ticketService: TicketService,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    ) {}

  resume : {
    allTickets?: number;
    activateTickets?: number;
    deprecateTickets?: number;
    graphicsData?: any[];
  } = {
    allTickets: 0,
    activateTickets: 0,
    deprecateTickets: 0,
    graphicsData: [],
  }
  
  lineChartColor = global.COLOR_CHART_DOMAIN;

  lastLoginTime = this.authService.getLastLogin();

  async ngOnInit() {
    this.spinner.show();
    const response = await this.ticketService.getResume();
    this.resume = this.processData(response);
    this.spinner.hide();
  }

  processData(resumeData: AppResume) {
    const res = resumeData.graphicsData
    const gData = Object.keys(res).map((entity: string) => {
      const tGraphData = Object.keys(res[entity]).map((date: string) => {
        return {
          name: date,
          value: res[entity][date]
        }
      });
      return {
        name: entity,
        series: tGraphData
      }
    });

    return {
      ...resumeData,
      graphicsData: gData,
    }
  }
}
