import { AuthService } from 'src/app/services/auth.service';
import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { map, shareReplay, tap, filter, startWith, switchMap, flatMap } from 'rxjs/operators';
import { routes } from 'src/app/config/routes';
import { Observable } from 'rxjs-compat';
import { Ticket, TicketState, TicketStore } from './ticket.store';
import { of } from 'rxjs';
import { BaseQuery } from '../base/base.query';

@Injectable({
  providedIn: 'root'
})
export class TicketQuery extends BaseQuery<TicketState> {
  constructor(protected store: TicketStore) {
    super(store);
  }

  active$ = () => this.selectTicket();

  getSnapshot = () => this.getValue();

  isLoading$ = () => this.selectLoading();

  ticket$ = () => this.selectTicket().pipe(filter(it => it != null));
  ticketCount$ = () => this.selectCount(it => it != null)

  selectTicket() {
    return this.selectActive(it => it) as Observable<Ticket>;
  }

}
