import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { removeEmptyJsonFields, Utils } from 'src/app/common/utils';
import { Filter } from 'src/app/interfaces/ticket.interface';
import { Location } from '@angular/common';
import { IPaginate } from 'src/app/services/base.service';
import { ExternalService } from 'src/app/services/external.service';
import { LoadingService } from 'src/app/services/loading.service';
import { TicketService } from 'src/app/services/ticket.service';
import { Ticket } from 'src/app/states/ticket';
import { PaginationConfig } from './data';
import { CompanyQuery } from 'src/app/states/company';
import { LawyerQuery } from 'src/app/states/lawyer';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, shareReplay } from 'rxjs/operators';
import { NgbModal, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-manual_assignment',
  templateUrl: './manual_assignment.html'
})
export class ManualAssignmentPage implements OnInit {
  closeResult: string;

  public rows: Array<any> = [];
  public paginateData: IPaginate<Ticket> = {
    data: [],
    totalPage: 0,
    totalRegister: 0
  };

  public filters: Filter = {
    page: null,
    loading: true
  };

  public config = PaginationConfig();
  public columns = this.config.baseConfig.sorting.columns;

  lawyers$ = this.lawyerQuery.lawyer$();

  public filtersForm = new FormGroup({
    id: new FormControl(null),
    consecutive: new FormControl(null),
    creationDate: new FormControl({
      creationDateStart: null,
      creationDateEnd: null
    }),
    entity: new FormControl(null),
    typeComplaint: new FormControl(null),
    receivingMedium: new FormControl(null),
    moanfulSerial: new FormControl(null),
    moanfulName: new FormControl(null),
    name: new FormControl(null),
    lastname: new FormControl(null),
  });

  public assignmentForm = new FormGroup({
    ticketId: new FormControl(null), 
    ticketConsecutive: new FormControl({value: null, disabled: true}),    
    entityName: new FormControl({value: null, disabled: true}),
    assignedId: new FormControl(null)
  });

  constructor(
    private ticketService: TicketService,
    private externalService: ExternalService,
    private loadingService: LoadingService,
    private companyQuery: CompanyQuery,
    private lawyerQuery: LawyerQuery,
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal
  ) {
    this.loadingService.setLoading(true);
  }

  public getComponentLoading() {
    return !this.loadingService.loading;
  }

  public getFormatedRow(row, columnName: string) {
    if (typeof row[columnName] === 'undefined') {
      return '-'
    }

    if (columnName.includes('Date')) {
      if (row[columnName].includes('T')) {
        return row[columnName].split('T')[0]
      }

      return row[columnName]
    }

    if (columnName.includes('btn')) {
      return `<span class="btn-link pointer"><b><span class="fa fa-random" aria-hidden="true"></span></b></span>`;
    }

    if (columnName.includes('stateTicketName') || columnName.includes('consecutive') || columnName.includes('id')) {
      return `<b>${row[columnName]}</b>`;
    }

    return row[columnName]
  }

  getRowClass(row) {
    let baseClass = '';
    if (!row.assignmentDate) baseClass = 'warning';

    return `bg-${baseClass}-super-lighter-transparent pointer`;
  }

  async ngOnInit() {
    await this.setLoading(true)

    await this.externalService.getTypeComplaintByRole();
    await this.externalService.getEntities();
    await this.externalService.getTicketStates();
    await this.externalService.getAllLawyers();

    const it = this.route.snapshot.queryParams;
    this.setPage(it['page'] ? (parseInt(it['page'], 10)) : 1)
    this.addFilters();
    this.setFilters(it);
    await this.changePage(0, false, true)
  }

  private addFilters() {
    const curForm = this.filtersForm.value;
    const dateFilters = {
      ...curForm['creationDate'],
    }
    delete curForm['creationDate'];
    this.filters = Object.assign(this.filters, {
      ...this.filters,
      ...curForm,
      ...dateFilters
    })
  }

  setFilters(it) {
    this.filters = Object.assign(this.filters, {
      ...this.filters,
      ...it
    })
    Object.keys(it).filter(x => !['loading', 'page'].includes(x)).forEach(element => {
      if (['creationDateStart', 'creationDateEnd'].includes(element)) {
        this.filtersForm.controls.creationDate.patchValue({
          ...this.filtersForm.value.creationDate,
          [`${element}`]: it[element]
        })
      } else {
        this.filtersForm.controls.creationDate.patchValue({
          creationDateStart: null,
          creationDateEnd: null
        })
      }
    });
  }

  public async changePage(event, withRedirect: boolean = true, firstTime: boolean = false) {
    const it = this.route.snapshot.queryParams;
    if (firstTime) {
      this.loadingService.setLoading(true);
      this.setPage(it['page'] ? (parseInt(it['page'], 10)) : 1)
    } else {
      if (this.filters.loading && event == 1) {
        this.loadingService.setLoading(true);
        this.setPage(it['page'] ? (parseInt(it['page'], 10)) : 1)
      } else {
        this.setPage(event)
      }
    }
    await this.onChangeTable(withRedirect)
    this.loadingService.setLoading(false);
  }

  setPage(page) {
    this.filters.page = page;
  }

  public async onChangeTable(withRedirect: boolean = true) {
    try {
      await this.setLoading(true)
      const resume = (await this.fetchData()).data;
      this.paginateData = {
        ...resume,
        data: this.fetchHtmlData(resume.data),
      }
      if (withRedirect) {
        this.goToComplaintsWithQueryParams()
      }
      await this.setLoading(false)
    } catch (e) {
      await this.setLoading(false)
    }
  }

  async setLoading(loading) {
    if (loading) this.spinner.show(); else this.spinner.hide();
    this.filters.loading = loading;
  }

  async fetchData() {
    const tempFilters = {
      ...this.filters,
      page: this.filters.page > 0 ? this.filters.page - 1 : 0
    }
    delete tempFilters['loading']
    return await this.ticketService.listTicketsForAssignment(tempFilters);
  }

  private fetchHtmlData(rows: Ticket[] = this.paginateData.data) {
    return rows.map(x => {
      return {
        ...x,
        id_: x.consecutive,
        creationDate_: Utils.parseDateToString(x.creationDate, false),
        entity_: x.entityName,
        assignmentDate_: Utils.parseDateToString(x.assignmentDate)
      };
    });
  }

  private goToComplaintsWithQueryParams() {
    const tmpFilters = removeEmptyJsonFields(this.filters);
    delete tmpFilters['loading']
    this.location.replaceState(this.router.createUrlTree(
      ['/assignment'],
      { queryParams: tmpFilters }
    ).toString())
  }

  @ViewChild('instanceEntity', { static: true }) instanceEntity: NgbTypeahead;
  focusEntity$ = new Subject<string>();
  clickEntity$ = new Subject<string>();
  entities$ = this.companyQuery.company$();
  searchEntity = (text$: Observable<string>) => {
    return Utils.getStringQueryResult(
      text$.pipe(debounceTime(200), distinctUntilChanged()),
      this.clickEntity$.pipe(shareReplay(1)),
      this.focusEntity$,
      this.entities$
    );
  }

  public async submitFilters() {
    this.addFilters();
    await this.onChangeTable(true)
  }

  openAssinmentModal(content, row) {
    this.assignmentForm.setValue({
      ticketId: row.id, 
      ticketConsecutive: row.consecutive,    
      entityName: row.entityName,
      assignedId: null
    });

    this.modalService.open(content, { size: 'lg', backdrop: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    });
  }

  public async submitAssignment() {    
    const curForm = this.assignmentForm.value;
    delete curForm['ticketConsecutive'];
    delete curForm['entityName'];

    this.spinner.show();
    await this.ticketService.assignTicketToLawyer(curForm)
    await this.onChangeTable(true)

    this.modalService.dismissAll();
  }
}
