<div class="login login-with-news-feed">
  <div class="news-feed login-cover">

    <div class="news-image login-cover-image" style="background-image: url(../assets/img/cover/home-cover.jpg)"></div>
    <div class="login-cover-bg-purple"></div>
    <div class="news-caption-laurado">
      <img src="https://defensorialg.com.co/wp-content/uploads/2020/03/logodcflg-3.png" />
    </div>
  </div>
  
  <div class="right-content">

    <a routerLink="/home" routerLinkActive="active" class="btn btn-default"><i class="fas fa-chevron-left text-red"></i>
      Atrás</a>
      
    <div class="login-header">
      <div class="brand">
        <b>LG</b> Defensoría
        <small *ngIf="loginRole == 'collaborator'">CORAL | Sistema de atención</small>
        <small *ngIf="loginRole == 'moanful'">CORAL | Solicitantes</small>
      </div>
      <div class="icon">
        <i class="fa fa-sign-in-alt text-l-red"></i>
      </div>
    </div>
    
    <div class="login-content" *ngIf="loginRole == 'collaborator'">
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="margin-bottom-0">
        <div class="form-group m-b-15">
          <input type="text" formControlName="username" autocomplete="new-password" class="form-control form-control-lg"
            [ngClass]="{
            'is-invalid': (username.invalid && username.errors.email)
          }" placeholder="Correo Electrónico" required />
        </div>
        <div class="form-group has-feedback m-b-15">
          <input formControlName="password" autocomplete="new-password" class="form-control form-control-lg"
            [type]="showPassword ? 'password' : 'text'" placeholder="Contraseña" required />
          <span class="form-control-feedback" (click)="showPassword = !showPassword"
            style="cursor: pointer; pointer-events: all;">
            <i class="fa" [ngClass]="{ 'fa-eye-slash': !showPassword, 'fa-eye': showPassword}"></i>
          </span>
        </div>
        <div class="login-buttons">
          <button type="submit" class="btn btn-l-red btn-block btn-lg" [disabled]="!loginForm.valid">
            Iniciar Sesión</button>
        </div>
        <div class="m-t-20 m-b-40 p-b-40 text-muted">
          Si tienes problemas para ingresar por favor comunícate con soporte técnico
        </div>
        <hr />
        <p class="text-center text-grey-darker mb-0">
          &copy; LG Defensoría Derechos Reservados 2023
        </p>
      </form>
    </div>

    <div class="login-content" *ngIf="loginRole == 'moanful'">
      <form [formGroup]="loginMoanFul" (ngSubmit)="onSubmit()" class="margin-bottom-0">
        <div class="form-group m-b-0" *ngIf="documentTypes$ | async as documentTypes">
          <label class="col-form-label "><b>Tipo de documento</b></label>
          <ng-select appearance="outline" [searchable]="false" formControlName="typeDocumentId" [items]="documentTypes"
            bindLabel="name" [clearable]="true" bindValue="id" tabindex=”2”>
          </ng-select>
        </div>

        <div class="form-group m-b-0">
          <label class="col-form-label"><b>Número de documento</b></label>
          <input type="text" formControlName="serial" autocomplete="new-password" class="form-control" [ngClass]="{
            'is-valid': !serial.invalid,
            'is-invalid': (serial.invalid && serial.errors.required)
          }" placeholder="Número de documento" required />
        </div>

        <div class="form-group">
          <label class="col-form-label"><b>Fecha de expedición</b></label>
          <div class="input-group">
            <input class="form-control" placeholder="aaaa-mm-dd" readonly name="dp" formControlName="documentDate"
              [minDate]="years_ago_init" [maxDate]="today_mark" ngbDatepicker #d="ngbDatepicker" [ngClass]="{
                    'is-valid': !documentDate.invalid,
                    'is-invalid': (documentDate.invalid)
                  }">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
                <i class="fa fa-calendar"></i>
              </button>
            </div>
          </div>
        </div>


        <div class="login-buttons">
          <button type="submit" class="btn btn-l-red btn-block btn-lg"
            [disabled]="!loginForm.valid && !loginMoanFul.valid">Iniciar Sesión</button>
        </div>
        <div class="m-t-20 m-b-40 p-b-40 text-muted">
          Si tienes problemas para ingresar por favor comunícate con soporte técnico
        </div>
        <hr />
        <p class="text-center text-grey-darker mb-0">
          &copy; LG Defensoría Derechos Reservados 2023
        </p>
      </form>
    </div>
  </div>
</div>