import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import swal from 'sweetalert2';
import { NgSelectConfig } from '@ng-select/ng-select';
import { ToolbarService, LinkService, ImageService, HtmlEditorService } from '@syncfusion/ej2-angular-richtexteditor';
import * as _ from 'lodash';
import { ExternalService } from 'src/app/services/external.service';
import { TypePersonQuery } from 'src/app/states/type_person/type_person.query';
import { shareReplay } from 'rxjs/operators';
import { DepartmentQuery } from 'src/app/states/department/department.query';
import { GenderQuery } from 'src/app/states/gender/gender.query';
import { EducationLevelQuery } from 'src/app/states/education_level/education_level.query';
import { EntityProductQuery } from 'src/app/states/entity_product/entity_product.query';
import { TypeComplaintQuery } from 'src/app/states/type_complaint/type_complaint.query';
import { CompanyQuery } from 'src/app/states/company';
import { Observable } from 'rxjs';
import { Utils } from 'src/app/common/utils';
import { AttachedFilesRequestPayload, DefaultCountry, DefaultSpecialCondition, DefaultTicketState, NO, TicketJsonResponse, YES } from 'src/app/interfaces/external.interface';
import { NgxSpinnerService } from "ngx-spinner";
import { TypeTicketQuery } from 'src/app/states/type_ticket';
import { CustomValidators } from 'src/app/common/validators';
import * as moment from 'moment';
import { RecieveMediumQuery } from 'src/app/states/recieve_medium';
import { TicketStateQuery } from 'src/app/states/ticket_state';
import { TypeFormatQuery } from 'src/app/states/type_format';
import { Countries } from 'src/app/common/countries';
import { SpecialConditionQuery } from 'src/app/states/special_condition';
import { ChannelQuery } from 'src/app/states/channel';
import { TicketService } from 'src/app/services/ticket.service';

@Component({
  selector: 'manual_complaint',
  templateUrl: './manual_complaint.html',
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService]
})

export class ManualComplaintPage implements OnDestroy, OnInit {

  @ViewChild('PageContent', { static: false }) PageContent: any;
  closeResult: string;
  currentStep = 1;
  
  ticketId: number;
  ticketConsecutive = "";

  sendingForm = false;
  alreadySendToEntity = false;

  files_: File[] = [];

  editorStyle = {
    height: '200px'
  };

  years_ago_init = {
    year: 1750,
    month: 1,
    day: 1
  }

  years_ago_init_event = {
    year: 2015,
    month: 1,
    day: 1
  }
  today_mark = {
    year: moment().year(),
    month: moment().month() + 1,
    day: moment().date()
  }
  personalInfoForm = new FormGroup({
    entity: new FormControl(null, [Validators.required]),
    creationDateRadicador: new FormControl(null, [Validators.required]),
    receivingMedium: new FormControl(null, [Validators.required]),    
    stateTicket: new FormControl(DefaultTicketState, [Validators.required]),
    typePerson: new FormControl(null, [Validators.required]),
    typeDocumentId: new FormControl(null, [Validators.required]),
    serial: new FormControl(null, {
      validators: [Validators.required, Validators.minLength(5), CustomValidators.numeric],
      updateOn: 'blur'
    }),
    documentExpeditionDate: new FormControl(null, [Validators.required]),
    name: new FormControl(null, [Validators.required, Validators.minLength(7)]),
    email: new FormControl(null, [Validators.required, Validators.email, CustomValidators.email]),
    cellPhone: new FormControl(null, [Validators.required, Validators.minLength(7), Validators.maxLength(10)]),
    genreTypeId: new FormControl(null),
    academicLevelId: new FormControl(null),
    retire: new FormControl(null),
    userId: new FormControl(null),
    specialConditionId: new FormControl(DefaultSpecialCondition, [Validators.required]),
  });

  complaintInfoForm = new FormGroup({
    typeFormat378: new FormControl(null, [Validators.required]),
    product: new FormControl(null, [Validators.required]),
    typeTicket: new FormControl(1, [Validators.required]),
    typeComplaint: new FormControl(null, [Validators.required]),
    cost: new FormControl(null, [Validators.required]),
    eventDate: new FormControl(null, [Validators.required]),
    channelId: new FormControl(null, [Validators.required]),
    digital: new FormControl(null, [Validators.required]),
    city: new FormControl(null, Validators.required),
    country: new FormControl(DefaultCountry, Validators.required),
  });

  factsInfoForm = new FormGroup({
    subject: new FormControl(null, [Validators.required, Validators.minLength(7)]),
    acts: new FormControl(null, [Validators.required, Validators.minLength(20)]),
    request: new FormControl(null, [Validators.required, Validators.minLength(20)]),
  });

  requestInfoForm = new FormGroup({
    files: new FormControl([]),
  });


  products$ = this.entityProductQuery.entityProduct$();

  complaintTypes$ = this.typeComplaintQuery.typeComplaint$();

  typeTickets$ = this.typeTicketQuery.typeTicket$();

  countryOptions = Countries;

  jubilateOptions = [
    {
      id: NO,
      name: 'No'
    },
    {
      id: YES,
      name: 'Si'
    }
  ];

  digitalOptions = [
    {
      id: NO,
      name: 'No'
    },
    {
      id: YES,
      name: 'Si'
    }
  ];

  channel$ = this.channelQuery.channel$();

  specialCondition$ = this.specialConditionQuery.specialCondition$();

  cities$ = this.departmentQuery.department$();

  genders$ = this.genderQuery.gender$();

  educationLevels$ = this.educationLevelQuery.educationLevel$();

  personalTypes$ = this.typePersonQuery.typePerson$();

  documentTypes$ = Observable.of(null);

  entities$ = this.companyQuery.company$();

  recieveMedium$ = this.recieveMediumQuery.recieveMedium$();

  ticketStates$ = this.ticketStateQuery.TicketState$();

  typeFormats$ = this.typeFormatQuery.TypeFormat$();

  ckeConfig: any;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private config: NgSelectConfig,
    private externalService: ExternalService,
    private ticketService: TicketService,
    private typePersonQuery: TypePersonQuery,
    private departmentQuery: DepartmentQuery,
    private genderQuery: GenderQuery,
    private specialConditionQuery: SpecialConditionQuery,
    private channelQuery: ChannelQuery,
    private educationLevelQuery: EducationLevelQuery,
    private entityProductQuery: EntityProductQuery,
    private typeComplaintQuery: TypeComplaintQuery,
    private companyQuery: CompanyQuery,
    private spinner: NgxSpinnerService,
    private typeTicketQuery: TypeTicketQuery,
    private recieveMediumQuery: RecieveMediumQuery,
    private ticketStateQuery: TicketStateQuery,
    private typeFormatQuery: TypeFormatQuery,

  ) {
    this.config.notFoundText = 'No se encontraron resultados';
    this.config.bindValue = 'name';
    this.config.loadingText = 'Cargando...';
  }

  async ngOnInit() {
    this.spinner.show();
    await this.externalService.getEntities();
    await this.externalService.getRecieveMedimums();
    await this.externalService.getTicketStates();
    await this.externalService.getTypeFormatAssign();
    await this.externalService.getChannels();
    await this.externalService.getSpecialConditions();

    this.entity.valueChanges.subscribe(async it => {
      // Get Entereprise information
      if (it) {
        this.spinner.show();
        this.companyQuery.setLoading(true);
        await this.externalService.getEntities();
        this.companyQuery.setLoading(false);

        // Page 1
        await this.externalService.getTypePersonAndDocuments();
        await this.externalService.getDepartmentsAndCities();
        await this.externalService.getGenders();
        await this.externalService.getEducationLevels();

        // Page 2
        await this.externalService.getProductsByEntity(parseInt(it, 10));
        await this.externalService.getTypeComplaintByEntity(parseInt(it, 10));
        await this.externalService.getTypeTicket();

        this.spinner.hide();
      }

    });
    // Events
    this.personalInfoForm.controls.typePerson.valueChanges.subscribe(it => {
      this.personalInfoForm.patchValue({ typeDocumentId: null, genreTypeId: null });
      this.documentTypes$ = this.typePersonQuery.selectDocument(parseInt(it, 10));
    });
    this.ckeConfig = {
      allowedContent: true,
      extraPlugins: ['divarea', 'uploadimage'],
      isFileUploadSupported: false,
      removePlugins: 'image',
      forcePasteAsPlainText: true
    };
    this.personalInfoForm.controls.serial.valueChanges.pipe(shareReplay(1)).subscribe(async (it) => await this.searchUserMoanfulData(it));
    this.spinner.hide();
  }

  isEnterpriseInformation() {
    if (this.typePerson.value == null) { return true; }
    return parseInt(this.typePerson.value, 10) === 2;
  }

  onSendStep1() {
    if (!this.personalInfoForm.valid) {
      swal.fire('Revisaste tu información?', 'Parece que no has diligenciado correctamente toda tu información personal', 'error');
      return;
    }
    this.nextStep();
  }

  onSendStep2() {
    if (!this.complaintInfoForm.valid) {
      swal.fire('Revisaste tu información?', 'Parece que no has diligenciado correctamente toda la información sobre tu queja', 'error');
      return;
    }
    this.nextStep();
  }

  onSendStep3() {
    if (!this.factsInfoForm.valid) {
      swal.fire('Revisaste tu información?', 'Parece que no has diligenciado correctamente la información de tu solicitud', 'error');
      return;
    }
    this.nextStep();
  }

  onSendStep4() {
    const size = this.getTotalFilesSize();    
    if (this.files_.length > 20 || size >= 100000000 ) {
      swal.fire('Algo anda mal', 'Recuerda que solo puedes subir hasta 20 archivos y el total de sus pesos juntos no pueden exceder los 100 MB (MegaBytes)', 'error');
      return;
    } else if (this.files_.length == 0 && this.validateIfRequireSupports()) {
      swal.fire('Espera!', 'Recuerda que debes subir el certificado de condición especial!', 'warning');
      return;
    }

    if (this.files_.length == 0) {
      this.validateSupports().then(async (result) => {
        if (result.value) this.onSendStep5();
      });
    } else {
      this.onSendStep5();
    }
  }

  async validateSupports(): Promise<any> {
    return await swal.fire({
      title: 'No se han añadido soportes, ¿Está seguro que desea continuar?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, radicar la solicitud!',
      cancelButtonText: 'No, quiero pensarlo mejor'
    });
  }

  validateIfRequireSupports(): Boolean {
    var markSpecial = this.personalInfoForm.get("specialConditionId").value;
    return markSpecial != DefaultSpecialCondition;
  }

  async onSendStep5() {
    try {
      await this.spinner.show();
      this.sendingForm = true;


      const ticketResponse = await this.saveCurrentForm();
      this.ticketConsecutive = ticketResponse.consecutive;
      this.ticketId = ticketResponse.ticketID || ticketResponse.id;

      this.sendingForm = false;
      await this.spinner.hide();
    } catch (error) {
      swal.fire('Algo anda mal', error.message, 'error');
      await this.spinner.hide();
    }
  }

  async searchUserMoanfulData(documentNumber: number) {
    if (documentNumber == null) { return; }
    const response = await this.externalService.getUserMoanfulData(documentNumber);
    if (response == null) { return; }
    const docExpDate = response.documentExpeditionDate.split('T')[0].split('-');
    const newData = {
      name: response.name,
      cellPhone: response.cellPhone == 0 ? null : response.cellPhone,
      address: response.address,
      typePerson: response.typePerson,
      academicLevelId: response.academicLevelId,
      genreTypeId: response.genreTypeId,
      userId: response.userId,
      typeDocumentId: response.typeDocumentId,
      documentExpeditionDate: { year: parseInt(docExpDate[0], 10), month: parseInt(docExpDate[1], 10), day: parseInt(docExpDate[2], 10) },
      retire: response.retire,
      email: response.email,
      city: response.city
    };
    this.personalInfoForm.patchValue(newData)
  }

  resetForms() {
    this.personalInfoForm.reset();
    this.complaintInfoForm.reset();
    this.factsInfoForm.reset();
    this.requestInfoForm.reset();
    this.files_ = [];
  }

  nextStep() {
    this.currentStep++;
  }

  prevStep() {
    this.currentStep--;
  }

  selectionCountryChanged(e) {
    if (e.id != DefaultCountry)
      this.complaintInfoForm.get('city').disable();
    else
      this.complaintInfoForm.get('city').enable();
  }

  async saveCurrentForm(): Promise<TicketJsonResponse> {
    const personalForm = {
      ...this.personalInfoForm.value,
      documentExpeditionDate: Utils.getDate(this.personalInfoForm.value.documentExpeditionDate),
      creationDateRadicador: Utils.getDate(this.personalInfoForm.value.creationDateRadicador),
    }
    const complaintForm = {
      ...this.complaintInfoForm.value,
      eventDate: Utils.getDate(this.complaintInfoForm.value.eventDate)
    }
    const form = _.merge(
      personalForm,
      this.complaintInfoForm.value,
      complaintForm,
      this.factsInfoForm.value,
    );

    // Error de radicación de quejas
    const response = await this.externalService.sendTicketManually(form);
    const attachedFiles = {
      ticketId: response.data.ticketID || response.data.id,
      files: this.requestInfoForm.value['files']
    } as AttachedFilesRequestPayload;
    if (attachedFiles.files.length > 0) {
      await this.externalService.attachTicketFiles(attachedFiles);
    }

    this.nextStep();
    return response.data;
  }

  async sendToEntity() {
    await this.spinner.show();
    const form = {
      ticketId: this.ticketId,
      entityId: this.personalInfoForm.value['entity'],
      typeFormat378Id: this.complaintInfoForm.value['typeFormat378'],
      productId: this.complaintInfoForm.value['product']
    }

    await this.ticketService.changeTicketEntity(form)
    this.alreadySendToEntity = true;

    await this.spinner.hide();
  }

  resetAll() {
    const url = this.router.url;
    this.router.navigateByUrl('/dashboard',{ skipLocationChange:true }).then(()=>{
      this.router.navigate([`/${url}`])
    })
  }

  // Form controls
  get typePerson() {
    return this.personalInfoForm.get('typePerson');
  }

  get entity() {
    return this.personalInfoForm.get('entity');
  }

  get receivingMedium() {
    return this.personalInfoForm.get('receivingMedium');
  }

  get stateTicket() {
    return this.personalInfoForm.get('stateTicket');
  }

  get typeFormat378() {
    return this.complaintInfoForm.get('typeFormat378');
  }

  get creationDateRadicador() {
    return this.personalInfoForm.get('creationDateRadicador');
  }

  get typeDocumentId() {
    return this.personalInfoForm.get('typeDocumentId');
  }

  get serial() {
    return this.personalInfoForm.get('serial');
  }

  get documentExpeditionDate() {
    return this.personalInfoForm.get('documentExpeditionDate');
  }

  get name() {
    return this.personalInfoForm.get('name');
  }

  get email() {
    return this.personalInfoForm.get('email');
  }

  get cellPhone() {
    return this.personalInfoForm.get('cellPhone');
  }

  get city() {
    return this.personalInfoForm.get('city');
  }

  get address() {
    return this.personalInfoForm.get('address');
  }

  get academicLevelId() {
    return this.personalInfoForm.get('academicLevelId');
  }

  get genreTypeId() {
    return this.personalInfoForm.get('genreTypeId');
  }

  get retire() {
    return this.personalInfoForm.get('retire');
  }

  get special() {
    return this.personalInfoForm.get('specialConditionId');
  }

  get country() {
    return this.complaintInfoForm.get('country');
  }

  get channel() {
    return this.complaintInfoForm.get('channel');
  }

  get product() {
    return this.complaintInfoForm.get('product');
  }

  get typeTicket() {
    return this.complaintInfoForm.get('typeTicket');
  }

  get typeComplaint() {
    return this.complaintInfoForm.get('typeComplaint');
  }

  get cost() {
    return this.complaintInfoForm.get('cost');
  }

  get eventDate() {
    return this.complaintInfoForm.get('eventDate');
  }

  get subject() {
    return this.factsInfoForm.get('subject');
  }

  get acts() {
    return this.factsInfoForm.get('acts');
  }

  get request() {
    return this.factsInfoForm.get('request');
  }

  get files() {
    return this.requestInfoForm.get('files');
  }

  // ========================= Drag & Drop Events =====================================

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files_.splice(index, 1);
    this.files.patchValue(this.files_);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      this.files_.push(item);
    }
    this.files.patchValue(this.files_);
  }

  getTotalFilesSize() {
    if(this.files_.length === 0)
      return 0;      
    return this.files_.map(i=> i.size).reduce((a,b)=> a + b)
  }

  getBytes(bytes, decimals = 2) {
    const k = 1024;
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat(`${(bytes / Math.pow(k, i))}`);
  }

  getItemStringSize(bytes) {
    const k = 1024;
    const sizes_n = this.formBaseSize(false);
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return sizes_n[i];
  }

  formBaseSize(is_string) {
    if (is_string) {
      return ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    }
    return [1, 2, 3, 4, 5, 6, 7, 8, 9];
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const values = this.getBytes(bytes, decimals).toFixed(dm);
    const k = 1024;
    const sizes = this.formBaseSize(true);
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return values + ' ' + sizes[i];
  }

  ngOnDestroy() {
    this.resetForms();
  }

  open(content) {
    this.modalService.open(content, { size: 'xl', backdrop: 'static' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
