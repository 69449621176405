import { guid, StoreConfig, Store, EntityState, EntityStore } from '@datorama/akita';
import pageSettings from '../../config/page-settings';
import { Injectable } from '@angular/core';

export interface PageSettings  {
  hiddingBars: boolean;
  contentClass: 'content' | 'container';
  userData: UserData;
}

export interface UserData  {
  email: string;
  role: string;
  name: string;
  lastName: string;
  fullName: string;
  docNumber: number;
  docType: string;
  birthDate: string;
  cellPhone: number;
  entity: string;
}


export interface PageSettingsState extends EntityState<PageSettings, number> {
  hiddingBars: boolean;
  contentClass: 'content' | 'container';
  userData: UserData;
}

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'PageSettings' })
export class PageSettingsStore extends EntityStore<PageSettingsState> {
  constructor() {
    super();
    this.set({1: {hiddingBars: true, contentClass: 'content', userData: null}}, {activeId: 1});
  }
}
