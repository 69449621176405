import { AppResume } from './../interfaces/ticket.interface';
import { BaseService, PaginateApiResponse, BaseEntity } from './base.service';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AnswerMessageThread, AskForInformation, AssignLawyerPayload, ChangeTicketEntity, ChangeTicketType, EntityTicketAction, Filter, RejectionType } from '../interfaces/ticket.interface';
import { Utils } from '../common/utils';
import { TicketAnswer, TicketAnswerQuery } from '../states/ticket_answer';
import { TicketLog, TicketLogQuery } from '../states/ticket_log';
import { TicketFile } from '../states/ticket_file/ticket_file.store';
import { TicketFileQuery } from '../states/ticket_file/ticket_file.query';
import { TicketUser, TicketUserQuery } from '../states/ticket_user';
import { Ticket, TicketQuery } from '../states/ticket';
import { TicketAnswerMessage, TicketAnswerMessageQuery } from '../states/ticket_answer_message';
import { IRoleDetail } from './auth.service';
import { TypeResponseApprovePronouncement } from '../interfaces/external.interface';
import { TypeResponseApprovePronouncementQuery } from '../states/type-response-approve-pronouncement/type-response-approve-pronouncement.query';

@Injectable()
export class TicketService extends BaseService {

  constructor(
    public http: HttpClient,
    private ticketAnswerQuery: TicketAnswerQuery,
    private ticketLogQuery: TicketLogQuery,
    private ticketFileQuery: TicketFileQuery,
    private ticketUserQuery: TicketUserQuery,
    private ticketQuery: TicketQuery,
    private ticketAnswerMessageQuery: TicketAnswerMessageQuery,
    private typeResponseApprovePronouncementQuery: TypeResponseApprovePronouncementQuery
    
    ) {
    super(http);
  }

  async listTicketsForReport(ticketFilter: Object) {   
    const queryParams = Utils.parseObjectToQueryString(ticketFilter);
    const sma_url = `${this.baseUrl}/ticket-report/v2?${queryParams}`;    
    return await this.paginatedGet<Ticket>(sma_url);
  }

  async listTickets(ticketFilter: Filter) {
    const queryParams = Utils.parseFilterToQueryString(ticketFilter);
    const sma_url = `${this.baseUrl}/ticket${queryParams}`;
    const response = await this.paginatedGet<Ticket>(sma_url);
    return response;
  }
  
  async listTicketsForAssignment(ticketFilter: Filter) {
    const queryParams = Utils.parseFilterToQueryString(ticketFilter);
    const sma_url = `${this.baseUrl}/ticket/manual-assignment${queryParams}`;
    const response = await this.paginatedGet<Ticket>(sma_url);
    return response;
  }

  async downloadTickets(ticketFilter: Filter) {
    const queryParams = Utils.parseFilterToQueryString(ticketFilter);
    const sma_url = `${this.baseUrl}/ticket-report${queryParams}`;
    const response = await this.getFileByBlob(sma_url);
    const currentDate = new Date().toISOString().split('T')[0];

    Utils.downloadByDataType(`ticket-list-${currentDate}.xlsx`, response)
    return response;
  }

  async retrieveTicket(consecutive: number) {
      const sma_url = `${this.baseUrl}/ticket/${consecutive}`;
      const response = await this.get<Ticket>(sma_url);
      this.ticketQuery.setState([{...response.data}], {activeId: response.data.id});
      return response;
  }

  async getMoanfulByTicket(consecutive: number) {
      const sma_url = `${this.baseUrl}/user/userMoanfulServiceByTicket/${consecutive}`;
      const response = await this.get<TicketUser>(sma_url);
      this.ticketUserQuery.setState([{...response.data, id: response.data.userId}], {activeId: response.data.userId});
      return response;
  }

  async getFilesByTicket(consecutive: number) {
      const sma_url = `${this.baseUrl}/ticket/attached-ticket/${consecutive}`;
      const response = await this.get<TicketFile[]>(sma_url);
      this.ticketFileQuery.setState(response.data, {activeId: response.data[0] ? response.data[0].id : 1});
      return response;
  }

  async getTicketLogs(consecutive: number) {
    const sma_url = `${this.baseUrl}/ticket/ticket-history/${consecutive}`;
    const response = await this.get<TicketLog[]>(sma_url);
    this.ticketLogQuery.setState(response.data, {activeId: response.data[0] ? response.data[0].id : 1});
    return response;
  }

  async getTicketAnswers(consecutive: number) {
    const sma_url = `${this.baseUrl}/ticket/extra-request-ticket/${consecutive}`;
    const response = await this.get<TicketAnswer[]>(sma_url);
    this.ticketAnswerQuery.setState(response.data, {activeId: null});
    return response;
  }

  async getTicketAnswerMessages(consecutive: number, historicalId: number) {
    const sma_url = `${this.baseUrl}/ticket/message-extra-request-ticket/${consecutive}/${historicalId}`;
    const response = await this.get<TicketAnswerMessage[]>(sma_url);
    this.ticketAnswerMessageQuery.setState(response.data, {activeId: null});
    return response;
  }

  async downloadTicketFiles(consecutive: number) {
    const sma_url = `${this.baseUrl}/ticket/attached-ticket-zip/${consecutive}`;
    const response = await this.getFileByBlob(sma_url);
    Utils.downloadByDataType(`ticket-files-${consecutive}.zip`, response)
    return response;
  }

  async markAsDeleteAttachment(attachedId: String) {   
    const sma_url = `${this.baseUrl}/ticket/attached-ticket/${attachedId}/delete`;    
    const response = await this.delete<any, any>(sma_url);
    return response;
  }

  // Ticket Actions

  async rejectTicket(data) {
    const sma_url = `${this.baseUrl}/ticket/rejection-ticket`;
    const response = await this.post<any, RejectionType>(sma_url, data);
    return response;
  }

  async changeTicketType(data) {
    const sma_url = `${this.baseUrl}/ticket/change-type-ticket`;
    const response = await this.post<any, ChangeTicketType>(sma_url, data);
    return response;
  }

  async changeTicketEntity(data) {
    const sma_url = `${this.baseUrl}/ticket/ticket-entity`;
    const response = await this.post<any, ChangeTicketEntity>(sma_url, data);
    return response;
  }

  async changeTicketEntityProduct(data) {
    const sma_url = `${this.baseUrl}/ticket/ticket-updated-entity`;
    const response = await this.post<any, ChangeTicketEntity>(sma_url, data);
    return response;
  }

  async askForInformation(data, roleDetail: IRoleDetail, action: EntityTicketAction) {
    const sma_url = this.getAskForInformationUrlByRole(roleDetail, action);
    data = {
      ...data,
      subject: sma_url.subject
    };
    const response = await this.post<any, AskForInformation>(sma_url.url, data);
    return response;
  }

  async notifyInformation(extraRequestTicketId) {    
    const response = await this.post<any, void>(
      `${this.baseUrl}/extra-request-ticket/notify/${extraRequestTicketId}`, null);
    return response;
  }

  async answerMessageThread(data, type=null, isMoanfulRequest=false, isInconsistency=false) {
    const baseTypesApprove = [
      Utils.extraTicketStates.VOCERIA_CREATION,
      Utils.extraTicketStates.PRONOUNCEMENT
    ]
    let sma_url = null;
    if (baseTypesApprove.includes(type)) {
      return this.approveOrMessagePronouncement(data, type);
    } else if(type === Utils.extraTicketStates.MOANFUL_INFO_REQUEST && isMoanfulRequest) {
      sma_url = `${this.baseUrl}/message-request/information-moanful`;
    } else if(isInconsistency) {
      sma_url = `${this.baseUrl}/message-request/information-inconsistency`;
    } else {
      sma_url = `${this.baseUrl}/message-request/information-defense`;
    }
    const response = await this.post<any, AnswerMessageThread>(sma_url, data);
    return response;
  }

  async attachExtraFiles(data, files) {
    const sma_url = `${this.baseUrl}/attached-extra`;
    const response = await this.commonPostAttachMultipart<any[]>(sma_url, data, files);
    return response;
  }

  async approveOrMessagePronouncement(data, type) {
    let sma_url = null;
    if (type === Utils.extraTicketStates.PRONOUNCEMENT) {
      return this.approvePronouncement(data);
    }
    else if (type === Utils.extraTicketStates.VOCERIA_CREATION) {
      sma_url = `${this.baseUrl}/message-request/message-voceria`;
    }
    else {
      sma_url = `${this.baseUrl}/message-request/information-moanful`;
    }
    const response = await this.post<any, AnswerMessageThread>(sma_url, data);
    return response;
  }

  async answerPronouncement(data) {
    const response = await this.post<any, AnswerMessageThread>(`${this.baseUrl}/message-request/approve-pronouncement`, data);
    return response;
  }

  async hideAnswer(data) {
    const response = await this.post<any, AnswerMessageThread>(`${this.baseUrl}/message-request/hide-answer`, data);
    return response;
  }

  async approvePronouncement(data) {
    const response = await this.post<any, AnswerMessageThread>(`${this.baseUrl}/message-request/comment-pronouncement`, data);
    if (response.success) {
      data.id = response.data.id;
      await this.post<any, AnswerMessageThread>(`${this.baseUrl}/message-request/notify-pronouncement`, data);
    }
    
    return response;
  }
  
  async notifyPronouncement(extraRequestTicketId) {
    return await this.post<any, AnswerMessageThread>(`${this.baseUrl}/message-request/only-notify-pronouncement/${extraRequestTicketId}`, null);
  }

  async getApprovePronouncement() {
    const sma_url = `${this.baseUrl}/message-request/type-response-approve-pronunciamiento`;
    const response = await this.get<TypeResponseApprovePronouncement>(sma_url);
    this.typeResponseApprovePronouncementQuery.setState(response.data, response.data[0])
    return response;
  }

  async assignTicketToLawyer(data) {
    const sma_url = `${this.baseUrl}/ticket/ticket-lawyer`;
    const response = await this.post<any, AssignLawyerPayload>(sma_url, data);
    return response;
  }

  async getResume() {
    let sma_url = `${this.baseUrl}/ticket/resume-info`;
    const response = await this.get<AppResume>(sma_url);
    return response.data;
  }

  resetStores() {
    this.ticketAnswerMessageQuery.resetStore();
    this.ticketQuery.resetStore();
    this.ticketUserQuery.resetStore();
    this.ticketFileQuery.resetStore();
    this.ticketLogQuery.resetStore();
    this.ticketAnswerQuery.resetStore();
  }


  private getAskForInformationUrlByRole(roleDetail: IRoleDetail, action: EntityTicketAction): { subject: string, url: string } {
    if (roleDetail.isRadicator) {
      return {
        url: `${this.baseUrl}/extra-request-ticket/ask-information`,
        subject: '[RADICADOR] Solicitud de Información y/o Documentos'
      };
    }

    if (roleDetail.isEntity) {
      switch (action) {
        case 'askInfo':
          return {
            url: `${this.baseUrl}/extra-request-ticket/ask-information-defense`,
            subject: '[ENTIDAD] Solicitud de Información y/o Documentos'
          };
        case 'extend':
          return {
            url: `${this.baseUrl}/extra-request-ticket/extension`,
            subject: '[ENTIDAD] Prórroga'
          };
        case 'answer':
          return {
            url: `${this.baseUrl}/extra-request-ticket/response-ticket`,
            subject: '[ENTIDAD] Respuesta de la entidad'
          };
        default:
          return {
            url: `${this.baseUrl}/extra-request-ticket/ask-information`,
            subject: '[ENTIDAD] Solicitud de Información y/o Documentos'
          };
      }
    }

    if (roleDetail.isLawyer) {
      switch (action) {
        case 'askInfo':
          return {
            url: `${this.baseUrl}/extra-request-ticket/ask-information-defense`,
            subject: '[ABOGADO] Solicitud de Información y/o Documentos'
          };
        case 'spokesperson':
          return {
            url: `${this.baseUrl}/extra-request-ticket/create-voceria`,
            subject: '[ABOGADO] Se genera una Vocería'
          };
        case 'attorney_answer':
          return {
            url: `${this.baseUrl}/extra-request-ticket/create-apremio`,
            subject: '[ABOGADO] Se genera un Apremio'
          };
        case 'pronouncement':
          return {
            url: `${this.baseUrl}/extra-request-ticket/create-pronouncement`,
            subject: '[ABOGADO] Se genera un Pronunciamiento'
          };
        default:
          return {
            url: `${this.baseUrl}/extra-request-ticket/ask-information`,
            subject: '[ABOGADO] Solicitud de Información y/o Documentos'
          };
      }
    }

    return {
      url: `${this.baseUrl}/extra-request-ticket/ask-information`,
      subject: '[ADMINISTRADOR] Solicitud de Información y/o Documentos'
    };
  }  

  private getNotifyInformationUrlByRole(roleDetail: IRoleDetail, action: EntityTicketAction, extraRequestTicketId: string): string {
    if (roleDetail.isLawyer || roleDetail.isAdmin) {
      switch (action) {
        case 'spokesperson':
          return `${this.baseUrl}/extra-request-ticket/notify-voceria/${extraRequestTicketId}`;
        case 'attorney_answer':
          return `${this.baseUrl}/extra-request-ticket/notify-apremio/${extraRequestTicketId}`;;
        default:
          throw 'invalid option';
      }
    }

    throw 'invalid option';
  }  
}
