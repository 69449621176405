import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { TicketAnswer, TicketAnswerQuery } from 'src/app/states/ticket_answer';
import { Location } from '@angular/common';
import { TicketService } from 'src/app/services/ticket.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TicketAnswerMessageQuery } from 'src/app/states/ticket_answer_message';
import { Utils } from 'src/app/common/utils';
import { AuthService, IRoleDetail } from 'src/app/services/auth.service';
import { TicketQuery } from 'src/app/states/ticket';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import swal from 'sweetalert2';
import { TypeResponseApprovePronouncementQuery } from 'src/app/states/type-response-approve-pronouncement/type-response-approve-pronouncement.query';

@Component({
  selector: 'complaint_answers',
  templateUrl: './complaint_answers.html'
})

export class ComplaintAnswersPage implements OnInit {
  @Output() openAnswerModal: EventEmitter<any> = new EventEmitter();
  @Input() ticketAnswers: TicketAnswer[];
  ticketAnswers$ = this.ticketAnswerQuery.ticketAnswer$();
  answerCount$ = this.ticketAnswerQuery.ticketAnswerCount$();
  attoneryAnswerCount$ = this.ticketAnswerQuery.ticketAnswerCountByType$(Utils.extraTicketStates.APREMIO_REQUEST);
  pronouncementCount$ = this.ticketAnswerQuery.ticketAnswerCountByType$(Utils.extraTicketStates.PRONOUNCEMENT);
  rejectionCount$ = this.ticketAnswerQuery.ticketAnswerCountByType$(Utils.extraTicketStates.REJECTION);
  spokespersonCount$ = this.ticketAnswerQuery.ticketAnswerCountByType$(Utils.extraTicketStates.VOCERIA_CREATION);
  requestinfoCount$ = this.ticketAnswerQuery.ticketAnswerCountByNoInType$([
    Utils.extraTicketStates.VOCERIA_CREATION,
    Utils.extraTicketStates.REJECTION,
    Utils.extraTicketStates.PRONOUNCEMENT,
    Utils.extraTicketStates.APREMIO_REQUEST,
  ]);
  
  selectedAnswer$ = this.ticketAnswerQuery.selectActive() as Observable<TicketAnswer>;
  detailData$ = this.ticketQuery.ticket$();
  roleDetail: IRoleDetail;

  files_: File[] = [];
  ticketAnswerMessage$ = this.ticketAnswerMessageQuery.ticketAnswerMessage$();
  editorStyle = {
    height: '70px',
    display: 'block'
  };
  answerMessageThreadForm = new FormGroup({
    extraRequestTicketId: new FormControl(null),
    titlePronouncement: new FormControl(null),
    message: new FormControl(null, [Validators.required]),
    typeResponseTicketExtraId: new FormControl(null),
    files: new FormControl([]),
  });

  currPage = 1;
  selectedMessage = null;
  subTab = 'all';
  isAnswer = false;
  reason = null;

  answerBenefit = null;
  isApproval = false;

  wasSendMailNotification = false;

  isInconsistency = false;

  benefits$ = this.ticketAnswerMessage$.pipe(
    switchMap(it => {
      this.answerBenefit = it[it.length - 1].typeResponseTicketExtraId;
      if (this.roleDetail.isApprover || this.roleDetail.isLawyer || this.roleDetail) {
        this.answerMessageThreadForm.patchValue({typeResponseTicketExtraId: null})
      }
      return this.typeResponseApprovePronouncementQuery.TypeResponseApprovePronouncement$(this.answerBenefit)
    }),
  );

  constructor(
    private route: ActivatedRoute,
    private ticketAnswerQuery: TicketAnswerQuery,
    private router: Router,
    private location: Location,
    private ticketService: TicketService,
    private spinner: NgxSpinnerService,
    private ticketAnswerMessageQuery: TicketAnswerMessageQuery,
    private authService: AuthService,
    private ticketQuery: TicketQuery,
    private typeResponseApprovePronouncementQuery: TypeResponseApprovePronouncementQuery
    ) {
  }

  public async ngOnInit() {
    await this.getAllData();
  }

  public async getAllData() {    
    const params = this.route.snapshot.queryParams;
    const params_ = this.route.snapshot.params;
    await this.fetchData();
    if (params['selectedAnswer'] != null && params['selectedAnswer'] != '') { 
      this.selectedMessage = params['selectedAnswer'];
      await this.loadMessage(params_['id'], params['selectedAnswer']);
    }
    if (params['subTab'] != null && params['subTab'] != '') {
      this.subTab = params['subTab'];
      this.changeSubTab(this.subTab);
    }
  }

  public async fetchData() {
    this.spinner.show();
    this.roleDetail = this.authService.getRoleDetail();
    const params = this.route.snapshot.params;
    await this.ticketService.getTicketAnswers(params['id'])
    await this.spinner.hide();
  }

  open(type) {
    this.openAnswerModal.emit(type)
  }

  public getIconObject(iconUrl) {
    const splitedUrl = iconUrl.split('.');
    const extension = splitedUrl[(splitedUrl.length - 1)]
    return {
      content: Utils.IMAGE_FORMATS.includes(extension) ? `<img src="${iconUrl}" alt="">` : '<i class="fa fa-file"></i>',
      icon: Utils.IMAGE_FORMATS.includes(extension) ? 'fa-camera' : 'fa-file'
    }
  }

  public async selectMessage(answerId: number) {
    this.spinner.show();
    this.selectedMessage = answerId;
    this.ticketAnswerQuery.setActive(this.selectedMessage);
    const params = this.route.snapshot.params;
    await this.loadMessage(params['id'], answerId);
    this.location.replaceState(this.router.createUrlTree(
      [`/complaints/${this.route.snapshot.params['id']}`],
      {queryParams: {
        tab: 'answers',
        selectedAnswer: answerId
      }}
    ).toString())
    await this.spinner.hide();
  }

  public getFormatedDateString(date) {
    return Utils.getDateWithHourFromString(date)
  }

  setForm(val=null, reason = null) {
    if (reason != 'is_for_approval') {
      this.isAnswer = val === null ? !this.isAnswer : val;
    }
    this.reason = reason;
   
    this.isApproval = reason == 'is_for_approval' ? !this.isApproval : false;
    if (!this.isApproval) {
      this.answerBenefit = null;
    }
  }

  public async sendMessageThreadAnswer() {
    await this.spinner.show();
    if (this.reason === null) {
      this.baseThreadAnswer()
    } else {
      this.approveAnswer_(this.answerMessageThreadForm.value.message, false);
    }
    await this.baseFormAction();
  }

  private async baseThreadAnswer() {
    const it = this.route.snapshot.params;
    const formData = {
      ...this.answerMessageThreadForm.value,
      extraRequestTicketId: this.selectedMessage,
      typeResponseTicketExtraId: this.isInconsistency ? 22 : null  
    };
    
    delete formData.files;
    const activeTicket = this.ticketAnswerQuery.naturalActive();

    const baseResponse = await this.ticketService.answerMessageThread(
                                                          formData, 
                                                          activeTicket.typeExtraRequest.id, 
                                                          this.roleDetail.isMoanful,
                                                          this.isInconsistency);

    if ( this.answerMessageThreadForm.value['files'].length > 0) {
      const formFilesData = {
        ticketId: parseInt(it['id'], 10),
        extraRequestId: baseResponse.data.extraRequestTicketId,
        messageId: baseResponse.data.id,
      }
      await this.ticketService.attachExtraFiles(formFilesData, this.answerMessageThreadForm.value['files']);
    }
    this.answerMessageThreadForm.reset();
    this.files_ = []
  }

  private async setTypeComplaintId() {
    const it = this.route.snapshot.params;
    await this.ticketService.retrieveTicket(it['id']);
    await this.ticketService.getTicketLogs(it['id'])
    await this.ticketService.getTicketAnswers(it['id'])
    await this.loadMessage(it['id'], this.selectedMessage);
    this.detailData$.subscribe(it => {
      this.answerMessageThreadForm.controls.extraRequestTicketId.patchValue(this.selectedMessage)
    });
    this.setForm(false, null);
  }

  inputFilesEvent($event) {
    this.answerMessageThreadForm.patchValue({files: $event.target.files})
  }

  private async baseFormAction() {
    await this.setTypeComplaintId();
    this.answerMessageThreadForm.reset();
    await this.spinner.hide();
  }

  public async loadMessage(consecutive, id) {
    if (id != null) {
      await this.ticketService.getTicketAnswerMessages(consecutive, id);
      await this.ticketAnswerQuery.setActive(id);
    } else {
      await this.ticketAnswerQuery.setActive(null);
    }
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      this.files_.push(item);
    }
    this.files.patchValue(this.files_);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files_.splice(index, 1);
    this.files.patchValue(this.files_);
  }

  get files() {
    return this.answerMessageThreadForm.get('files');
  }

  isValidForAction(ticketState) {
    return !Utils.isInvalidForAction(ticketState);
  }

  isAnswerable(state) {
    return Object.values(Utils.answerableTicketStates).includes(state);
  }
  
  async approveAnswer() {
    this.selectedAnswer$.subscribe(x => {
      if (x.typeExtraRequest.name == 'Pronouncement') {
        this.setForm(true, 'is_for_approval')
      } else {
        this.sendApproveAnswer()
      }
    });
    
  }

  async sendApproveAnswer() {
    await swal.fire({
      title: '¿Está seguro de esta aprobación?',
      text: 'Recuerde que esta acción es irreversible...',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, realizar aprobación!',
      cancelButtonText: 'No, quiero pensarlo mejor'
    }).then(async (result) => {
      if (result.value) {
        await this.approveAnswer_(null, true);
        swal.fire('Excelente...', 'Hemos realizado la debida aprobación, como lo indicaste!', 'success')
      }
    });
  }

  async approveAnswer_(message = null, isApproved = false) {
    try {
      await this.spinner.show();
      const activeTicket = this.ticketAnswerQuery.naturalActive();
      const form = {
        extraRequestTicketId: this.selectedMessage,
        message: message ? message : 'Aprobado',
        approve: isApproved,
      };
      if (this.isApproval) { 
        if (this.answerBenefit == null) {
          swal.fire('Ooops...', 'Selecciona una opción válida!', 'warning')
        } else {
          form['typeResponseTicketExtraId'] = this.answerBenefit 
        }
      }
      await this.ticketService.approveOrMessagePronouncement(form, activeTicket.typeExtraRequest.id);
      await this.baseFormAction();
      this.setForm(false);
    } catch (error) {
      await this.spinner.hide();
      this.setForm(false, null);
    }
  }

  public async onlyNotifyPronouncement() {
    return await swal.fire({
      html: "¿Deseas enviar nuevamente la notificación del pronunciamiento?",
      icon: 'question',
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonText: 'Si, enviar!',
      cancelButtonText: 'No, quiero pensarlo mejor'
    }).then(async (result) => {
      if (result.value)
        await this.onlyNotifyPronoucement_();
      return result.isConfirmed;
    });
  }

  async onlyNotifyPronoucement_() {
    try {
      this.wasSendMailNotification = true;
      await this.spinner.show();
      await this.ticketService.notifyPronouncement(this.selectedMessage);
      await this.baseFormAction();
      this.setForm(false);
    } catch (error) {
      await this.spinner.hide();
      this.setForm(false, null);
    }
  }

  public async approvePronouncement(accept : Boolean) {
    var confirmationMessage = accept ? 
      "¿Confirmas que la entidad <b>se acoge al pronunciamiento</b>?": "¿Deseas notificar que la entidad <b>no acoge el pronunciamiento</b>?";

    return await swal.fire({
      html: confirmationMessage,
      icon: accept ? 'question' : 'warning',
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonText: 'Si, confirmar!',
      cancelButtonText: 'No, quiero pensarlo mejor'
    }).then(async (result) => {
      if (result.value)
        await this.approvePronouncement_(accept);
      return result.isConfirmed;
    });
  }

  async approvePronouncement_(isApproved: Boolean) {
    try {
      await this.spinner.show();
      const form = {
        extraRequestTicketId: this.selectedMessage,
        message: isApproved ? 'Acogido por la entidad' : 'No acogido por la entidad',
        approve: isApproved,
      };
      
      form['typeResponseTicketExtraId'] = this.answerBenefit;
      await this.ticketService.answerPronouncement(form);
      await this.baseFormAction();
      this.setForm(false);
    } catch (error) {
      await this.spinner.hide();
      this.setForm(false, null);
    }
  }

  // Boton eliminación pronunciamiento.
  public async hideAnswer() {    
    const activeTicket = this.ticketAnswerQuery.naturalActive();  
    console.log(activeTicket.typeExtraRequest.id)    
    return await swal.fire({
      html: "¿Esta seguro que desea <b>eliminar el pronunciamiento</b>?",
      icon: 'warning',
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonText: 'Si, confirmar!',
      cancelButtonText: 'No, quiero pensarlo mejor'
    }).then(async (result) => {
      if (result.value)
        await this.hideAnswer_();
      return result.isConfirmed;
    });
  }

  async hideAnswer_() {
    try {
      await this.spinner.show();
      const activeTicket = this.ticketAnswerQuery.naturalActive();        
      const form = { 
        extraRequestTicketId: this.selectedMessage,         
        typeExtraRequestId: activeTicket.typeExtraRequest.id
      };           

      await this.ticketService.hideAnswer(form);
      await this.baseFormAction();
      this.setForm(false);
      this.selectMessage(null);
    } catch (error) {
      await this.spinner.hide();
      this.setForm(false, null);
    }
  }

  changeSubTab(typeValue) {
    this.subTab = typeValue;
    this.ticketAnswerQuery.setTypeFilter(typeValue)
    this.ticketAnswers$ = this.ticketAnswerQuery.ticketAnswer$();
    this.location.replaceState(this.router.createUrlTree(
      [`/complaints/${this.route.snapshot.params['id']}`],
      {queryParams: {
        tab: 'answers',
        selectedAnswer: this.selectedMessage,
        subTab: typeValue
      }}
    ).toString())
  }

  isPronoucementForApproval() {    
    if (this.selectedMessage === null) { return of(false); }

    if (this.roleDetail.isEntity) {
      const activeTicket = this.ticketAnswerQuery.naturalActive();
      if (activeTicket.typeExtraRequest.name == 'Pronunciamiento') {
        return this.ticketAnswerMessage$.pipe(map(it => it.filter(x => [21,20].includes(x.typeResponseTicketExtraId)).length == 0));
      }
    }
    
    return of(false);
  }

  isForApproval() {
    if (this.selectedMessage === null) { return of(false); }

    const activeTicket = this.ticketAnswerQuery.naturalActive();      
    if (this.roleDetail.isApprover || this.roleDetail.isLawyer) {      
      if (activeTicket.typeExtraRequest.name == 'Creacion Voceria') {
        return this.ticketAnswerMessage$.pipe(map(it => it.filter(x => x.message == 'Aprobado').length == 0))
      } else if (activeTicket.typeExtraRequest.name == 'Pronunciamiento') {
        return this.ticketAnswerMessage$.pipe(map(it => it.filter(x => x.message == 'Aprobado').length == 0))
      }
    } else if (this.roleDetail.isEntity) {
      if (activeTicket.typeExtraRequest.name == 'Pronunciamiento') {
        return of(true)
      }
    }

    return of(false);
  }

  // Authorisation to deleate answer.
  isForDeletion() {
    if (this.selectedMessage === null) { return of(false); }
    if (!this.roleDetail.isAdmin && !this.roleDetail.isLawyer) { return of(false); }
    
    const activeTicket = this.ticketAnswerQuery.naturalActive();
    if(!activeTicket)  { return of(false); }

    //Pronunciamiento, Creacion Voceria, Solicitud Apremio, Extension Ticket
    return of([7,6,5,3].includes(activeTicket.typeExtraRequest.id));
  }

  isAbleForNotifyApproval() {
    if (this.selectedMessage === null) { return of(false); }

    const activeTicket = this.ticketAnswerQuery.naturalActive();      
    if (this.roleDetail.isApprover || this.roleDetail.isLawyer) {      
      if (activeTicket.typeExtraRequest.name == 'Pronunciamiento') {
        return this.ticketAnswerMessage$.pipe(map(it => it.filter(x => x.message == 'Aprobado').length > 0))
      }
    } 

    return of(false);
  }

  isValidAnswer$ = (isValidBase) => {
    return this.selectedAnswer$.pipe(map(selectedAnswer => {
      if (typeof selectedAnswer != 'undefined') {
        if (selectedAnswer.typeExtraRequest.name == 'Pronunciamiento') {
          if (!this.roleDetail.isLawyer && !this.roleDetail.isApprover) {
            return isValidBase
          } else {
            return isValidBase || this.answerMessageThreadForm.value['typeResponseTicketExtraId'] == null;
          }
        } else {
          return isValidBase
        }
      } else {
        return isValidBase
      }
      
    }))
  }

  onFilterCheckBox(e) {
    this.isInconsistency = e.target.checked;
  }

}
