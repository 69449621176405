import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import swal from 'sweetalert2';
import { NgSelectConfig } from '@ng-select/ng-select';
import { ToolbarService, LinkService, ImageService, HtmlEditorService } from '@syncfusion/ej2-angular-richtexteditor';
import * as _ from 'lodash';
import { ExternalService } from 'src/app/services/external.service';
import { TypePersonQuery } from 'src/app/states/type_person/type_person.query';
import { map, shareReplay } from 'rxjs/operators';
import { DepartmentQuery } from 'src/app/states/department/department.query';
import { GenderQuery } from 'src/app/states/gender/gender.query';
import { EducationLevelQuery } from 'src/app/states/education_level/education_level.query';
import { EntityProductQuery } from 'src/app/states/entity_product/entity_product.query';
import { CompanyQuery } from 'src/app/states/company';
import { combineLatest, Observable } from 'rxjs';
import { Utils } from 'src/app/common/utils';
import { AttachedFilesRequestPayload, NO, YES, DefaultSpecialCondition, DefaultCountry } from 'src/app/interfaces/external.interface';
import { NgxSpinnerService } from "ngx-spinner";
import { CustomValidators } from 'src/app/common/validators';
import * as moment from 'moment';
import { SpecialConditionQuery } from 'src/app/states/special_condition';
import { Countries } from 'src/app/common/countries';

@Component({
  selector: 'complaint',
  templateUrl: './complaint.html',
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService],
  styles: [`.col-form-label { font-weight: 600; }`]
})

export class ComplaintPage implements OnDestroy, OnInit {

  @ViewChild('PageContent', { static: false }) PageContent: any;
  closeResult: string;
  currentStep = 1;
  ticketId = "";

  files_: File[] = [];
  entity: number;

  years_ago_init = {
    year: 1750,
    month: 1,
    day: 1
  }

  years_ago_init_event = {
    year: 2015,
    month: 1,
    day: 1
  }

  today_mark = {
    year: moment().year(),
    month: moment().month() + 1,
    day: moment().date()
  }

  personalInfoForm = new FormGroup({
    typePerson: new FormControl(null, [Validators.required]),
    typeDocumentId: new FormControl(null, [Validators.required]),
    serial: new FormControl(null, {
      validators: [Validators.required, Validators.minLength(5), CustomValidators.numeric],
      updateOn: 'blur'
    }),
    documentExpeditionDate: new FormControl(null, [Validators.required]),
    name: new FormControl(null, [Validators.required, Validators.minLength(7)]),
    email: new FormControl(null, [Validators.required, Validators.email, CustomValidators.email]),
    cellPhone: new FormControl(null, [Validators.required, Validators.minLength(7), Validators.maxLength(10)]),
    address: new FormControl(null),
    genreTypeId: new FormControl(null),
    academicLevelId: new FormControl(null),
    retire: new FormControl(null),
    userId: new FormControl(null),
    specialConditionId: new FormControl(DefaultSpecialCondition, [Validators.required]),
  });

  complaintInfoForm = new FormGroup({
    //default fields
    cost: new FormControl(0),    
    typeTicket: new FormControl(1),
    channelId: new FormControl(null),
    eventDate: new FormControl(null),
    digital: new FormControl(null),
    typeComplaint: new FormControl(20),

    product: new FormControl(null, [Validators.required]),        
    city: new FormControl(null, Validators.required),
    country: new FormControl(DefaultCountry, Validators.required),
    subject: new FormControl(null, [Validators.required, Validators.minLength(7)]),
    acts: new FormControl(null, [Validators.required, Validators.minLength(20)]),
    request: new FormControl(null, [Validators.required, Validators.minLength(20)])
  });

  requestInfoForm = new FormGroup({
    files: new FormControl([]),
  });

  termsInfoForm = new FormGroup({
    reclamation_accept_terms_and_conditions: new FormControl(null, [Validators.required]),
    not_a_robot: new FormControl(null, [Validators.required])
  });

  jubilateOptions = [
    {
      id: NO,
      name: 'No'
    },
    {
      id: YES,
      name: 'Si'
    }
  ];

  products$ = this.entityProductQuery.entityClientsProduct$();

  countryOptions = Countries;

  specialCondition$ = this.specialConditionQuery.specialCondition$();

  cities$ = this.departmentQuery.department$();

  genders$ = this.genderQuery.gender$();

  educationLevels$ = this.educationLevelQuery.educationLevel$();

  personalTypes$ = this.typePersonQuery.typePerson$();

  documentTypes$ = Observable.of(null);

  entities$ = this.companyQuery.company$();

  currentEntity$ = combineLatest(
    this.companyQuery.company$(),
    this.route.params,
    this.companyQuery.isLoading$(),
    (companyQuery, params, loading) => ({ companyQuery, params, loading })
  ).pipe(
    shareReplay(1),
    map(
      it => {
        if (!it.loading) {
          const filteredData = it.companyQuery.find(x => x.id === parseInt(it.params['id'], 10));
          if (filteredData == null) { this.router.navigate(['not_found']); }
          return filteredData;
        }
      }
    ));

  ckeConfig: any;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private config: NgSelectConfig,
    private externalService: ExternalService,
    private typePersonQuery: TypePersonQuery,
    private departmentQuery: DepartmentQuery,
    private specialConditionQuery: SpecialConditionQuery,    
    private genderQuery: GenderQuery,
    private educationLevelQuery: EducationLevelQuery,
    private entityProductQuery: EntityProductQuery,    
    private companyQuery: CompanyQuery,
    private spinner: NgxSpinnerService

  ) {
    this.config.notFoundText = 'No se encontraron resultados';
    this.config.bindValue = 'name';
    this.config.loadingText = 'Cargando...';
  }

  ngOnInit() {
    this.spinner.show();
    this.route.params.subscribe(async it => {
      this.entity = parseInt(it['id'], 10);
      // Get Entereprise information
      this.companyQuery.setLoading(true);
      await this.externalService.getEntities();
      this.companyQuery.setLoading(false);

      // Page 1
      await this.externalService.getTypePersonAndDocuments();
      await this.externalService.getDepartmentsAndCities();
      await this.externalService.getGenders();
      await this.externalService.getEducationLevels();
      await this.externalService.getSpecialConditions();

      // Page 2
      await this.externalService.getProductsByEntity(parseInt(it['id'], 10));            
      this.spinner.hide();
    });
    // Events
    this.personalInfoForm.controls.typePerson.valueChanges.subscribe(it => {
      this.personalInfoForm.patchValue({ typeDocumentId: null, genreTypeId: null });
      this.documentTypes$ = this.typePersonQuery.selectDocument(parseInt(it, 10));
    });
    this.ckeConfig = {
      allowedContent: true,
      extraPlugins: ['divarea', 'uploadimage'],
      isFileUploadSupported: false,
      removePlugins: 'image',
      forcePasteAsPlainText: true
    };
  }

  isEnterpriseInformation() {
    if (this.typePerson.value == null) { return true; }
    return parseInt(this.typePerson.value, 10) === 2;
  }

  resolveCaptcha() {
    this.not_a_robot.patchValue(true);
  }

  onSendStep1() {    
    if (!this.personalInfoForm.valid) {
      swal.fire('Revisaste tu información?', 'Parece que no has diligenciado correctamente toda tu información personal', 'error');
      return;
    }
    this.nextStep();
  }

  onSendStep2() {
    if (!this.complaintInfoForm.valid) {
      swal.fire('Revisaste tu información?', 'Parece que no has diligenciado correctamente toda la información sobre tu solicitud', 'error');
      return;
    }
    this.nextStep();
  }

  onSendStep3() {
    const size = this.getTotalFilesSize();
    if (this.files_.length > 20 || size >= 100000000 ) {
      swal.fire('Algo anda mal', 'Recuerda que solo puedes subir hasta 20 archivos y el total de sus pesos juntos no pueden exceder los 100 MB (MegaBytes)', 'error');
      return;
    } else if (this.files_.length == 0 && this.validateIfRequireSupports()) {
      swal.fire('Espera!', 'Recuerda que debes subir el certificado de condición especial!', 'warning');
      return;
    }
    this.nextStep();
  }

  async onSendStep4() {
    try {
      if (!this.termsInfoForm.valid) {
        swal.fire('Algo anda mal?', 'Prueba aceptando nuestras políticas de uso de datos', 'error');
        return;
      }
      await this.spinner.show();

      this.ticketId = await this.saveCurrentForm();
      this.resetForms();
      await this.spinner.hide();
    } catch (error) {
      await this.spinner.hide();
    }
  }

  validateIfRequireSupports(): Boolean {
    var markSpecial = this.personalInfoForm.get("specialConditionId").value;
    return markSpecial != DefaultSpecialCondition;
  }

  async searchUserMoanfulData(documentNumber: number) {
    this.personalInfoForm.disable();

    if (documentNumber == null) {
      this.personalInfoForm.enable();      
      return;
    }

    const response = await this.externalService.getUserMoanfulData(documentNumber);
    if (response == null) {
      this.personalInfoForm.enable();
      this.personalInfoForm.reset();
      this.personalInfoForm.controls['specialConditionId'].setValue(DefaultSpecialCondition);
      this.personalInfoForm.controls['serial'].setValue(documentNumber);            
      return;
    }

    const docExpDate = response.documentExpeditionDate.split('T')[0].split('-');
    const newData = {
      name: response.name,
      cellPhone: response.cellPhone == 0 ? null : response.cellPhone,
      address: response.address,
      typePerson: response.typePerson,
      academicLevelId: response.academicLevelId,
      genreTypeId: response.genreTypeId,
      userId: response.userId,
      typeDocumentId: response.typeDocumentId,
      documentExpeditionDate: { year: parseInt(docExpDate[0], 10), month: parseInt(docExpDate[1], 10), day: parseInt(docExpDate[2], 10) },
      retire: response.retire,
      email: response.email,
      city: null,
      specialConditionId: response.specialConditionId
    };
    
    this.personalInfoForm.patchValue(newData)
    this.enablePersonalFields();
  }

  enablePersonalFields() {
    this.personalInfoForm.controls['serial'].enable();
    this.personalInfoForm.controls['email'].enable();
    this.personalInfoForm.controls['cellPhone'].enable();
    this.personalInfoForm.controls['academicLevelId'].enable();
    this.personalInfoForm.controls['retire'].enable();
    this.personalInfoForm.controls['specialConditionId'].enable();
    this.personalInfoForm.controls['address'].enable();
  }

  resetForms() {
    this.personalInfoForm.reset();
    this.complaintInfoForm.reset();
    this.requestInfoForm.reset();
    this.termsInfoForm.reset();
    this.files_ = [];
  }

  nextStep() {    
    this.currentStep++;
  }

  prevStep() {
    this.currentStep--;
  }

  selectionCountryChanged(e) {
    if (e.id != DefaultCountry)
      this.complaintInfoForm.get('city').disable();
    else
      this.complaintInfoForm.get('city').enable();
  }

  // Form controls

  get typePerson() {
    return this.personalInfoForm.get('typePerson');
  }

  get typeDocumentId() {
    return this.personalInfoForm.get('typeDocumentId');
  }

  get serial() {
    return this.personalInfoForm.get('serial');
  }

  get documentExpeditionDate() {
    return this.personalInfoForm.get('documentExpeditionDate');
  }

  get name() {
    return this.personalInfoForm.get('name');
  }

  get email() {
    return this.personalInfoForm.get('email');
  }

  get cellPhone() {
    return this.personalInfoForm.get('cellPhone');
  }

  get city() {
    return this.personalInfoForm.get('city');
  }

  get address() {
    return this.personalInfoForm.get('address');
  }

  get academicLevelId() {
    return this.personalInfoForm.get('academicLevelId');
  }

  get genreTypeId() {
    return this.personalInfoForm.get('genreTypeId');
  }

  get retire() {
    return this.personalInfoForm.get('retire');
  }

  get special() {
    return this.personalInfoForm.get('specialConditionId');
  }

  get country() {
    return this.complaintInfoForm.get('country');
  }

  get product() {
    return this.complaintInfoForm.get('product');
  }

  get channel() {
    return this.complaintInfoForm.get('channel');
  }

  get typeComplaint() {
    return this.complaintInfoForm.get('typeComplaint');
  }

  get cost() {
    return this.complaintInfoForm.get('cost');
  }

  get eventDate() {
    return this.complaintInfoForm.get('eventDate');
  }

  get subject() {
    return this.complaintInfoForm.get('subject');
  }

  get acts() {
    return this.complaintInfoForm.get('acts');
  }

  get request() {
    return this.complaintInfoForm.get('request');
  }

  get files() {
    return this.requestInfoForm.get('files');
  }

  get reclamation_accept_terms_and_conditions() {
    return this.termsInfoForm.get('reclamation_accept_terms_and_conditions');
  }

  get not_a_robot() {
    return this.termsInfoForm.get('not_a_robot');
  }

  async saveCurrentForm(): Promise<string> {    
    const personalForm = {
      ...this.personalInfoForm.getRawValue(),
      documentExpeditionDate: Utils.getDate(this.personalInfoForm.value.documentExpeditionDate)
    }
    
    const complaintForm = {
      ...this.complaintInfoForm.value,
      eventDate: Utils.getDate(this.complaintInfoForm.value.eventDate)
    } 

    const form = _.merge(
      personalForm,
      complaintForm,
      { entity: this.entity }
    );
    
    const response = await this.externalService.sendTicket(form);
    const attachedFiles = {
      ticketId: response.data.ticketID,
      files: this.requestInfoForm.value['files']
    } as AttachedFilesRequestPayload;
    if (attachedFiles.files.length > 0) {
      await this.externalService.attachTicketFiles(attachedFiles);
    }

    this.nextStep();
    return response.data.consecutive;
  }

  // ========================= Drag & Drop Events =====================================

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files_.splice(index, 1);
    this.files.patchValue(this.files_);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      this.files_.push(item);
    }
    this.files.patchValue(this.files_);
  }

  getTotalFilesSize() {
    if(this.files_.length === 0)
      return 0;
    return this.files_.map(i=> i.size).reduce((a,b)=> a + b)
  }

  getBytes(bytes, decimals = 2) {
    const k = 1024;
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat(`${(bytes / Math.pow(k, i))}`);
  }

  getItemStringSize(bytes) {
    const k = 1024;
    const sizes_n = this.formBaseSize(false);
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return sizes_n[i];
  }

  formBaseSize(is_string) {
    if (is_string) {
      return ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    }
    return [1, 2, 3, 4, 5, 6, 7, 8, 9];
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const values = this.getBytes(bytes, decimals).toFixed(dm);
    const k = 1024;
    const sizes = this.formBaseSize(true);
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return values + ' ' + sizes[i];
  }

  ngOnDestroy() {
    this.resetForms();
  }

  acceptTerms() {
    this.termsInfoForm.patchValue({ reclamation_accept_terms_and_conditions: true });
  }

  rejectTerms() {
    this.termsInfoForm.patchValue({ reclamation_accept_terms_and_conditions: false });
  }

  open(content) {
    this.modalService.open(content, { size: 'xl', backdrop: 'static' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
