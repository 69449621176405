import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import swal from 'sweetalert2';
import { NgSelectConfig } from '@ng-select/ng-select';
import { ToolbarService, LinkService, ImageService, HtmlEditorService } from '@syncfusion/ej2-angular-richtexteditor';
import * as _ from 'lodash';

import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from 'src/app/services/auth.service';
import { Observable } from 'rxjs';
import { UserData } from 'src/app/states/page_settings/page_settings.store';
import { PageSettingsQuery } from 'src/app/states/page_settings';
import { CustomValidators } from 'src/app/common/validators';


@Component({
  selector: 'user_profile',
  templateUrl: './user_profile.html',
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService]
})

export class UserProfilePage implements OnInit  {
  closeResult: string;
  changePasswordForm = new FormGroup({
    oldPassword: new FormControl(null, Validators.required),
    newPassword: new FormControl(null, [Validators.required, CustomValidators.password]),
  });

  ckeConfig: any;
  userData$: Observable<UserData>;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private config: NgSelectConfig,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private pageSettingsQuery: PageSettingsQuery

  ) {
    this.config.notFoundText = 'No se encontraron resultados';
    this.config.bindValue = 'name';
    this.config.loadingText = 'Cargando...';

  }

  async ngOnInit() {
    this.spinner.show();
    this.userData$ = this.pageSettingsQuery.userData$();
    
    this.spinner.hide();
  }
 
  async onSubmitChangePassword() {
    try {
      this.spinner.show();
      const form = this.changePasswordForm.value;
      await this.authService.changePassword(form);
      swal.fire('Excelente...', 'Tu contraseña se ha cambiado exitosamente!', 'success')
      this.changePasswordForm.reset();
      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      swal.fire('Ooops...', error.message, 'error')
    }
  } 

  // Form controls

  get oldPassword() {
    return this.changePasswordForm.get('oldPassword');
  }

  get newPassword() {
    return this.changePasswordForm.get('newPassword');
  }

}
