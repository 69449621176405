<div class="login-cover">
    <div class="login-cover-image" style="background-image: url(../assets/img/cover/home-cover.jpg)"
        data-id="login-cover-image"></div>
    <div class="login-cover-bg-purple"></div>
</div>

<div class="container login-cover">
    <div class="row" style="position: relative; top: 50%; transform: translateY(-50%);">
        <div class="col-xs-1 col-sm-3 col-lg-4"></div>
        <div class="col-xs-10 col-sm-6 col-lg-4">
            <div class="text-center">
                <img src="https://defensorialg.com.co/wp-content/uploads/2020/03/logodcflg-3.png" style="width: 60%;">
            </div>
            <br>
            <br>
            <br>
            <div class="card border-0 text-center">
                <div class="card-body">
                    <div class="clearfix">
                        <br />
                        <svg height="150px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 160" x="0px" y="0px">
                            <path
                                d="M81,6V5.94043A5.94738,5.94738,0,0,0,75.05957,0H16.94043A5.94738,5.94738,0,0,0,11,5.94043V122.05957A5.94738,5.94738,0,0,0,16.94043,128H75.05957A5.94738,5.94738,0,0,0,81,122.05957V60.28333L77,62.413v59.64655A1.94217,1.94217,0,0,1,75.05957,124H16.94043A1.94217,1.94217,0,0,1,15,122.05957V5.94043A1.94217,1.94217,0,0,1,16.94043,4H75.05957A1.94217,1.94217,0,0,1,77,5.94043V6Z" />
                            <circle cx="22" cy="11" r="3" />
                            <path d="M42,118a2,2,0,0,0,0,4h8a2,2,0,0,0,0-4Z" />
                            <path
                                d="M69,78.416V53.584A3.5876,3.5876,0,0,0,65.416,50H26.584A3.5876,3.5876,0,0,0,23,53.584V78.416a3.557,3.557,0,0,0,.65527,2.054,1.9846,1.9846,0,0,0,.30957.38055l.01239.00952A3.56473,3.56473,0,0,0,26.584,82H65.416A3.5876,3.5876,0,0,0,69,78.416ZM57.433,66.77386l-3.26807,2.36L62.45007,78H29.36865L37.769,69.01544l-3.24274-2.38922L27,74.6759V56.11316L43.666,68.3916a3.76456,3.76456,0,0,0,4.66211.02344L65,56.37512v18.4964ZM30.87628,54H61.45734L46.01367,65.15234Z" />
                            <path
                                d="M95.17383,48.23242h16.47852A6.32067,6.32067,0,0,0,118,41.95313V16.2793A6.32067,6.32067,0,0,0,111.65234,10H70.34766A6.32067,6.32067,0,0,0,64,16.2793V41.95313a6.32067,6.32067,0,0,0,6.34766,6.2793h5.30078l-4.876,8.79785a1.99992,1.99992,0,0,0,2.68848,2.73535L95.07617,48.25684A.21581.21581,0,0,1,95.17383,48.23242Zm-1.97461-3.50781-15.65234,8.334L80.793,47.20215a1.99975,1.99975,0,0,0-1.749-2.96973H70.34766A2.31657,2.31657,0,0,1,68,41.95313V16.2793A2.31657,2.31657,0,0,1,70.34766,14h41.30469A2.31657,2.31657,0,0,1,114,16.2793V41.95313a2.31657,2.31657,0,0,1-2.34766,2.2793H95.17383a4.22532,4.22532,0,0,0-1.97266.49121Z" />
                            <path
                                d="M82.68945,26.39258a2.00278,2.00278,0,0,0-2.76074-.61816L79,26.36322v-.54974a2,2,0,1,0-4,0v.54974l-.92871-.58881a2.00048,2.00048,0,0,0-2.14258,3.37891L73.2641,30l-1.33539.84668a2.00048,2.00048,0,0,0,2.14258,3.37891L75,33.63678v.54974a2,2,0,0,0,4,0v-.54974l.92871.58881a2.00048,2.00048,0,1,0,2.14258-3.37891L80.7359,30l1.33539-.84668A2.00153,2.00153,0,0,0,82.68945,26.39258Z" />
                            <path
                                d="M96.68945,33.60742a2.00153,2.00153,0,0,0-.61816-2.76074L94.7359,30l1.33539-.84668a2.00048,2.00048,0,1,0-2.14258-3.37891L93,26.36322v-.54974a2,2,0,1,0-4,0v.54974l-.92871-.58881a2.00048,2.00048,0,0,0-2.14258,3.37891L87.2641,30l-1.33539.84668a2.00048,2.00048,0,0,0,2.14258,3.37891L89,33.63678v.54974a2,2,0,0,0,4,0v-.54974l.92871.58881a2.00174,2.00174,0,0,0,2.76074-.61816Z" />
                            <path
                                d="M99.31055,33.60742a2.00174,2.00174,0,0,0,2.76074.61816L103,33.63678v.54974a2,2,0,0,0,4,0v-.54974l.92871.58881a2.00048,2.00048,0,0,0,2.14258-3.37891L108.7359,30l1.33539-.84668a2.00048,2.00048,0,0,0-2.14258-3.37891L107,26.36322v-.54974a2,2,0,1,0-4,0v.54974l-.92871-.58881a2.00048,2.00048,0,0,0-2.14258,3.37891L101.2641,30l-1.33539.84668A2.00153,2.00153,0,0,0,99.31055,33.60742Z" />
                        </svg>

                        <form [formGroup]="validateForm" (ngSubmit)="onSubmit()" class="margin-bottom-0 p-l-15 p-r-15">
                            <div class="form-group m-b-15">
                                <h4 class="card-title text-dark m-b-0">Verificación en dos pasos</h4>
                                <label class="col-form-label">Código de verificación</label>
                                <input class="form-control form-control-lg" (focus)='clearInput()'
                                    [ngClass]="{'is-invalid': validateForm.controls.otp.errors?.invalidOtp}"
                                    formControlName="otp" type="text" placeholder="* * * *" minlength="4" maxlength="4"
                                    style="text-align: center;">
                                <small class="text-danger m-t-5" *ngIf="validateForm.controls.otp.errors?.invalidOtp">El
                                    código ingresado no es válido</small>
                            </div>
                            <button type="submit" [disabled]="!validateForm.valid"
                                class="btn btn-success btn-block btn-lg m-b-15">Continuar</button>
                            <hr />
                            <div class="row">
                                <div class="col-sm-6 text-left">
                                    <button type="button" *ngIf="secondsLeft != 0" disabled class="btn btn-link btn-xs">
                                        Restante: 00:{{ secondsLeft }}
                                    </button>
                                    <button type="button" *ngIf="secondsLeft == 0" (click)="cancel()" class="btn btn-link btn-xs">
                                        Volver
                                    </button>
                                </div>
                                <div class="col-sm-6 text-right">
                                    <button type="button" [disabled]="!resendEnabled" (click)="resendOTP()" class="btn btn-link btn-xs">
                                        Reenviar código
                                    </button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
            <div class="text-center">
                <br>
                <h3><small style="color: #ffffff96;">Si tienes problemas para ingresar a la aplicación por favor
                        contacta con soporte técnico</small></h3>
            </div>
        </div>
    </div>
    <br />
    <br />
</div>