import { Component, ViewChild, AfterViewInit, ElementRef } 		 from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'panel',
  inputs: ['title', 'variant', 'noBody', 'noButton', 'bodyClass', 'footerClass', 'panelClass'],
  templateUrl: './panel.component.html'
})

export class PanelComponent implements AfterViewInit {
  @ViewChild('panelFooter', { static: false, read: ElementRef }) panelFooter: ElementRef;
  expand = false;
  reload = false;
  collapse = false;
  remove = false;
  showFooter = false;
  title: string|null = null;
  variant: string|null = null;
  noBody: boolean|null = false;
  noButton: boolean|null = false;
  bodyClass: string|null = null;
  footerClass: string|null = null;
  panelClass: string|null = null;

  ngAfterViewInit() {
    setTimeout(() => {
      if (typeof this.panelFooter !== 'undefined') {
        this.showFooter = this.panelFooter.nativeElement && this.panelFooter.nativeElement.children.length > 0;
      }
    });
  }

  panelExpand() {
    this.expand = !this.expand;
  }
  panelReload() {
    this.reload = true;

    setTimeout(() => {
        this.reload = false;
    }, 1500);
  }
  panelCollapse() {
    this.collapse = !this.collapse;
  }
  panelRemove() {
    this.remove = !this.remove;
  }
}
