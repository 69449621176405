import { AuthService } from 'src/app/services/auth.service';
import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { map, shareReplay, tap, filter, startWith, switchMap, flatMap } from 'rxjs/operators';
import { routes } from 'src/app/config/routes';
import { Observable } from 'rxjs-compat';
import { TicketUser, TicketUserState, TicketUserStore } from './ticket_user.store';
import { of } from 'rxjs';
import { BaseQuery } from '../base/base.query';

@Injectable({
  providedIn: 'root'
})
export class TicketUserQuery extends BaseQuery<TicketUserState> {
  constructor(protected store: TicketUserStore) {
    super(store);
  }

  active$ = () => this.selectTicketUser();

  getSnapshot = () => this.getValue();

  isLoading$ = () => this.selectLoading();

  ticketUser$ = () => this.selectTicketUser();
  ticketUserCount$ = () => this.selectCount(it => it != null)

  selectTicketUser() {
    return this.selectActive(it => it) as Observable<TicketUser>;
  }

  setLoading(isLoading) {
    this.store.setLoading(isLoading);
  }


  setState(data, activeId) {
    this.store.set(data, activeId);
  }

}
