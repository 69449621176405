import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { removeEmptyJsonFields, Utils } from 'src/app/common/utils';
import { Filter, TicketTableFormat } from 'src/app/interfaces/ticket.interface';
import { IPaginate } from 'src/app/services/base.service';
import { TicketService } from 'src/app/services/ticket.service';
import { Location } from '@angular/common';
import { LoadingService } from 'src/app/services/loading.service';
import { FormControl, FormGroup } from '@angular/forms';
import { CompanyQuery } from 'src/app/states/company';
import { ExternalService } from 'src/app/services/external.service';
import { merge, Observable, Subject } from 'rxjs';
import { ModalDismissReasons, NgbModal, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, filter, shareReplay } from 'rxjs/operators';
import { TypeComplaintQuery } from 'src/app/states/type_complaint';
import { NgxSpinnerService } from 'ngx-spinner';
import { PaginationConfig } from './data';
import { AuthService, IRoleDetail } from 'src/app/services/auth.service';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { Moment } from 'moment';
import * as moment from 'moment';
import swal from 'sweetalert2';
import { UserService } from 'src/app/services/user.service';
import { User, UserQuery } from 'src/app/states/user';

@Component({
  selector: 'users_list',
  templateUrl: './users_list.html'
})

export class UsersListPage implements OnInit {
  roleDetail: IRoleDetail;
  closeResult: string;

  public rows:Array<any> = [];
  public paginateData: IPaginate<any> = {
    data: [],
    totalPage: 0,
    totalRegister: 0
  };
  public filters: Filter = {
    page: null,
    loading: true
  };

  years_ago_init = {
    year: 1750,
    month: 1,
    day: 1
  }
  today_mark = {
    year: moment().year(),
    month: moment().month() + 1,
    day: moment().date()
  }

  public config = PaginationConfig(this.authService.getRole());
  public columns = this.config.baseConfig.sorting.columns;

  public localeDateRange = Utils.DATA_RANGE_PICKER_LOCALE_FORMAT;
  public rangesCreationRange = Utils.DATE_RANGES_FORMATS;
  public creationDataInit = {
    startDate: null,
    endDate: null
  }

  public filtersForm = new FormGroup({
    id: new FormControl(null),
    email: new FormControl(null),
    serial: new FormControl(null),
    name: new FormControl(null),
    lastname: new FormControl(null),
  });


  public constructor(
    private router: Router,
    private userService: UserService,
    private userQuery: UserQuery,
    private route: ActivatedRoute,
    private location: Location,
    private loadingService: LoadingService,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private modalService: NgbModal,
  ) {
    this.spinner.show();
    this.loadingService.setLoading(true);
    
  }

  public async ngOnInit() {
    this.roleDetail = this.authService.getRoleDetail();
    await this.setLoading(true)
    const it = this.route.snapshot.queryParams;
    this.setPage( it['page'] ? (parseInt(it['page'], 10)) : 1)
    // if (this.roleDetail.isApprover) {
    //   this.filters['stateTicket'] = 'Defensoria-revision';
    //   this.goToUsersWithQueryParams();
    // }
    this.setFilters(it);
    await this.changePage(0, false, true)
  }

  public getFormatedRow(row, columnName: string) {
    if (typeof row[columnName] === 'undefined') {
      return '-'
    }
    if (columnName.includes('Date')) {
      if (row[columnName].includes('T')) {
        return row[columnName].split('T')[0]
      }

      if (columnName.includes('maxDate')) {
        return row['extesionMaxDate'] ? row['extesionMaxDate'] : row['maxDate']
      }
      
      return row[columnName]
    }

    if (columnName.includes('counterDays')) {
      return `${row[columnName]} Días hábiles`
    }

    if (columnName.includes('consecutive') || columnName.includes('id')) {
      return `<span class="btn-link pointer">${row[columnName]}</span>`;
    }

    if (columnName.includes('stateTicketName')) {
      return `<b>${row[columnName]}</b>`;
    }

    return row[columnName]
  }

  private fetchHtmlData(rows: User[] = this.paginateData.data) {
    return rows.map(x => {
      return {
        ...x,
        id_: this.renderCell(this.renderComplaintId(x.id)),
        typeDocumentName_: this.renderCell(x.typeDocumentName),
        serial_: this.renderCell(x.serial),
        name_: this.renderCell(`${x.name} ${x.lastname}`),
        rolName: this.renderCell(x.rol.name),
        
      };
    });
  }

  getRowClass(row) {
    return '';
  }

  private isSent(stateTicketId) {
    return stateTicketId !== 1;
  }

  private renderIsSent(isSent) {
    return isSent ? 'Si <i class="fa fa-check-circle"></i>' : 'No <i class="fa fa-times-circle"></i>' ;
  }

  private renderCell(templateString) {
    return templateString;
  }

  private renderComplaintId(value) {
    return `<a>${value}</a>`;
  }

  public async submitFilters(c) {
    this.addFilters();
    await this.onChangeTable(true)
    c('Close click');
  }

  private addFilters() {
    const curForm = this.filtersForm.value;
    const dateFilters = {
      ...curForm['creationDate'],
      ...curForm['assignmentDate_']
    }
    delete curForm['creationDate'];
    delete curForm['assignmentDate_'];
    this.filters = Object.assign(this.filters, {
      ...this.filters,
      ...curForm,
      ...dateFilters
    })
  }

  setPage(page) {
    this.filters.page = page;
  }

  setFilters(it) {
    this.filters = Object.assign(this.filters, {
      ...this.filters,
      ...it
    })
    Object.keys(it).filter(x => !['loading', 'page'].includes(x)).forEach(element => {
      this.filtersForm.controls[element].patchValue(it[element])
    });
  }

  async setLoading(loading) {
    if (loading) {
      this.spinner.show();
    } else {
      this.spinner.hide();
    }
    this.filters.loading = loading;
  }

  async fetchData() {
    const tempFilters = {
      ...this.filters,
      page: this.filters.page > 0 ? this.filters.page - 1 : 0
    }
    delete tempFilters['loading']
    return await this.userService.listUsers(tempFilters);
  }

  public async changePage(event, withRedirect: boolean = true, firstTime: boolean = false) {
    const it = this.route.snapshot.queryParams;
    if (firstTime) {
      this.loadingService.setLoading(true);
      this.setPage( it['page'] ? (parseInt(it['page'], 10)) : 1)
    } else {
      if (this.filters.loading && event == 1) {
        this.loadingService.setLoading(true);
        this.setPage( it['page'] ? (parseInt(it['page'], 10)) : 1)
      } else {
        this.setPage(event)
      }
    }
    await this.onChangeTable(withRedirect)
    this.loadingService.setLoading(false);
  }

  public getComponentLoading() {
    return !this.loadingService.loading;
  }

  public async onChangeTable(withRedirect: boolean = true) {
    try {
      await this.setLoading(true)
      const resume = (await this.fetchData()).data;
      this.paginateData = {
        ...resume,
        data: this.fetchHtmlData(resume.data),
      }
      if (withRedirect) {
        this.goToUsersWithQueryParams()
      }
      await this.setLoading(false)
    } catch (e) {
      await this.setLoading(false)
    }
  }

  private goToUsersWithQueryParams() {
    const tmpFilters = removeEmptyJsonFields(this.filters);
    delete tmpFilters['loading']
    this.location.replaceState(this.router.createUrlTree(
        ['/user/list'],
        {queryParams: tmpFilters}
    ).toString())
    
  }

  public onCellClick(row: TicketTableFormat): any {
    this.router.navigateByUrl(`user/detail/${row.id}`);
  }

  setCreationDateFilters(e: {creationDateStart: Moment, creationDateEnd: Moment}) {
    this.filtersForm.controls.creationDate.patchValue({
      creationDateStart: e.creationDateStart ? e.creationDateStart.format('YYYY-MM-DD') : null,
      creationDateEnd: e.creationDateEnd ? e.creationDateEnd.format('YYYY-MM-DD') : null
    });
  }

  setAssignationDateFilters(e: { assignmentDateStart: Moment, assignmentDateEnd: Moment }) {
    this.filtersForm.controls.assignmentDate_.patchValue({
      assignmentDateStart: e.assignmentDateStart ? e.assignmentDateStart.format('YYYY-MM-DD') : null,
      assignmentDateEnd: e.assignmentDateEnd ? e.assignmentDateEnd.format('YYYY-MM-DD') : null
    });
  }

  open(content) {
    this.modalService.open(content, { size: 'lg', backdrop: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  resetForm() {
    this.filtersForm.reset();
  }


  onFilterCheckBox(e) {
    this.filtersForm.controls.assignmentDate.patchValue(e.target.checked)
  }

  clearCalendar(key: string) {
    this.filtersForm.controls[key].patchValue({
      [`${key.replace('_', '')}Start`]: null,
      [`${key.replace('_', '')}End`]: null,
    })
  }

  get assignmentDate() {
    return this.filtersForm.get('assignmentDate');
  }

  get creationDate_() {
    let str = '';
    if (!this.filtersForm.value.creationDate) { return ''; }
    if (this.filtersForm.value.creationDate.creationDateStart) {
      str += this.filtersForm.value.creationDate.creationDateStart;
    } else {
      str = '';
    }

    if (this.filtersForm.value.creationDate.creationDateEnd) {
      if (this.filtersForm.value.creationDate.creationDateStart) { str += ' - ' }
      str += this.filtersForm.value.creationDate.creationDateEnd;
    } else {
      str = '';
    }
    return str;
  }

  get assignmentDate__() {
    let str = '';
    if (!this.filtersForm.value.assignmentDate_) { return ''; }
    if (this.filtersForm.value.assignmentDate_.assignmentDateStart) {
      str += this.filtersForm.value.assignmentDate_.assignmentDateStart;
    } else {
      str = '';
    }

    if (this.filtersForm.value.assignmentDate_.assignmentDateEnd) {
      if (this.filtersForm.value.assignmentDate_.assignmentDateStart) { str += ' - ' }
      str += this.filtersForm.value.assignmentDate_.assignmentDateEnd;
    } else {
      str = '';
    }
    return str;
  }

  // openCreationDateRangePicker(e) {
  //   this.pickerCreationDirective.open(e);
  // }

  // openAssignationDateRangePicker(e) {
  //   this.pickerAssignationDirective.open(e);
  // }

  async downloadExcel() {
    this.addFilters();
    if (
      !this.filters.assignmentDateEnd && 
      !this.filters.creationDateEnd &&
      !this.filters.creationDateStart &&
      !this.filters.assignmentDateStart
    ) {
      await swal.fire({
        title: '¿Está seguro de esta descarga?',
        text: 'Recuerde que si no estableces filtros de fechas puedes traer muchos registros en esta acción...',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, realizar descarga!',
        cancelButtonText: 'No, quiero pensarlo mejor'
      }).then(async (result) => {
        if (result.value) {
          // await this.downloadExcel_();
          swal.fire('Excelente...', 'Hemos realizado la debida descarga, como lo indicaste!', 'success')
        }
      });
    } else {
      // await this.downloadExcel_();
    }
  }

  // async downloadExcel_() {
  //   this.spinner.show();
  //   const tempFilters = {
  //     ...this.filters,
  //     page: this.filters.page > 0 ? this.filters.page - 1 : 0
  //   }
  //   delete tempFilters['loading']
  //   await this.ticketService.downloadTickets(tempFilters);
  //   this.spinner.hide();
  // }
}
