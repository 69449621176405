import { guid, StoreConfig, Store, EntityState, EntityStore } from '@datorama/akita';
import pageSettings from '../../config/page-settings';
import { Injectable } from '@angular/core';
import { BaseEntity, BaseEntityDetail } from 'src/app/interfaces/user.interface';

export interface TicketAnswerAttachment {
  id: number;
  messageExtraRequestId: number;
  name: string;
  state: string;
  url: string;
}

// Call answer of ticket. Preuba subida 2
export interface TicketAnswerMessage  {
  id: number;
  extraRequestTicketId: number;
  typeResponseTicketExtraId: number;
  titlePronouncement: string;
  message: string;
  state: string;
  createDate: string;
  attachmentsExtraDTO: TicketAnswerAttachment[];
  userName: string;
  userRole: string;
  typeResponseTicketExtraName: string;
}

export interface TicketAnswerMessageState extends EntityState<TicketAnswerMessage, number> {
  id: number;
  extraRequestTicketId: number;
  typeResponseTicketExtraId: number;
  message: string;
  state: string;
  createDate: string;
  attachmentsExtraDTO: TicketAnswerAttachment[];
  userName: string;
  userRole: string;
}

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'TicketAnswerMessage' })
export class TicketAnswerMessageStore extends EntityStore<TicketAnswerMessageState> {
  constructor() {
    super();
    this.set({1: null}, {activeId: 1});
  }
}
