<ol class="breadcrumb float-xl-right">
  <li class="breadcrumb-item"><a href="javascript:;">Inicio</a></li>
</ol>

<h1 class="page-header">Radicación Manual</h1>

<panel title="Solicitudes manuales" noButton="true">
  <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12" data-pageload-addclass="animated fadeIn">  
    <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12">
      <div class="card border-0 text-center">
        <div class="card-body">
          <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12">
            <div class="col-xl-12 col-lg-12">
              <div class="clearfix" style="text-align: left;">
                <h4 class="card-title text-dark" *ngIf="currentStep < 6">
                  <span class="badge badge-success" style="padding: 5px 15px 4px;margin-right: 10px;">Paso {{currentStep}}</span>
                  <span *ngIf="currentStep == 1">Información basica</span>
                  <span *ngIf="currentStep == 2">Información de la reclamación</span>
                  <span *ngIf="currentStep == 3">Acerca de la reclamación</span>
                  <span *ngIf="currentStep == 4">Soportes de la reclamación</span>
                </h4>
                <h4 class="card-title text-dark" *ngIf="currentStep == 5"> Hemos Terminado!</h4>
                <hr>
              </div>
            </div>
            <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12" *ngIf="currentStep == 1" >
              <div class="clearfix">
                <form [formGroup]="personalInfoForm" (ngSubmit)="onSendStep1()" class="margin-bottom-0">
                  <div class="row">
                    <div class="col-md-4 col-xl-4 col-xs-12 col-sm-12 col-lg-4">
                      <div class="form-group row m-b-15">
                        <label class="col-form-label">Seleccione Entidad</label>
                        <div class="col-md-12" *ngIf="entities$ | async as entities">
                          <ng-select
                            appearance="outline"
                            [searchable]="true"
                            formControlName="entity"
                            [items]="entities"
                            bindLabel="name"
                            [clearable]="false"
                            bindValue="id"
                            tabindex=”1”
                            >
                          </ng-select>
                        </div>
                      </div>
                    </div>
                    
                    <div class="col-md-4 col-xl-4 col-xs-12 col-sm-12 col-lg-4">
                      <div class="form-group row m-b-15">
                        <label class="col-form-label">Fecha de creación</label>
                        <div class="col-md-12">
                          <div class="input-group">
                            <input class="form-control" placeholder="aaaa-mm-dd" readonly
                                  name="dp" formControlName="creationDateRadicador" [minDate]="years_ago_init" [maxDate]="today_mark" ngbDatepicker #d="ngbDatepicker" [ngClass]="{
                                    'is-valid': !creationDateRadicador.invalid,
                                    'is-invalid': (creationDateRadicador.invalid)
                                  }">
                            <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
                                <i class="fa fa-calendar"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4 col-xl-4 col-xs-12 col-sm-12 col-lg-4">
                      <div class="form-group row m-b-15">
                        <label class="col-form-label">Medio de recepción</label>
                        <div class="col-md-12" *ngIf="recieveMedium$ | async as recieveMediums">
                          <ng-select
                            appearance="outline"
                            [searchable]="true"
                            formControlName="receivingMedium"
                            [items]="recieveMediums"
                            bindLabel="name"
                            [clearable]="false"
                            bindValue="id"
                            tabindex=”1”
                            >
                          </ng-select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="entity.value && creationDateRadicador.value && receivingMedium.value && stateTicket.value">
                    <hr>
                    <div class="row">
                      <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
                        <div class="form-group row m-b-15">
                          <label class="col-form-label">Número de documento</label>
                          <div class="col-md-12">
                            <input formControlName="serial" class="form-control m-b-5" placeholder="Ej: 1065240124" type="text" tabindex=”3” NumbersOnly [ngClass]="{
                              'is-valid': !serial.invalid,
                              'is-invalid': (serial.invalid)
                            }">
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
                        <div class="form-group row m-b-15">
                          <label class="col-form-label">Tipo de persona</label>
                          <div class="col-md-12" *ngIf="personalTypes$ | async as personalTypes">
                            <ng-select
                              appearance="outline"
                              [searchable]="false"
                              formControlName="typePerson"
                              [items]="personalTypes"
                              bindLabel="name"
                              [clearable]="false"
                              bindValue="id"
                              tabindex=”1”
                              >
                            </ng-select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
                        <div class="form-group row m-b-15" *ngIf="documentTypes$ | async as documentTypes">
                          <label class="col-form-label">Tipo de documento</label>
                          <div class="col-md-12">
                            <ng-select
                              appearance="outline"
                              [searchable]="false"
                              formControlName="typeDocumentId"
                              [items]="documentTypes"
                              bindLabel="name"
                              [clearable]="false"
                              bindValue="id"
                              tabindex=”2”
                              >
                            </ng-select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
                        <div class="form-group row m-b-15">
                          <label class="col-form-label" *ngIf="!isEnterpriseInformation()">Fecha de expedición</label>
                          <label class="col-form-label" *ngIf="isEnterpriseInformation()">Fecha de expedición RUT</label>
                          <div class="col-md-12">
                            <div class="input-group">
                              <input class="form-control" placeholder="aaaa-mm-dd" readonly
                                    name="dp" formControlName="documentExpeditionDate" [minDate]="years_ago_init" [maxDate]="today_mark" ngbDatepicker #d="ngbDatepicker" [ngClass]="{
                                      'is-valid': !documentExpeditionDate.invalid,
                                      'is-invalid': (documentExpeditionDate.invalid)
                                    }">
                              <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
                                  <i class="fa fa-calendar"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
                        <div class="form-group row m-b-15">
                          <label class="col-form-label" *ngIf="!isEnterpriseInformation()">Nombre completo</label>
                          <label class="col-form-label" *ngIf="isEnterpriseInformation()">Razón social</label>
                          <div class="col-md-12">
                            <input formControlName="name" class="form-control m-b-5" placeholder="Ej: Juan Perez / Empresa S.A.S" type="text" tabindex=”5” [ngClass]="{
                              'is-valid': !name.invalid,
                              'is-invalid': (name.invalid)
                            }">
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
                        <div class="form-group row m-b-15">
                          <label class="col-form-label">¿Condicion especial? <small>Deberás adjuntar el certificado.</small></label>
                          <div class="col-md-12" *ngIf="specialCondition$ | async as conditions">
                            <ng-select appearance="outline" [searchable]="true" formControlName="specialConditionId" [items]="conditions"
                              bindLabel="name" [clearable]="false" bindValue="id"  [virtualScroll]="true">
                            </ng-select>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
                        <div class="form-group row m-b-15">
                          <label class="col-form-label">Correo electrónico</label>
                          <div class="col-md-12">
                            <input formControlName="email" class="form-control m-b-5" placeholder="Ej: juan@gmail.com" type="email" tabindex=”6” [ngClass]="{
                              'is-valid': !email.invalid,
                              'is-invalid': (email.invalid)
                            }">
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
                        <div class="form-group row m-b-15">
                          <label class="col-form-label">Teléfono de contacto</label>
                          <div class="col-md-12">
                            <input formControlName="cellPhone" class="form-control m-b-5" placeholder="Ej: 3003003030" type="number" minlength="7" maxlength="10" tabindex=”8” [ngClass]="{
                              'is-valid': !cellPhone.invalid,
                              'is-invalid': (cellPhone.invalid)
                            }">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="!isEnterpriseInformation()" >
                      <hr>
                      <div class="row">
                        <div class="col-md-4 col-xl-4 col-xs-12 col-sm-12 col-lg-4">
                          <div class="form-group row">
                            <label class="col-form-label">Genero</label>
                            <div class="col-md-12" *ngIf="genders$ | async as genders">
                              <ng-select
                                appearance="outline"
                                [searchable]="false"
                                formControlName="genreTypeId"
                                [items]="genders"
                                bindLabel="name"
                                [clearable]="false"
                                bindValue="id"
                                tabindex=”10”
                                >
                              </ng-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 col-xl-4 col-xs-12 col-sm-12 col-lg-4">
                          <div class="form-group row m-b-15">
                            <label class="col-form-label">Eres jubilado o pensionado?</label>
                            <div class="col-md-12">
                              <ng-select
                                appearance="outline"
                                [searchable]="false"
                                formControlName="retire"
                                [items]="jubilateOptions"
                                bindLabel="name"
                                [clearable]="false"
                                bindValue="id"
                                tabindex=”11”
                                >
                              </ng-select>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 col-xl-4 col-xs-12 col-sm-12 col-lg-4">
                          <div class="form-group row m-b-15">
                            <label class="col-form-label">Nivel Educativo</label>
                            <div class="col-md-12" *ngIf="educationLevels$ | async as educationLevels">
                              <ng-select
                                appearance="outline"
                                [searchable]="false"
                                formControlName="academicLevelId"
                                [items]="educationLevels"
                                bindLabel="name"
                                [clearable]="false"
                                bindValue="id"
                                tabindex=”10”
                                >
                              </ng-select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12">
                    <hr>
                    <div class="row">
                      <button type="submit" tabindex=”12” class="btn btn-success btn-block btn-lg pull-right" [disabled]="!personalInfoForm.valid">Siguiente Paso</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
  
            <div class="col-xl-12 col-lg-12" *ngIf="currentStep == 2" >
              <div class="clearfix">
                <form [formGroup]="complaintInfoForm" (ngSubmit)="onSendStep2()" class="margin-bottom-0">
                  <div class="row">
                    <div class="col-md-4 col-xl-4 col-xs-12 col-sm-12 col-lg-4">
                      <div class="form-group row m-b-15">
                        <label class="col-form-label">Tipo de solicitud</label>
                        <div class="col-md-12" *ngIf="typeTickets$ | async as typeTickets">
                          <ng-select appearance="outline" [searchable]="false" formControlName="typeTicket"
                            [items]="typeTickets" bindLabel="name" [clearable]="false" bindValue="id" tabindex=”1”>
                          </ng-select>
                        </div>
                      </div>
                    </div>   

                    <div class="col-md-4 col-xl-4 col-xs-12 col-sm-12 col-lg-4">
                      <div class="form-group row m-b-15">
                        <label class="col-form-label">Canal</label>
                        <div class="col-md-12" *ngIf="channel$ | async as channels">
                          <ng-select appearance="outline" [searchable]="false" formControlName="channelId"
                            [items]="channels" bindLabel="name" [clearable]="false" bindValue="id" tabindex=”1”>
                          </ng-select>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4 col-xl-4 col-xs-12 col-sm-12 col-lg-4">
                      <div class="form-group row m-b-15">
                        <label class="col-form-label">¿Es un producto digital?</label>
                        <div class="col-md-12">
                          <ng-select appearance="outline" [searchable]="false" formControlName="digital"
                            [items]="digitalOptions" bindLabel="name" [clearable]="false" bindValue="id" >
                          </ng-select>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
                      <div class="form-group row m-b-15">
                        <label class="col-form-label">Producto</label>
                        <div class="col-md-12" *ngIf="products$ | async as products">
                          <ng-select appearance="outline" [searchable]="true" formControlName="product"
                            [items]="products" bindLabel="name" [clearable]="false" bindValue="id" tabindex=”1”>
                          </ng-select>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
                      <div class="form-group row m-b-15">
                        <label class="col-form-label">Formato</label>
                        <div class="col-md-12" *ngIf="typeFormats$ | async as typeFormats">
                          <ng-select
                            appearance="outline"
                            [searchable]="true"
                            formControlName="typeFormat378"
                            [items]="typeFormats"
                            bindLabel="name"
                            [clearable]="false"
                            bindValue="id"
                            tabindex=”1”
                            >
                          </ng-select>
                        </div>
                      </div>
                    </div>
                    
                    <div class="col-md-6 col-xl-6 col-xs-6 col-sm-6 col-lg-6">
                      <div class="form-group row m-b-15">
                        <label class="col-form-label">¿Cuando ocurrió tu incidente?</label>
                        <div class="col-md-12">
                          <div class="input-group">
                            <input class="form-control" placeholder="aaaa-mm-dd" readonly name="dp"
                              formControlName="eventDate" [minDate]="years_ago_init" [maxDate]="today_mark" ngbDatepicker
                              #d="ngbDatepicker" [ngClass]="{
                                    'is-valid': !eventDate.invalid,
                                    'is-invalid': (eventDate.invalid)
                                  }">
                            <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
                                <i class="fa fa-calendar"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
                      <div class="form-group row m-b-15">
                        <label class="col-form-label col-md-12 text-left">¿Donde ocurrió tu incidente?</label>
                        <div class="col-md-4">
                          <ng-select appearance="outline" [searchable]="true" formControlName="country" [items]="countryOptions"
                            bindLabel="name" [clearable]="false" bindValue="id" [virtualScroll]="true" (change)="selectionCountryChanged($event)">
                          </ng-select>
                        </div>
                        <div class="col-md-8" *ngIf="cities$ | async as cities">
                          <ng-select appearance="outline" [searchable]="true" formControlName="city" [items]="cities"
                            bindLabel="name" [clearable]="false" bindValue="id" [virtualScroll]="true">
                          </ng-select>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6 col-xl-6 col-xs-6 col-sm-6 col-lg-6">
                      <div class="form-group row m-b-15">
                        <label class="col-form-label">Tipo de reclamación</label>
                        <div class="col-md-12" *ngIf="complaintTypes$ | async as complaintTypes">
                          <ng-select appearance="outline" [searchable]="false" formControlName="typeComplaint"
                            [items]="complaintTypes" bindLabel="name" [clearable]="false" bindValue="id" tabindex=”2”>
                          </ng-select>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6 col-xl-6 col-xs-6 col-sm-6 col-lg-6">
                      <div class="form-group row m-b-15">
                        <label class="col-form-label">Valor de la reclamación</label>
                        <div class="col-md-12">
                          <input formControlName="cost" class="form-control m-b-5" placeholder="Ej: 122000" type="number"
                            tabindex=”4” NumbersOnly [ngClass]="{
                            'is-valid': !cost.invalid,
                            'is-invalid': (cost.invalid)
                          }">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <hr>
                    <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12">
                      <div class="form-group">
                        <button type="button" (click)="prevStep()"  tabindex=”12” class="btn btn-default  pull-left">Volver al paso 1</button>
                        <button type="submit" tabindex=”12” class="btn btn-success  pull-right" [disabled]="!complaintInfoForm.valid || !personalInfoForm.valid">Siguiente Paso</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
  
            <div class="col-xl-12 col-lg-12" *ngIf="currentStep == 3" >
              <form [formGroup]="factsInfoForm" (ngSubmit)="onSendStep3()" class="margin-bottom-0">
                <div class="row">
                  <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12">
                    <div class="form-group row m-b-15">
                      <label class="col-form-label col-md-12 text-left">Asunto de la reclamación <small
                          class="f-s-12 text-grey-darker">(Breve resumen del incidente)</small></label>
                      <div class="col-md-12">
                        <input formControlName="subject" class="form-control m-b-5"
                          placeholder="Ej: Mi tarjeta me ha cobrado mas de lo debido" type="text" tabindex=”4” [ngClass]="{
                          'is-valid': !subject.invalid,
                          'is-invalid': (subject.invalid)
                        }">
                      </div>
                    </div>
                  </div>
  
                  <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12">
                    <div class="form-group row m-b-15 text-left">
                      <label class="col-md-12 col-form-label">Cuentanos lo que ocurrió <small class="f-s-12 text-grey-darker">(Min. 20
                          caracteres / Max. 1000 caracteres)</small></label>
                      <div class="col-md-12">
                        <textarea class="form-control" rows="6"
                          placeholder="Indícanos detalladamente qué fue lo que sucedio con la entidad"
                          formControlName="acts" [ngClass]="{
                          'is-valid': !acts.invalid,
                          'is-invalid': (acts.invalid)
                          }">
                        </textarea>
                      </div>
                    </div>
                  </div>
  
                  <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12">
                    <div class="form-group row m-b-15 text-left">
                      <label class="col-md-12 col-form-label">¿Como te gustaría que te ayudáramos? <small
                          class="f-s-12 text-grey-darker">(Min. 20 caracteres / Max. 1000 caracteres)</small></label>
                      <div class="col-md-12">
                        <textarea class="form-control" rows="6"
                          placeholder="¿Qué te gustaría que se hiciera al respecto o como podríamos ayudarte?"
                          formControlName="request" [ngClass]="{
                          'is-valid': !request.invalid,
                          'is-invalid': (request.invalid)
                          }">
                        </textarea>
                      </div>
                    </div>
                  </div>
                </div>
                  
                <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12">
                  <hr>
                  <div class="form-group">
                    <button type="button" (click)="prevStep()"  tabindex=”12” class="btn btn-default  pull-left">Volver al paso 2</button>
                    <button type="submit" tabindex=”12” class="btn btn-success  pull-right" [disabled]="!complaintInfoForm.valid || !personalInfoForm.valid || !factsInfoForm.valid">Siguiente Paso</button>
                  </div>
                </div>
              </form>
            </div>
  
            <div class="col-xl-12 col-lg-12" *ngIf="currentStep == 4" >
              <label class="col-md-12">¿Tienes archivos que agregar?... <small class="f-s-12 text-grey-darker">Si cuentas con imagenes, archivos o videos que puedan ayudar a tener mayor claridad de tu solicitud, este es el momento de incluirlas</small></label>
              <form [formGroup]="requestInfoForm" (ngSubmit)="onSendStep4()" class="margin-bottom-0">
  
                <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12">
                  <div class="dnd-container" appDnd (fileDropped)="onFileDropped($event)">
                    <input type="file" #fileDropRef id="fileDropRef" multiple accept=".pdf,.jpg,.jpeg,.png,.mp4,.doc,.docx,.xls,.xlsx,.bmp,.mp3,.msg"
                      (change)="fileBrowseHandler($event.target.files)" />
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 100 100">
                      <g fill="#3B454F" fill-rule="nonzero">
                        <path
                          d="M42.656 15.135a1.953 1.953 0 0 1-1.391-.578L31.5 4.795l-9.765 9.762a1.97 1.97 0 1 1-2.785-2.785L30.106.616a1.97 1.97 0 0 1 2.785 0l11.157 11.156a1.97 1.97 0 0 1-1.392 3.363z" />
                        <path
                          d="M31.5 36.791a1.97 1.97 0 0 1-1.969-1.969V2.01a1.97 1.97 0 0 1 3.938 0v32.812a1.97 1.97 0 0 1-1.969 1.969z" />
                        <path
                          d="M55.781 63.041H7.22A7.225 7.225 0 0 1 0 55.822V41.385a4.599 4.599 0 0 1 4.594-4.594h7.234a4.567 4.567 0 0 1 4.402 3.276l2.814 9.382a.658.658 0 0 0 .628.467h23.656a.658.658 0 0 0 .628-.467l2.814-9.385a4.572 4.572 0 0 1 4.402-3.273h7.234A4.599 4.599 0 0 1 63 41.385v14.437a7.225 7.225 0 0 1-7.219 7.219zM4.594 40.729a.656.656 0 0 0-.657.656v14.437a3.286 3.286 0 0 0 3.282 3.282H55.78a3.286 3.286 0 0 0 3.282-3.282V41.385a.656.656 0 0 0-.657-.656h-7.234a.65.65 0 0 0-.628.467L47.73 50.58a4.628 4.628 0 0 1-4.402 3.274H19.672a4.567 4.567 0 0 1-4.402-3.276l-2.814-9.382a.65.65 0 0 0-.628-.467H4.594z" />
                      </g>
                    </svg>
  
                    <h3>Arrastra los archivos hasta aquí</h3>
                    <h3>O también puedes</h3>
                    <button class="btn btn-info" for="fileDropRef" >Abrir y cargar archivos</button>
                  </div>
                  <div class="dnd-files-list">
                    <div class="single-file" *ngFor="let file of files_; let i = index">
                      <div class="file-icon" style="width: 50px">
                        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                          x="0px" y="0px" viewBox="0 0 58 58" style="enable-background:new 0 0 58 58;" xml:space="preserve">
                          <polygon style="fill:#EDEADA;" points="51.5,14 37.5,0 6.5,0 6.5,58 51.5,58 " />
                          <g>
                            <path style="fill:#CEC9AE;"
                              d="M16.5,23h25c0.552,0,1-0.447,1-1s-0.448-1-1-1h-25c-0.552,0-1,0.447-1,1S15.948,23,16.5,23z" />
                            <path style="fill:#CEC9AE;"
                              d="M16.5,15h10c0.552,0,1-0.447,1-1s-0.448-1-1-1h-10c-0.552,0-1,0.447-1,1S15.948,15,16.5,15z" />
                            <path style="fill:#CEC9AE;"
                              d="M41.5,29h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,29,41.5,29z" />
                            <path style="fill:#CEC9AE;"
                              d="M41.5,37h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,37,41.5,37z" />
                            <path style="fill:#CEC9AE;"
                              d="M41.5,45h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,45,41.5,45z" />
                          </g>
                          <polygon style="fill:#CEC9AE;" points="37.5,0 37.5,14 51.5,14 " />
                        </svg>
                      </div>
                      <div class="info">
                        <h4 class="name">
                          {{ file.name }}
                        </h4>
                        <p class="size">
                          {{ formatBytes(file?.size) }}
                        </p>
                      </div>
  
                      <div class="delete" (click)="deleteFile(i)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18">
                          <path fill="#B1B1B1" fill-rule="nonzero"
                            d="M1 16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v10zm3.17-7.83a.996.996 0 0 1 1.41 0L7 9.59l1.42-1.42a.996.996 0 1 1 1.41 1.41L8.41 11l1.42 1.42a.996.996 0 1 1-1.41 1.41L7 12.41l-1.42 1.42a.996.996 0 1 1-1.41-1.41L5.59 11 4.17 9.58a.996.996 0 0 1 0-1.41zM10.5 1L9.79.29C9.61.11 9.35 0 9.09 0H4.91c-.26 0-.52.11-.7.29L3.5 1H1c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1h-2.5z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
  
                <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12">
                  <div class="form-group">
                    <button type="button" (click)="prevStep()"  tabindex=”12” class="btn btn-default  pull-left">Volver al paso 3</button>
                    <button type="submit" tabindex=”12” [disabled]="sendingForm"  class="btn btn-success  pull-right">Radicar solicitud</button>
                  </div>
                </div>
              </form>
            </div>
  
            <div class="col-xl-12 col-lg-12" *ngIf="currentStep == 5" >
              <div class="panel">
                <div class="note-content">
                  <i class="fas fa-check-circle fa-5x"></i>
                  <br/>   
                  <br/>   
                  <p>Solicitud radicada exitosamente bajo el ticket <b>Nº {{ticketConsecutive}}</b></p>
                  
                  <hr>               
                  <button (click)="sendToEntity()" [disabled]="alreadySendToEntity" class="btn btn-laurado-purple text-white m-r-10">Enviar a la entidad</button>                  
                  <button (click)="resetAll()" class="btn btn-laurado-purple text-white">Radicar una nueva solicitud</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</panel>