<div class="login-cover">
    <div class="login-cover-image" style="background-image: url(../assets/img/cover/home-cover.jpg)"
        data-id="login-cover-image"></div>
    <div class="login-cover-bg-purple"></div>
</div>

<div class="container login-cover">
    <div class="row" style="position: relative; top: 50%; transform: translateY(-50%);">
        <div class="col-xs-4"></div>
        <div class="col-xs-4">
            <div class="text-center">
                <img src="https://defensorialg.com.co/wp-content/uploads/2020/03/logodcflg-3.png" style="width: 60%;">
            </div>  
            <br>
            <br>
            <br>
            <div class="card border-0 text-center">
                <div class="card-body">
                    <div class="clearfix">
                        <i class="fa fa-unlock-alt m-b-15" style="font-size: 60px;" aria-hidden="true"></i>
                        <h4 class="card-title text-dark m-b-20">Cambiar contraseña</h4>                        
                        <form  class="margin-bottom-0">
                            <div class="form-group m-b-15">
                                <input class="form-control" type="password"
                                    placeholder="Crear nueva contraseña">
                            </div>
                            <div class="form-group m-b-15">
                                <input  class="form-control" type="password"
                                    placeholder="Confirmar nueva contraseña">
                            </div>
                            <button type="submit" class="btn btn-success btn-block btn-lg"
                                >Actualizar</button>
                        </form>
                    </div>
                </div>
            </div>
            <div class="text-center text-white">
                <br>
                <h3><small>Si tienes problemas para actualizar tu contraseña, por favor contacta con soporte técnico</small></h3>
            </div>                      
        </div>        
    </div>
    <br />
    <br />
</div>