import { AuthService } from 'src/app/services/auth.service';
import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { map, shareReplay, tap, filter, startWith, switchMap, flatMap } from 'rxjs/operators';
import { routes } from 'src/app/config/routes';
import { Role, RoleState, RoleStore } from './role.store';
import { Observable } from 'rxjs-compat';

@Injectable({
  providedIn: 'root'
})
export class RoleQuery extends QueryEntity<RoleState> {
  constructor(protected store: RoleStore) {
    super(store);
  }

  active$ = () => this.selectRole().pipe(shareReplay(1));

  getSnapshot = () => this.getValue();

  isLoading$ = () => this.selectLoading();

  Role$ = () => this.selectRole().pipe(shareReplay(1));
  
  selectRole() {
    return this.selectAll().pipe(
      map(it => it.filter(x => x != null))
    )
  }

  setLoading(isLoading) {
    this.store.setLoading(isLoading);
  }


  setState(data, activeId) {
    this.store.set(data, activeId);
  }

}
