import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../states/user';

@Pipe({
  name: 'fullname'
})
export class FullNamePipe implements PipeTransform {

  transform(user: User): String {
    if (user.lastname) return user.name + " " + user.lastname;
    else return user.name
  }

}
