<div class="error">
  <div class="error-code m-b-10">
    404
  </div>
  <div class="error-content">
    <div class="error-message">Lo sentimos...</div>
    <div class="error-desc m-b-30"> Parece que no hemos podido encontrar lo que buscabas. <br> Sin embargo disponemos de las siguientes ayudas. </div>
    <div><a class="btn btn-warning p-l-20 p-r-20" [routerLink]="['/home']" routerLinkActive="active">Si deseas realizar una queja por favor haz click aquí</a></div>
    <div><a class="btn btn-info p-l-20 p-r-20 m-t-20" [routerLink]="['/dashboard']" routerLinkActive="active">Si eres funcionario por favor haz click aquí</a></div>
  </div>
</div>