import { guid, StoreConfig, Store, EntityState, EntityStore } from '@datorama/akita';
import pageSettings from '../../config/page-settings';
import { Injectable } from '@angular/core';
import { BaseEntity, BaseEntityDetail, TicketUser_ } from 'src/app/interfaces/user.interface';

export interface Ticket {
  acts: string;
  assignmentDate: Date;
  city: number;
  consecutive: string;
  cost: number;
  creationDate: Date;
  entity: number;
  entityName: string;
  productName: string;
  eventDate: Date;
  id: number;
  maxDays: number;
  minDays: number;
  note: string;
  product: number;
  receivingMedium: number;
  receivingMediumName: string;
  request: string;
  stateTicket: {detalle: string, id: number, state: string, type: string} | number;
  stateTicketName: string;
  subject: string;
  typeComplaint: number;
  typeComplaintName: string;
  typeFormat378: number;
  typeTicket: number;
  typeTicketName: string;
  userName: string;
  counterDays: number;
  extesionMaxDate?: string;
  maxDate?: string;
  moanfulSerial?: string;
  typeFormat378Name?: string;
  haveExtraRequest?: string;
  apremioDate?:Date;
  voceriaEntityDate?: Date;
  extensionDate?: Date;
  inconsistencyMarkDate?: Date;
}

export interface TicketState extends EntityState<Ticket, number> {
  acts: string;
  assignmentDate: Date;
  city: number;
  consecutive: string;
  cost: number;
  creationDate: Date;
  entity: number;
  entityName: string;
  eventDate: Date;
  id: number;
  maxDays: number;
  minDays: number;
  note: string;
  product: number;
  receivingMedium: number;
  receivingMediumName: string;
  request: string;
  stateTicket: number;
  stateTicketName: string;
  subject: string;
  typeComplaint: number;
  typeComplaintName: string;
  typeFormat378: number;
  typeTicket: number;
  typeTicketName: string;
  userName: string;
  counterDays: number;
  extesionMaxDate?: string;
  maxDate?: string;
  moanfulSerial?: string;
  typeFormat378Name?: string;
  rejectionTypeName?: string;
}

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'Ticket' })
export class TicketStore extends EntityStore<TicketState> {
  constructor() {
    super();
  }
}
