<div class="vertical-box with-grid inbox bg-light" *ngIf="detailData$ | async as detailData">
  <div class="vertical-box-column width-200 height-500">

    <div class="vertical-box">

      <div class="wrapper">
        <div class="d-flex align-items-center justify-content-center">
          <ng-container *ngIf="(!roleDetail.isEntity && isValidForAction(detailData.stateTicket.id)) || (roleDetail.isEntity && detailData.stateTicket.id == 4)">
            <a href="javascript:;" class="btn btn-inverse p-l-40 p-r-40 btn-sm" (click)="open('answer')" *ngIf="roleDetail.isRadicator || roleDetail.isEntity">
              Redactar.
            </a>
          </ng-container>
        </div>
      </div>
      <div class="vertical-box-row collapse d-lg-table-row" id="emailNav">

        <div class="vertical-box-cell">

          <div class="vertical-box-inner-cell">

            <div class="slimScrollDiv" style="position: relative; overflow: hidden; width: auto; height: 100%;">
              <div data-scrollbar="true" data-height="100%" data-init="true"
                style="overflow: hidden; width: auto; height: 100%;">

                <div class="wrapper p-0">
                  <div class="nav-title"><b>Tipos de Respuesta</b></div>
                  <ul class="nav nav-inbox">
                    <li [className]=" subTab == 'all' ? 'active' : '' " (click)="changeSubTab('all')"><a href="javascript:;"><i class="fa fa-inbox fa-fw m-r-5"></i> Todos
                      <span class="badge pull-right">{{answerCount$ | async}}</span></a></li>
                    <li [className]=" subTab == 'request_info' ? 'active' : '' " (click)="changeSubTab('request_info')"><a href="javascript:;"><i class="fa fa-inbox fa-fw m-r-5"></i> sol. Información
                      <span class="badge pull-right">{{requestinfoCount$ | async}}</span></a></li>
                    <li [className]=" subTab == 'pronouncement' ? 'active' : '' "><a href="javascript:;" (click)="changeSubTab('pronouncement')"><i class="fa fa-envelope fa-fw m-r-5"></i> Pronunciamientos
                      <span class="badge pull-right">{{pronouncementCount$ | async}}</span></a></li>
                    <ng-container *ngIf="!roleDetail.isMoanful">
                      <li [className]=" subTab == 'attonery_answer' ? 'active' : '' " (click)="changeSubTab('attonery_answer')"><a href="javascript:;"><i class="fa fa-flag fa-fw m-r-5"></i> Apremios
                        <span class="badge pull-right">{{attoneryAnswerCount$ | async}}</span></a></li>
                      <li [className]=" subTab == 'spokesperson' ? 'active' : '' "><a href="javascript:;" (click)="changeSubTab('spokesperson')"><i class="fa fa-envelope fa-fw m-r-5"></i> Vocerías
                        <span class="badge pull-right">{{spokespersonCount$ | async}}</span></a></li>
                    </ng-container>
                  </ul>
                </div>

              </div>
              <div class="slimScrollBar"
                style="background: rgb(0, 0, 0); width: 7px; position: absolute; top: 0px; opacity: 0.4; display: none; border-radius: 7px; z-index: 99; right: 1px; height: 829px;">
              </div>
              <div class="slimScrollRail"
                style="width: 7px; height: 100%; position: absolute; top: 0px; display: none; border-radius: 7px; background: rgb(51, 51, 51); opacity: 0.2; z-index: 90; right: 1px;">
              </div>
            </div>

          </div>

        </div>

      </div>

    </div>

  </div>
  <div class="vertical-box-column">
    <div class="vertical-box">
      <div class="wrapper">
        <div class="btn-toolbar align-items-center">
          <button class="btn btn-sm btn-white mr-2" *ngIf="selectedMessage != null" (click)="selectMessage(null)"><i class="fa fa-arrow-left"></i> Volver</button>
          <button class="btn btn-sm btn-white mr-2" *ngIf="selectedMessage != null" (click)="getAllData()" ><i class="fa fa-sync"></i> Actualizar</button>
          <button class="btn btn-sm btn-black mr-2" *ngIf="isForDeletion() | async" (click)="hideAnswer()" ><i class="fa fa-trash"></i> Eliminar</button>
          
          <button class="btn btn-sm btn-white mr-2" *ngIf="selectedMessage == null" (click)="fetchData()" ><i class="fa fa-sync"></i> Actualizar</button>

          <div class="btn-group ml-auto">
            <button class="btn btn-white btn-sm" [disabled]="(answerCount$ | async) == 1">
              <i class="fa fa-fw fa-chevron-left"></i>
            </button>
            <button class="btn btn-white btn-sm" [disabled]="(answerCount$ | async) == currPage">
              <i class="fa fa-fw fa-chevron-right"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="vertical-box-row">
        <div class="vertical-box-cell">
          <div class="vertical-box-inner-cell bg-white">
            <div class="slimScrollDiv" style="position: relative; overflow: hidden; width: auto; height: 100%;">
              <div data-scrollbar="true" data-height="100%" data-init="true"
                style="overflow: scroll; width: auto; height: 100%;">
                <div *ngIf="selectedMessage">
                  <div class="wrapper" *ngIf="selectedAnswer$ | async as selectedAnswer">
                    <h3 class="m-t-0 m-b-15 f-w-500">[ Tema de conversación: {{ selectedAnswer.subject }} ]</h3>
                  </div>
                  <div class="wrapper" *ngFor="let ticketAnswerMessage of ticketAnswerMessage$ | async">
                    <ul class="media-list underline m-b-15 p-b-15">
                      <li class="media media-sm clearfix">
                        <div class="media-body">
                          <div class="email-from text-inverse f-s-14 m-b-3 f-w-600">
                            Enviado por: {{ ticketAnswerMessage.userName }}
                          </div>
                          <div class="m-b-3"><i class="fa fa-clock fa-fw"></i> {{ getFormatedDateString(ticketAnswerMessage.createDate).date }} {{ getFormatedDateString(ticketAnswerMessage.createDate).hour }}</div>
                          <div class="email-to">
                            <b>Rol de usuario:</b> {{ ticketAnswerMessage.userRole }}
                          </div>
                          <div class="email-to" *ngIf="ticketAnswerMessage.typeResponseTicketExtraName">
                            <b>Tipo de pronunciamiento:</b> {{ ticketAnswerMessage.typeResponseTicketExtraName }}
                          </div>
                        </div>
                      </li>
                    </ul>
                    <p class="text-inverse" [innerHTML]="'<b>Contenido del Mensaje: </b>' + ticketAnswerMessage.message"></p>
                    <ul class="attached-document clearfix">
                      <li [class]="getIconObject(attachmentsExtra.url).icon" *ngFor="let attachmentsExtra of ticketAnswerMessage.attachmentsExtraDTO">
                        <div class="document-file">
                          <a [href]="attachmentsExtra.url" target="_blank" [innerHtml]="getIconObject(attachmentsExtra.url).content"></a>
                        </div>
                        <div class="document-name">
                          <a [href]="attachmentsExtra.url" target="_blank">{{attachmentsExtra.name}}</a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                
                <ul class="list-group list-group-lg no-radius list-email" *ngIf="!selectedMessage">
                  <li class="list-group-item unread" *ngFor="let item of ticketAnswers$ | async">
                    <a href="javascript:;" class="email-user bg-blue" (click)="selectMessage(item.id)">
                      <span class="text-white">{{item.typeExtraRequest.name.charAt(0)}}</span>
                    </a>
                    <div class="email-info">
                      <a href="javascript:;" (click)="selectMessage(item.id)">
                        <span class="email-sender">{{item.typeExtraRequest.name}}</span>
                        <span class="email-title">{{item.subject}}</span>
                        <span class="email-desc">{{item.stateExtra.detalle}}</span>
                        <span class="email-time">{{item.historyDate | date: 'dd-MM-yyyy'}}</span>                        
                      </a>
                    </div>
                  </li>
                </ul>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="wrapper clearfix d-flex align-items-center" *ngIf="selectedMessage != null && (selectedAnswer$ | async )">
        <div class="btn-group ml-auto width-full" *ngIf="(!isAnswer && !roleDetail.isApprover && !(isForApproval() | async))">
          <button type="button" class="btn btn-inverse" (click)="setForm()">
            <i class="fa fa-fs fa-reply"></i> Quisiera responder
          </button>
        </div>
        <div class="btn-group ml-auto width-full" *ngIf="(!isAnswer && roleDetail.isEntity && isPronoucementForApproval() | async)">
          <button type="button" class="btn btn-laurado-purple" (click)="approvePronouncement(true)">
            <i class="fa fa-fs fa-check"></i> Acoger pronunciamiento
          </button>
          <button type="button" class="btn btn-inverse" (click)="approvePronouncement(false)">
            <i class="fa fa-fs fa-times"></i> No acoger
          </button>
        </div>
        <div class="btn-group ml-auto width-full" *ngIf="!isAnswer && !isApproval && roleDetail.isApprover && (isForApproval() | async)">
          <button type="button" class="btn btn-success" (click)="approveAnswer()">
            <i class="fa fa-fs fa-thumbs-up"></i> Aprobar
          </button>
          <button type="button" class="btn btn-inverse" (click)="setForm(true, 'is_for_comment')">
            <i class="fa fa-fs fa-reply"></i> Agregar comentario
          </button>
        </div>
        <form [formGroup]="answerMessageThreadForm" (ngSubmit)="sendMessageThreadAnswer();" class="col-md-12 margin-bottom-0 clearfix bg-white" *ngIf="isAnswer" style="padding: 0;">
          <div class="form-group row m-b-15" *ngIf="selectedAnswer$ | async as selectedAnswer">  
            <div class="col-md-12 m-b-10 m-t-10 p-r-30">
              <div style="display: flex;">             
                <div style="padding: 5px 15px; margin-left: auto;">
                  <b>Marcar como inconsistencia</b>
                </div>  
                <div class="switcher">                    
                  <input type="checkbox" name="switcher_checkbox_1" id="switcher_checkbox_1" *ngIf="isInconsistency" checked (change)="onFilterCheckBox($event)">
                  <input type="checkbox" name="switcher_checkbox_1" id="switcher_checkbox_1" *ngIf="!isInconsistency" (change)="onFilterCheckBox($event)">
                  <label for="switcher_checkbox_1"></label>
                </div> 
              </div>     
            </div>          
            <div class="col-md-12 m-b-10">
              <quill-editor [styles]="editorStyle" formControlName="message" placeholder="Ingrese texto aquí"></quill-editor>
            </div>
            <div class="col-md-12">
              <div class="form-group row m-b-15 width-full b-l-10 col-md-12" *ngIf="(roleDetail.isApprover || roleDetail.isLawyer) && selectedAnswer.typeExtraRequest.name == 'Pronunciamiento'">
                <label class="col-form-label col-md-12">Seleccione una opción</label>
                <div class="col-md-12" *ngIf="benefits$ | async as benefits">
                  <ng-select
                    appearance="outline"
                    [searchable]="false"
                    formControlName="typeResponseTicketExtraId"
                    [items]="benefits"
                    bindLabel="name"
                    [clearable]="false"
                    bindValue="id"
                    tabindex=”10”
                  >
                  </ng-select>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row m-b-15" *ngIf="reason == null">
              <div class="col-md-12">
                <div class="dnd-container-sm" appDnd (fileDropped)="onFileDropped($event)">
                  <input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target.files)" />
                  <button type="button" class="btn btn-info" for="fileDropRef" >Abrir y cargar archivos</button>
                </div>
                <div class="dnd-files-list">
                  <div class="single-file" *ngFor="let file of files_; let i = index">
                    <div class="info">
                      <h4 class="name">
                        {{ file.name }}
                      </h4>
                    </div>
                    <div class="delete" (click)="deleteFile(i)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="14" viewBox="0 0 14 18">
                        <path fill="#B1B1B1" fill-rule="nonzero"
                          d="M1 16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v10zm3.17-7.83a.996.996 0 0 1 1.41 0L7 9.59l1.42-1.42a.996.996 0 1 1 1.41 1.41L8.41 11l1.42 1.42a.996.996 0 1 1-1.41 1.41L7 12.41l-1.42 1.42a.996.996 0 1 1-1.41-1.41L5.59 11 4.17 9.58a.996.996 0 0 1 0-1.41zM10.5 1L9.79.29C9.61.11 9.35 0 9.09 0H4.91c-.26 0-.52.11-.7.29L3.5 1H1c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1h-2.5z" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
          </div>          
          <div class="btn-group ml-auto width-full">
            <button type="button" class="btn btn-white btn-sm" (click)="setForm(false, null)">
              <i class="fa fa-fs fa-times"></i> Cancelar
            </button>
            <button type="submit" class="btn btn-info" [disabled]="isValidAnswer$(!answerMessageThreadForm.valid) | async">
              <i class="fa fa-fs fa-reply"></i> Responder
            </button>
          </div>
        </form>
        <div *ngIf="isApproval && !isAnswer && roleDetail.isApprover" class="margin-bottom-0 clearfix bg-white width-full">
          <div class="form-group row m-b-15 width-full b-l-10 col-md-12">
            <label class="col-form-label col-md-12">Seleccione una opción</label>
            <div class="col-md-12" *ngIf="benefits$ | async as benefits">
              <ng-select
                appearance="outline"
                [searchable]="false"
                [(ngModel)]="answerBenefit"
                [items]="benefits"
                bindLabel="name"
                [clearable]="false"
                bindValue="id"
                tabindex=”10”
              >
              </ng-select>
            </div>
          </div>
          <div class="btn-group ml-auto width-full">
            <button type="button" class="btn btn-white btn-sm" (click)="setForm(false, null)">
              <i class="fa fa-fs fa-times"></i> Cancelar
            </button>
            <button type="submit" class="btn btn-success" [disabled]="answerBenefit == null" (click)="sendApproveAnswer()">
              <i class="fa fa-fs fa-thumbs-up"></i> Aprobar
            </button>
          </div>
        </div>
        <div *ngIf="roleDetail.isApprover && isAbleForNotifyApproval() | async" class="btn-group ml-auto width-full">
          <button type="button" class="btn btn-laurado-purple" [disabled]="wasSendMailNotification" (click)="onlyNotifyPronouncement()">
            <i class="fa fa-fs fa-share"></i> Notificar pronunciamiento
          </button>
        </div>
      </div>
      <div class="wrapper clearfix d-flex align-items-center" *ngIf="selectedMessage == null">
        <div class="text-inverse f-w-600">{{answerCount$ | async}} Respuesta(s)</div>
        <div class="btn-group ml-auto">
          <button class="btn btn-white btn-sm" [disabled]="(answerCount$ | async) == 1">
            <i class="fa fa-fw fa-chevron-left"></i>
          </button>
          <button class="btn btn-white btn-sm" [disabled]="(answerCount$ | async) == currPage">
            <i class="fa fa-fw fa-chevron-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
