import { guid, StoreConfig, Store, EntityState, EntityStore } from '@datorama/akita';
import pageSettings from '../../config/page-settings';
import { Injectable } from '@angular/core';

export interface Department  {
  id: number;
  name: string;
  state: string;
  code: string;
  cities: City[];
}

export interface City  {
  id: number;
  code: string;
  name: string;
  state: string;
}


export interface DepartmentState extends EntityState<Department, number> {
  id: number;
  name: string;
  state: string;
  code: string;
  cities: City[];
}

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'Department' })
export class DepartmentStore extends EntityStore<DepartmentState> {
  constructor() {
    super();
    this.set({1: null}, {activeId: 1});
  }
}
