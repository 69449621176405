import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'update_password',
    templateUrl: './update_password.html',
    providers: []
})

export class UpdatePasswordPage {

}