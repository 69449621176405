import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface BaseEntity {
  id: number;
  name: string;
}

export interface ApiResponse<T> {
  success: boolean;
  code: number;
  data: T;
  error?: string[] | string;
}

export interface IPaginate<T> {
  data: T[];
  totalPage: number;
  totalRegister: number;
}

export interface PaginateApiResponse<T> {
  success: boolean;
  code: number;
  data: IPaginate<T>;
  error?: string[] | string;
}

export const NoValidatedRoutes = [
  'home',
  'login',
  'complaint',
  'not_found',
  'external/userMoanful/',
  'external/entity',
  'external/typePerson',
  'external/state',
  'external/genre',
  'external/academicLevel',
  'external/typeComplaint',
  'external/specialCondition',
  'external/product/',
];

export type responseType = 'arraybuffer' | 'blob' | 'json' | 'text';

@Injectable()
export class BaseService {
  public baseUrl = environment.apiUrl;

  constructor(public http: HttpClient) {}
  public async get<T>(url: string): Promise<ApiResponse<T>> {
    return await this.http.get<ApiResponse<T>>(url).toPromise();
  }

  public async getFileByBlob(url: string) {
    return await this.http.get(url, { responseType: 'blob', reportProgress: true, observe: 'events'}).toPromise();
  }

  public async paginatedGet<T>(url: string): Promise<PaginateApiResponse<T>> {
    return await this.http.get<PaginateApiResponse<T>>(url).toPromise();
  }

  public async post<T, S>(url: string, payload: S): Promise<ApiResponse<T>> {
     return await this.http.post<ApiResponse<T>>(url, payload).toPromise();                
  }

  public async commonPostAttachMultipart<T>(url: string, extraParams, files: any[]): Promise<ApiResponse<T>> {
    let formData = new FormData();
    const formKeys = Object.keys(extraParams);
    formKeys.forEach((x) => {
      formData.append(x, extraParams[x]);
    });
    Object.values(files).forEach((x) => {
      formData.append('files', x);
    });
    return await this.http.post<ApiResponse<T>>(url, formData, { 
      reportProgress: true,
      headers: {
        'Content-Type': undefined,
        'enctype': 'multipart/form-data',
        'Accept': 'application/json'
      }
     }).toPromise();
  }

  public async postAttachMultipart<T>(url: string, ticketId: number,files: any[]): Promise<ApiResponse<T>> {
    const formData = new FormData();
    formData.append('ticketId', `${ticketId}`);
    files.forEach((x) => {
      formData.append('files', x);
    });

    return await this.http.post<ApiResponse<T>>(url, formData, {
      reportProgress: true,
      headers: {
        'Content-Type': undefined,
        'enctype': 'multipart/form-data',
        'Accept': 'application/json'
      }
    }).toPromise();
  }

  public refreshPost<T, S>(url: string, payload: S): Observable<T> {
    return this.http.post<T>(url, payload);
  }

  public async put<T, S>(url: string, payload: S): Promise<ApiResponse<T>> {
    return await this.http.put<ApiResponse<T>>(url, payload).toPromise();
  }

  public async patch<T, S>(url: string, payload: S): Promise<ApiResponse<T>> {
    return await this.http.patch<ApiResponse<T>>(url, payload).toPromise();
  }

  public async delete<T, S>(url: string): Promise<ApiResponse<T>> {
    return await this.http.delete<ApiResponse<T>>(url).toPromise();
  }
}
