import { StoreConfig, EntityState, EntityStore } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { BaseEntity, BaseEntityDetail } from 'src/app/interfaces/user.interface';

export interface TicketAnswer {
  id: number;
  ticketId: number;
  typeExtraRequest: BaseEntity;
  stateExtra: BaseEntityDetail;
  subject: string;
}

export interface TicketAnswerState extends EntityState<TicketAnswer, number> {
  id: number;
  ticketId: number;
  typeExtraRequest: BaseEntity;
  stateExtra: BaseEntityDetail;
  subject: string;
}

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'TicketAnswer' })
export class TicketAnswerStore extends EntityStore<TicketAnswerState> {
  constructor() {
    super();
    // this.set({1: null}, {activeId: 1});
  }
}
