import { ComplaintDescriptionPage } from './pages/complaint_detail/complaint_description/complaint_description';
import { ComplaintUserInfoPage } from './pages/complaint_detail/complaint_user_info/complaint_user_info';
import { ComplaintPage } from './pages/complaint/complaint';
import { LoginPage } from './pages/login/login';
// Core Module
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, Title } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule, MatTableModule } from '@angular/material';

// Main Component
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SidebarRightComponent } from './components/sidebar-right/sidebar-right.component';
import { TopMenuComponent } from './components/top-menu/top-menu.component';
import { FooterComponent } from './components/footer/footer.component';
import { PanelComponent } from './components/panel/panel.component';
import { FloatSubMenuComponent } from './components/float-sub-menu/float-sub-menu.component';

// Component Module
import { AgmCoreModule } from '@agm/core';
import { FullCalendarModule } from '@fullcalendar/angular';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { TrendModule } from 'ngx-trend';
import { CountdownModule } from 'ngx-countdown';
import { ChartsModule } from 'ng4-charts/ng4-charts';
import { TagInputModule } from 'ngx-chips';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { Ng2TableModule } from 'ngx-datatable/ng2-table';
import { NvD3Module } from 'ng2-nvd3';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import 'd3';
import 'nvd3';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgSelectConfig } from '@ng-select/ng-select';
import { CKEditorModule } from 'ckeditor4-angular';
import { DndDirective } from './directives/dnd.directive';
import { CustomScrollDirective } from './directives/custom-scroll.directive';
import { RecaptchaModule, RecaptchaFormsModule, RECAPTCHA_SETTINGS, RecaptchaSettings, RECAPTCHA_V3_SITE_KEY  } from 'ng-recaptcha';
import { NgxSpinnerModule } from "ngx-spinner";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { akitaConfig } from '@datorama/akita';

akitaConfig({ resettable: true });

// Pages
import { HomePage } from './pages/home/home';
import { DashboardPage } from './pages/dashboard/dashboard';
import { AuthService } from './services/auth.service';
import { AuthGuardService } from './guards/auth.guard';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { HttpErrorInterceptor } from './interceptors/httperror.interceptor';
import { ComplaintDetailPage } from './pages/complaint_detail/complaint_detail';
import { ComplaintsPage } from './pages/complaints/complaints';
import { ComplaintGeneralPage } from './pages/complaint_detail/complaint_general/complaint_general';
import { ExternalService } from './services/external.service';
import { NotFoundPage } from './pages/not_found/not_found';
import { ComplaintAnswersPage } from './pages/complaint_detail/complaint_answers/complaint_answers';
import { ComplaintLogsPage } from './pages/complaint_detail/complaint_logs/complaint_logs';
import { TokenFactory } from './factories/token.factory';
import { CookieService } from 'ngx-cookie-service';
import { TicketService } from './services/ticket.service';
import { LoadingService } from './services/loading.service';

import { QuillModule } from 'ngx-quill'
import { NumberOnlyDirective } from './directives/numbers_only.directive';
import { ManualComplaintPage } from './pages/manual_complaint/manual_complaint';
import { UserProfilePage } from './pages/user_profile/user_profile';
import { UsersListPage } from './pages/users/list/users_list';
import { UserService } from './services/user.service';
import { UsersDetailPage } from './pages/users/detail/users_detail';
import { UpdatePasswordPage } from './pages/password/update_password';
import { ManualAssignmentPage } from './pages/manual_assignment/manual_assignment';
import { LetDirective } from './directives/let.directive';
import { VerificationPage } from './pages/login/verification/verification';
import { AuditService } from './services/audit.service';
import { AuditsPage } from './pages/audits/audits';
import { FullNamePipe } from './pipes/full-name.pipe';
import { AutoAssignmentPage } from './pages/auto_assignment/auto_assignment';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    SidebarRightComponent,
    TopMenuComponent,
    FooterComponent,
    PanelComponent,
    FloatSubMenuComponent,

    FullNamePipe,

    HomePage,
    LoginPage,
    DashboardPage,
    ComplaintPage,
    ManualComplaintPage,
    DndDirective,
    LetDirective,
    CustomScrollDirective,
    NumberOnlyDirective,
    ComplaintsPage,
    ComplaintDetailPage,
    ComplaintGeneralPage,
    ComplaintUserInfoPage,
    ComplaintAnswersPage,
    ComplaintLogsPage,
    ComplaintDescriptionPage,
    UserProfilePage,
    UsersListPage,
    UsersDetailPage,
    ManualAssignmentPage,
    UpdatePasswordPage,
    VerificationPage,
    AuditsPage,
    AutoAssignmentPage,
    NotFoundPage    
  ],
  imports: [
    AppRoutingModule,
    AgmCoreModule.forRoot({ apiKey: 'AIzaSyC5gJ5x8Yw7qP_DqvNq3IdZi2WUSiDjskk' }),
    BrowserAnimationsModule,
    BrowserModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    CountdownModule,
    ChartsModule,
    FullCalendarModule,
    FormsModule,
    LoadingBarRouterModule,
    MatSortModule,
    MatTableModule,
    NgbModule,
    NvD3Module,
    NgxChartsModule,
    Ng2TableModule,
    NgxDaterangepickerMd.forRoot(),
    PerfectScrollbarModule,
    ReactiveFormsModule,
    SweetAlert2Module.forRoot(),
    TagInputModule,
    TrendModule,
    AkitaNgDevtools.forRoot(),
    HttpClientModule,
    CKEditorModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
          provide: JWT_OPTIONS,
          useClass: TokenFactory
      }
    }),
    NgSelectModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    NgxSpinnerModule,
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
          ['blockquote', 'code-block'],
       
          [{ 'header': 1 }, { 'header': 2 }],               // custom button values
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
          [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
          [{ 'direction': 'rtl' }],                         // text direction
       
          [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
       
          [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
          [{ 'font': [] }],
          [{ 'align': [] }],
       
          ['clean'],                                         // remove formatting button
       
        ]
      }
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [ Title, {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }, 
    AuthGuardService,
    NgSelectConfig,
    ExternalService,
    AuthService,
    CookieService,
    TicketService,
    AuditService,
    UserService,
    LoadingService,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
      } as RecaptchaSettings,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
  ],
  bootstrap: [ AppComponent ]
})


export class AppModule {
  constructor(private router: Router, private titleService: Title, private route: ActivatedRoute) {
    router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        const title = 'Defensoría LG | ' + this.route.snapshot.firstChild.data.title;
        this.titleService.setTitle(title);
      }
    });
  }
}
