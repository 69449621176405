<div class="row">
  <div class="col-md-12 col-xs-12 col-sm-12">
    <panel variant="warning" title="Información del solicitante" noButton="true" bodyClass="border-warning">
      <table class="table table-striped" *ngIf="ticketUserData$ | async as ticketUserData">
        <tbody>
          <tr>
            <td>Nombre completo</td>
            <td>{{ticketUserData.user | fullname}}</td>
          </tr>
          <tr>
            <td>Número de documento</td>
            <td>{{ticketUserData.user.serial}}</td>
          </tr>
          <tr>
            <td>Tipo de documento</td>
            <td>{{ticketUserData.user.typeDocumentName}}</td>
          </tr>
          <tr>
            <td>Telefono de contacto</td>
            <td>{{ticketUserData.user.cellPhone || '-'}}</td>
          </tr>
          <tr>
            <td>Correo electrónico</td>
            <td>{{ticketUserData.user.email}}</td>
          </tr>
          <tr>
            <td>Condicion Especial</td>
            <td>{{ticketUserData.specialConditionName}}</td>
          </tr>
        </tbody>
      </table>
    </panel>
  </div>
</div>