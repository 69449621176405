import { guid, StoreConfig, Store, EntityState, EntityStore } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Role_ } from 'src/app/interfaces/user.interface';

export interface Lawyer  {
  id: number;
  typeDocument: number;
  genreType: number;
  rolId: number;
  rol: Role_;
  serial: number;
  name: string;
  lastname: string;
  fullName: string;
  birthdate: string;
  email: string;
  state: string;
  phone: 1;
  cellPhone: 1;
  typeDocumentName: string;
}

export interface LawyerState extends EntityState<Lawyer, number> {
  id: number;
  typeDocument: number;
  genreType: number;
  rolId: number;
  rol: Role_;
  serial: number;
  name: string;
  lastname: string;
  birthdate: string;
  email: string;
  state: string;
  phone: 1;
  cellPhone: 1;
  typeDocumentName: string;
}

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'Lawyer' })
export class LawyerStore extends EntityStore<LawyerState> {
  constructor() {
    super();
    this.set({1: null}, {activeId: 1});
  }
}
