export interface IColumnHeaders {
    title: string;
    name: string;
    sort?: boolean;
}

export const BaseTableColumnHeaders: Array<IColumnHeaders> = [
    {
        title: 'Fecha',
        name: 'creationDate',
        sort: false,
    },
    {
        title: 'Usuario',
        name: 'userEmail',
    },
    {
        title: 'IP',
        name: 'userIP',
    },
    {
        title: 'Acción',
        name: 'action',
        sort: false,
    },
    {
        title: 'Registro afectado',
        name: 'target',
        sort: false,
    }
];

export const PaginationConfig = () => {
    return {
        baseConfig: {
            paging: true,
            sorting: { columns: BaseTableColumnHeaders },
            className: ['table-striped', 'table-bordered', 'm-b-0']
        },
        maxSize: 3,
        numPages: 1
    }
}

export function getActionDescription(action: String) {
    switch (action) {
        case 'LOGIN':
            return 'Inicio de sesión';
        case 'VERIFICATION':
            return 'Verificación';
        case 'QUERY_OVER_TICKET':
            return 'Consulta solicitud';
        case 'USER_UPDATE':
            return 'Actualización';
        case 'USER_ENABLED':
            return 'Activación';
        case 'USER_DISABLED':
            return 'Inactivación ';
        default:
            return '-';
    }
}