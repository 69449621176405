import { guid, StoreConfig, Store, EntityState, EntityStore } from '@datorama/akita';
import pageSettings from '../../config/page-settings';
import { Injectable } from '@angular/core';
import { BaseEntity, BaseEntityDetail } from 'src/app/interfaces/user.interface';

export interface TicketLog {
  id: number;
  userId: number;
  user: {
      id: number;
      typeDocument: number;
      genreType: number;
      rolId: number;
      rol: {
          id: number;
          name: string;
          state: string;
          type: string;
      };
      serial: number;
      name: string;
      email: string;
      state: string;
      phone: number;
      cellPhone: number,
      typeDocumentName: string;
  };
  stateTicket: {
      id: number;
      detalle: string;
      state: string;
      type: string;
  },
  ticketId: 176,
  date: string;
  ticketConsecutive: string;
}

export interface TicketLogState extends EntityState<TicketLog, number> {
  id: number;
  userId: number;
  user: {
      id: number;
      typeDocument: number;
      genreType: number;
      rolId: number;
      rol: {
          id: number;
          name: string;
          state: string;
          type: string;
      };
      serial: number;
      name: string;
      email: string;
      state: string;
      phone: number;
      cellPhone: number,
      typeDocumentName: string;
  };
  stateTicket: {
      id: number;
      detalle: string;
      state: string;
      type: string;
  },
  ticketId: 176,
  date: string;
  ticketConsecutive: string;
}

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'TicketLog' })
export class TicketLogStore extends EntityStore<TicketLogState> {
  constructor() {
    super();
    this.set({1: null}, {activeId: 1});
  }
}
