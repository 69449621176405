<div *ngIf="detailData$ | async as detailData_">
  <!-- begin breadcrumb -->
  <ol class="breadcrumb float-xl-left">
    <li class="breadcrumb-item"><a [routerLink]="['/complaints']">Lista de Quejas</a></li>
    <li class="breadcrumb-item">Detalle de solicitud</li>
  </ol>
  <div class="clearfix"></div>
  <!-- end breadcrumb -->
  <!-- begin page-header -->
  <h1 class="page-header">Detalle de solicitud: {{detailData_.consecutive}}</h1>
  <!-- end page-header -->

  <!-- begin panel -->
  <panel title="" noButton="true">
    <ng-container beforeBody *ngIf="!isValidForAction(detailData_.stateTicket)">
      <div [class]="getNoticeStatus(detailData_.counterDays, detailData_.assignmentDate).style">
        <i [class]="getNoticeStatus(detailData_.counterDays, detailData_.assignmentDate).icon"></i>
        <p class="m-b-0">
          El estado actual de la solicitud es <b> {{detailData_.stateTicketName}} </b> (Actualmente solo está disponible
          para su visibilidad)
        </p>
      </div>
    </ng-container>
    <div class="col-md-12">
      <div class="btn-group pull-left">
        <a [routerLink]="['/complaints']" class="btn btn-white">
          <i class="fa fa-arrow-left"></i><br />
          Atrás
        </a>
        <ng-container *ngIf="!isValidForAction(detailData_.stateTicket)">
          <a class="btn btn-white" *ngIf="(roleDetail.isAdmin || roleDetail.isRadicatorAdmin || roleDetail.isEntity)"
          href="javascript:;"
            (click)="open(answerAssignModalDialog, '')">
            <i class="fas fa-code-branch"></i><br />
            Cambio de producto
          </a>
        </ng-container>
        <ng-container *ngIf="isValidForAction(detailData_.stateTicket)">
          <a class="btn btn-white" *ngIf="roleDetail.isEntityAdmin && detailData_.stateTicket == 4" href="javascript:;"
            (click)="open(answerModalDialog, 'answer')">
            <i class="fas fa-reply"></i><br />
            Responder
          </a>
          <a class="btn btn-white"
            *ngIf="(roleDetail.isEntity || roleDetail.isRadicatorAdmin) && ([1, 4, 10, 15].includes(detailData_.stateTicket) || roleDetail.isAdmin)"
            (click)="open(answerAssignModalDialog, '')">
            <ng-container *ngIf="roleDetail.isRadicator">
              <i class="fas fa-code-branch"></i><br />
              Cambio de producto
            </ng-container>
            <ng-container *ngIf="roleDetail.isEntityAdmin">
              <i class="fas fa-code-branch"></i><br />
              Cambio de producto
            </ng-container>
          </a>
          <a class="btn btn-white" *ngIf="roleDetail.isEntityAdmin && detailData_.stateTicket == 4" href="javascript:;"
            (click)="open(answerModalDialog, 'askInfo')">
            <i class="fas fa-question-circle"></i><br />
            Solicitar Información
          </a>
          <a class="btn btn-white"
            *ngIf="roleDetail.isEntityAdmin && !detailData_.extesionMaxDate && detailData_.stateTicket == 4"
            href="javascript:;" (click)="open(answerModalDialog, 'extend')">
            <i class="fas fa-calendar-plus"></i><br />
            Solicitar Prórroga
          </a>
          <a class="btn btn-white"
            *ngIf="roleDetail.isLawyer && [10,15].includes(detailData_.stateTicket) && (validatePronouncementExists() | async)"
            href="javascript:;" (click)="open(answerModalDialog, 'pronouncement')">
            <i class="fas fa-bullhorn"></i><br />
            Emitir Pronunciamiento
          </a>
        </ng-container>
      </div>

      <div class="btn-group pull-right">
        <ng-container>
          <a class="btn btn-white"
            *ngIf="(roleDetail.isRadicatorAdmin && detailData_.stateTicket == 1 && isValidForAction(detailData_.stateTicket))
                      || (roleDetail.isAdmin && ![3,19].includes(detailData_.stateTicket))"
            (click)="open(answerRejectModalDialog, '')">
            <i class="fas fa-trash"></i><br />
            Rechazar
          </a>
          <a class="btn btn-white"
            *ngIf="roleDetail.isLawyer && detailData_.stateTicket == 10 && isValidForAction(detailData_.stateTicket)"
            (click)="open(answerModalDialog, 'attorney_answer')">
            <i class="fas fa-comments"></i><br />
            Apremio
          </a>
          <a class="btn btn-white" *ngIf="roleDetail.isLawyer" (click)="open(answerModalDialog, 'spokesperson')">
            <i class="fas fa-comment-alt"></i><br />
            Vocería
          </a>
          <a class="btn btn-white"
            *ngIf="roleDetail.isRadicatorAdmin && haveResponseFromEntity(detailData_.stateTicket) && isValidForAction(detailData_.stateTicket)"
            (click)="open(assignToLawyerDialog, '')">
            <i class="fas fa-user-plus"></i><br />
            Asignar a Abogado
          </a>
          <a class="btn btn-white"
            *ngIf="roleDetail.isRadicatorAdmin && detailData_.stateTicket == 1 && (isEditableTypeTicket$() | async) && isValidForAction(detailData_.stateTicket)"
            (click)="open(answerChangeTypeModalDialog, '')">
            <i class="fas fa-pencil-alt"></i><br />
            Editar tipo de solicitud
          </a>
        </ng-container>
      </div>
    </div>
    <div class="clearfix"><br /><br /></div>
    <div class="col-md-12">
      <!-- begin nav-tabs -->
      <ngb-tabset [activeId]="activeIdString" (tabChange)="beforeChangeTab($event)" *ngIf="detailData_ != null">
        <ngb-tab id="general">
          <ng-template ngbTabTitle>
            <span class="d-sm-none">Gral.</span>
            <span class="d-sm-block d-none">General</span>
          </ng-template>
          <ng-template ngbTabContent>
            <complaint_general></complaint_general>
          </ng-template>
        </ngb-tab>
        <ngb-tab id="requestor">
          <ng-template ngbTabTitle>
            <span class="d-sm-none">Info... Sol...</span>
            <span class="d-sm-block d-none">Información del Solicitante</span>
          </ng-template>
          <ng-template ngbTabContent>
            <complaint_user_info></complaint_user_info>
          </ng-template>
        </ngb-tab>
        <ngb-tab id="description">
          <ng-template ngbTabTitle>
            <span class="d-sm-none">Desc..</span>
            <span class="d-sm-block d-none">Descripción.</span>
          </ng-template>
          <ng-template ngbTabContent>
            <complaint_description></complaint_description>
          </ng-template>
        </ngb-tab>
        <ngb-tab id="answers">
          <ng-template ngbTabTitle>
            <span class="d-sm-none">Resp...</span>
            <span class="d-sm-block d-none">Respuestas.</span>
          </ng-template>
          <ng-template ngbTabContent>
            <complaint_answers (openAnswerModal)="open(answerModalDialog, $event)"></complaint_answers>
          </ng-template>
        </ngb-tab>
        <ngb-tab id="logs" *ngIf="!roleDetail.isMoanful">
          <ng-template ngbTabTitle>
            <span class="d-sm-none">Logs.</span>
            <span class="d-sm-block d-none">Logs.</span>
          </ng-template>
          <ng-template ngbTabContent>
            <complaint_logs></complaint_logs>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>
    </div>

  </panel>
  <!-- end panel -->
</div>

<ng-template #answerModalDialog let-c="close" let-d="dismiss">
  <form [formGroup]="askForInformationform" (ngSubmit)="submitAskForInformation(c);" class="margin-bottom-0">
    <div class="modal-header">
      <h4 class="modal-title" *ngIf="answerFormType == 'askInfo'">Requerir más información y/o documentos <small>Danos
          más información al respecto</small></h4>
      <h4 class="modal-title" *ngIf="answerFormType == 'answer'">Generar respuesta para este ticket <small>Danos más
          información al respecto</small></h4>
      <h4 class="modal-title" *ngIf="answerFormType == 'extend'">Solicitar más tiempo <small>Prórroga automática de ocho
          (8) días hábiles.</small></h4>
      <h4 class="modal-title" *ngIf="answerFormType == 'spokesperson'">Generar una Vocería <small>Danos más información
          al respecto</small></h4>
      <h4 class="modal-title" *ngIf="answerFormType == 'attorney_answer'">Generar un Apremio <small>Danos más
          información al respecto</small></h4>
      <h4 class="modal-title" *ngIf="answerFormType == 'pronouncement'">Emitir un Pronunciamiento <small>Danos más
          información al respecto</small></h4>

      <button type="button" class="close" (click)="c('Cross click')">×</button>
    </div>
    <div class="modal-body">
      <div class="form-group row m-b-15">
        <div class="col-md-12">
          <quill-editor [styles]="editorStyle" formControlName="message" placeholder="Ingrese texto aquí">
          </quill-editor>
        </div>
      </div>
      <div *ngIf="answerFormType == 'pronouncement'" class="margin-bottom-0 clearfix bg-white width-full">
        <!-- Espacio para titulo del Pronunciamiento -->
        <div class="form-group row m-b-15 width-full b-l-10 col-md-12">
          <label class="col-form-label col-md-12">Ingrese el título del pronunciamiento.</label>
          <input class="form-control" type="text" formControlName="titlePronouncement" placeholder="Digite el título aquí.">
        </div>
        <div class="form-group row m-b-15 width-full b-l-10 col-md-12">
          <label class="col-form-label col-md-12">Seleccione a favor de quien se realiza el pronunciamiento</label>
          <div class="col-md-12" *ngIf="benefits$ | async as benefits">
            <ng-select appearance="outline" [searchable]="false" formControlName="typeResponseTicketExtraId"
              [items]="benefits" bindLabel="name" [clearable]="false" bindValue="id" tabindex=”10”>
            </ng-select>
          </div>
        </div>
      </div>
      <div class="form-group row m-b-15">
        <label class="col-form-label col-md-3">Adjuntar archivos y/o guías</label>
        <div class="col-md-12">
          <div class="dnd-container-sm" appDnd (fileDropped)="onFileDropped($event)">
            <input type="file" #fileDropRef id="fileDropRef" multiple
              accept=".pdf,.jpg,.jpeg,.png,.mp4,.doc,.docx,.xls,.xlsx,.bmp,.mp3,.msg"
              (change)="fileBrowseHandler($event.target.files)" />
            <button type="button" class="btn btn-info" for="fileDropRef">Abrir y cargar archivos</button>
          </div>
          <div class="dnd-files-list">
            <div class="single-file" *ngFor="let file of files_; let i = index">
              <div class="info">
                <h4 class="name">
                  {{ file.name }}
                </h4>
              </div>
              <div class="delete" (click)="deleteFile(i)">
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="14" viewBox="0 0 14 18">
                  <path fill="#B1B1B1" fill-rule="nonzero"
                    d="M1 16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v10zm3.17-7.83a.996.996 0 0 1 1.41 0L7 9.59l1.42-1.42a.996.996 0 1 1 1.41 1.41L8.41 11l1.42 1.42a.996.996 0 1 1-1.41 1.41L7 12.41l-1.42 1.42a.996.996 0 1 1-1.41-1.41L5.59 11 4.17 9.58a.996.996 0 0 1 0-1.41zM10.5 1L9.79.29C9.61.11 9.35 0 9.09 0H4.91c-.26 0-.52.11-.7.29L3.5 1H1c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1h-2.5z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-white" type="button"
        (click)="askForInformationform.reset(); c('Close click')">Cerrar</button>
      <button class="btn btn-success" type="submit" [disabled]="askInformationIsValid(!askForInformationform.valid)"><i
          class="fas fa-reply"></i> Guardar y Enviar</button>
    </div>
  </form>
</ng-template>

<ng-template #answerRejectModalDialog let-c="close" let-d="dismiss">
  <form [formGroup]="rejectionForm" (ngSubmit)="submitRejection(c)" class="margin-bottom-0">
    <div class="modal-header">
      <h4 class="modal-title">Rechazar y devolver solicitud <small class="f-s-12"></small></h4>
      <button type="button" class="close" (click)="c('Cross click')">×</button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="form-group col-sm-6">
          <h6>Causal de rechazo</h6>
          <div *ngIf="rejectionTypes$ | async as rejectionTypes">
            <ng-select appearance="outline" [searchable]="false" formControlName="rejectionTypeId"
              [items]="rejectionTypes" bindLabel="name" [clearable]="false" bindValue="id" tabindex=”10”>
            </ng-select>
          </div>
        </div>
        <div class="form-group col-sm-6" [hidden]="isHidden$ | async">
          <h6>Consecutivo del ticket de replica</h6>
          <input class="form-control" formControlName="replicaConsecutive" />
        </div>
        <div class="form-group col-sm-12">
          <h6>Descripción o motivo del rechazo</h6>
          <quill-editor [styles]="editorStyle" formControlName="note" placeholder="Ingrese texto aquí"></quill-editor>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-white" type="button" (click)="c('Close click')">Cerrar</button>
      <button class="btn btn-danger" type="submit" [disabled]="!rejectionForm.valid"><i class="fas fa-reply"></i>
        Guardar y Enviar</button>
    </div>
  </form>
</ng-template>

<ng-template #answerAssignModalDialog let-c="close" let-d="dismiss">
  <form [formGroup]="assignationForm" (ngSubmit)="submitAssignation(c)" class="margin-bottom-0">
    <div class="modal-header">
      <h4 class="modal-title">Actualizar la información de la solicitud</h4>
      <button type="button" class="close" (click)="c('Cross click')">×</button>
    </div>
    <div class="modal-body" *ngIf="detailData$ | async as detailData_">
      <div class="form-group row m-b-15" *ngIf="!roleDetail.isEntity && isRadicatedState(detailData_.stateTicket)">
        <label class="col-form-label col-md-3"><b>Seleccione la entidad</b></label>
        <div class="col-md-9" *ngIf="entities$ | async as entities">
          <ng-select appearance="outline" [searchable]="true" formControlName="entityId" [items]="entities"
            bindLabel="name" [clearable]="false" bindValue="id" tabindex=”10”>
          </ng-select>
        </div>
      </div>
      <div class="form-group row m-b-15">
        <div class="col-md-12" *ngIf="products$ | async as products">
          <label class="col-form-label"><b>Seleccione el producto</b></label>
          <ng-select appearance="outline" [searchable]="true" formControlName="productId" [items]="products"
            bindLabel="name" [clearable]="true" bindValue="id" tabindex=”10”>
          </ng-select>
          <small class="form-text text-muted"><b>Actual: </b>{{detailData_.productName}}</small>
        </div>
      </div>
      <div class="form-group row m-b-15">
        <div class="col-md-12" *ngIf="typeFormats$ | async as typeFormats">
          <label class="col-form-label"><b>Seleccione el motivo</b></label>
          <ng-select appearance="outline" [searchable]="true" formControlName="typeFormat378Id" [items]="typeFormats"
            bindLabel="name" [clearable]="true" bindValue="id" tabindex=”10”>
          </ng-select>
          <small class="form-text text-muted"><b>Actual: </b>{{detailData_.typeFormat378Name}}</small>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-white" type="button" (click)="c('Close click')">Cerrar</button>
      <button class="btn btn-warning" type="submit" [disabled]="validateAssignationForm(!assignationForm.valid)"><i
          class="fas fa-check-circle"></i> Confirmar</button>
    </div>
  </form>
</ng-template>

<ng-template #answerChangeTypeModalDialog let-c="close" let-d="dismiss">
  <form [formGroup]="changeTypeForm" (ngSubmit)="submitChangeType(c)" class="margin-bottom-0">
    <div class="modal-header">
      <h4 class="modal-title">Parece que quieres cambiar el tipo de solicitud <small class="f-s-12">Explícanos la
          causa...</small></h4>
      <button type="button" class="close" (click)="c('Cross click')">×</button>
    </div>
    <div class="modal-body">
      <div class="form-group row m-b-15">
        <label class="col-form-label col-md-3">Seleccione el nuevo tipo de solicitud</label>
        <div class="col-md-9" *ngIf="typeTicket$ | async as typeTicket">
          <ng-select appearance="outline" [searchable]="false" formControlName="typeTicketId" [items]="typeTicket"
            bindLabel="name" [clearable]="false" bindValue="id" tabindex=”10”>
          </ng-select>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-white" type="button" (click)="c('Close click')">Cerrar</button>
      <button class="btn btn-info" type="submit" [disabled]="!changeTypeForm.valid"><i class="fas fa-reply"></i> Guardar
        y Enviar</button>
    </div>
  </form>
</ng-template>

<ng-template #assignToLawyerDialog let-c="close" let-d="dismiss">
  <form [formGroup]="assignTicketForm" (ngSubmit)="submitAssignToLawyer(c)" class="margin-bottom-0">
    <div class="modal-header">
      <h4 class="modal-title">Reasigna este ticket a alguien de manera personalizada </h4>
      <button type="button" class="close" (click)="c('Cross click')">×</button>
    </div>
    <div class="modal-body">
      <div class="form-group row m-b-15">
        <label class="col-form-label col-md-3">Seleccione el nuevo abogado asignado</label>
        <div class="col-md-9" *ngIf="lawyer$ | async as lawyer">
          <ng-select appearance="outline" [searchable]="false" formControlName="assignedId" [items]="lawyer"
            bindLabel="fullname" [clearable]="false" bindValue="id" tabindex=”10”>
          </ng-select>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-white" type="button" (click)="c('Close click')">Cerrar</button>
      <button class="btn btn-info" type="submit"><i class="fas fa-user"></i> Asignar</button>
    </div>
  </form>
</ng-template>