import { AuthService } from 'src/app/services/auth.service';
import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { map, shareReplay, tap, filter, startWith, switchMap } from 'rxjs/operators';
import { routes } from 'src/app/config/routes';
import { TypePersonState, TypePersonStore } from './type_person.store';
import { Observable } from 'rxjs-compat';
import { Utils } from 'src/app/common/utils';

@Injectable({
  providedIn: 'root'
})
export class TypePersonQuery extends QueryEntity<TypePersonState> {
  constructor(protected store: TypePersonStore) {
    super(store);
  }

  active$ = () => this.selectTypePerson().pipe(shareReplay(1));

  getSnapshot = () => this.getValue();

  isLoading$ = () => this.selectLoading();

  typePerson$ = () => this.selectTypePerson().pipe(shareReplay(1), map(it => it.filter(x => x != null)));
  
  selectTypePerson() {
    return this.selectAll();
  }

  selectDocument(tpId: number | null) {
    return this.selectAll().pipe(
      startWith([]),
      map(it => {
        if(tpId == null) { return [] }
        const res = it.find(it2 => it2.id == tpId);
        return (res ? res.typeDocument : [])
     }
    ))
  }

  getAllDocumentTypes(){
    return this.selectAll().pipe(
      map(it => Utils.flatArray(it.map(it2 => it2.typeDocument)))
    )
  } 

  getTypePersonByDocumentType(documentType) {
    return this.selectAll().pipe(switchMap(it => Observable.of(it.find(it2 => it2.typeDocument.find(it3 => it3.id === documentType)))))
  }

  setLoading(isLoading) {
    this.store.setLoading(isLoading);
  }


  setState(data, activeId) {
    this.store.set(data, activeId);
  }

}
