import { AuthService } from 'src/app/services/auth.service';
import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { map, shareReplay, tap, filter, startWith, switchMap, flatMap } from 'rxjs/operators';
import { routes } from 'src/app/config/routes';
import { RejectionTypeState, RejectionTypeStore } from './rejection_type.store';
import { Observable } from 'rxjs-compat';
import { BaseQuery } from '../base/base.query';

@Injectable({
  providedIn: 'root'
})
export class RejectionTypeQuery extends BaseQuery<RejectionTypeState> {
  constructor(protected store: RejectionTypeStore) {
    super(store);
  }

  active$ = () => this.selectRejectionType().pipe(shareReplay(1));

  getSnapshot = () => this.getValue();

  isLoading$ = () => this.selectLoading();

  RejectionType$ = () => this.selectRejectionType().pipe(shareReplay(1));
  
  selectRejectionType() {
    return this.selectAll().pipe(
      map(it => it.filter(x => x != null))
    )
  }

}
