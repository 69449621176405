import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TicketService } from 'src/app/services/ticket.service';
import { TicketUserQuery } from 'src/app/states/ticket_user';

@Component({
  selector: 'complaint_user_info',
  templateUrl: './complaint_user_info.html'
})

export class ComplaintUserInfoPage implements OnInit {
  public ticketUserData$ = this.ticketUserQuery.ticketUser$();

  constructor(
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private ticketService: TicketService,
    private ticketUserQuery: TicketUserQuery,
    
  ) {

  }

  public async ngOnInit() {
    await this.fetchData();
  }

  public async fetchData() {
    this.spinner.show();
    const params = this.route.snapshot.params;
    await this.ticketService.getMoanfulByTicket(params['id'])
    await this.spinner.hide();
  }

}
