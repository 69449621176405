import { BaseEntity, BaseEntityDetail } from './user.interface';

export const YES = 'YES';
export const NO = 'NO';
export const DefaultTicketState = 1;
export const DefaultSpecialCondition = 185;
export const DefaultGenreType = 25;
export const DefaultCountry = "Colombia";

export type YesNo = 'YES' | 'NO';

export interface TicketRequestPayload {
  entity: number
  typePerson: number
  typeDocumentId: number
  serial: number
  documentExpeditionDate: string
  creationDate?: string
  name: string
  email: string
  cellphone: number
  address: string
  city: number
  genreTypeId?: number
  academicLevelId?: number
  retire?: YesNo
  userId?: number
  product: number
  typeComplaint: number
  eventDate: string
  subject: string
  acts: string
  request: string
  note?: string
}

export interface TicketJsonResponse {
  consecutive?: string
  ticketID?: number
  id?: number
  tempToken?: string
}

export interface AttachedFilesRequestPayload {
  files: File[]
  ticketId: number
}

export interface AttachedFilesJsonResponse {
  id: number
  ticket: number
  state: string
  name: string
  url: string
}

export interface UserMoanfulDataJsonResponse {
  id: number
  typeTicket: number
  typeFormat378: number
  receivingMedium: number
  product: number
  entity: number
  city: number
  rejectionType: number
  typeComplaint: number
  minDays: number
  maxDays: number
  cost: number
  typeDocumentId: number
  userId: number
  genreTypeId: number
  academicLevelId: number
  typePerson: number
  proffesion: number
  name: string
  serial: number
  address: string
  cellPhone: number
  retire: YesNo
  specialConditionId: number
  documentExpeditionDate: string
  email: string
}

export type RejectionType = BaseEntity;
export type TypeFormat = BaseEntity;
export type TicketState = BaseEntityDetail;
export type RecieveMedium = BaseEntity;
export type TypeResponseApprovePronouncement = BaseEntity;
export type Role = BaseEntity;
export type Position = BaseEntity;
export type Profession = BaseEntity;

