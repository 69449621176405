import { guid, StoreConfig, Store, EntityState, EntityStore } from '@datorama/akita';
import pageSettings from '../../config/page-settings';
import { Injectable } from '@angular/core';

export interface Company  {
  id: number;
  serial: number;
  consecutiveComplain: number
  consecutiveConciliation: number
  name: string;
  logo: string;
  state: string
}


export interface CompanyState extends EntityState<Company, number> {
  id: number;
  serial: number;
  consecutiveComplain: number
  consecutiveConciliation: number
  name: string;
  logo: string;
  state: string
}

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'Company' })
export class CompanyStore extends EntityStore<CompanyState> {
  constructor() {
    super();
    this.set({1: null}, {activeId: 1});
  }
}
