import { Injectable } from '@angular/core';

@Injectable()
export class LoadingService {
  loading: boolean = true;
  constructor() {
  }

  async setLoading(loading: boolean) {
    this.loading = loading;
  }

  
}
