import { guid, StoreConfig, Store, EntityState, EntityStore } from '@datorama/akita';
import pageSettings from '../../config/page-settings';
import { Injectable } from '@angular/core';

export interface Profession  {
  id: number;
  name: string;
  state: string;
}

export interface ProfessionState extends EntityState<Profession, number> {
  id: number;
  name: string;
  state: string;
}

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'Profession' })
export class ProfessionStore extends EntityStore<ProfessionState> {
  constructor() {
    super();
    this.set({1: null}, {activeId: 1});
  }
}
