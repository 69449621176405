import { Router } from '@angular/router';
import { Component, Input, Output, EventEmitter, Renderer2, OnDestroy, OnInit } from '@angular/core';
import pageSettings from '../../config/page-settings';
import { PageSettingsQuery } from 'src/app/states/page_settings/page_settings.query';
import { Observable } from 'rxjs';
import { UserData } from 'src/app/states/page_settings/page_settings.store';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnDestroy, OnInit {

  userData$: Observable<UserData>;

  @Input() pageSidebarTwo;
  @Output() toggleSidebarRightCollapsed = new EventEmitter<boolean>();
  @Output() toggleMobileSidebar = new EventEmitter<boolean>();
  @Output() toggleMobileRightSidebar = new EventEmitter<boolean>();
  pageSettings = pageSettings;

  mobileSidebarToggle() {
    this.toggleMobileSidebar.emit(true);
  }
  mobileRightSidebarToggle() {
    this.toggleMobileRightSidebar.emit(true);
  }
  toggleSidebarRight() {
    this.toggleSidebarRightCollapsed.emit(true);
  }

  mobileTopMenuToggle() {
    this.pageSettings.pageMobileTopMenuToggled = !this.pageSettings.pageMobileTopMenuToggled;
  }

  mobileMegaMenuToggle() {
    this.pageSettings.pageMobileMegaMenuToggled = !this.pageSettings.pageMobileMegaMenuToggled;
  }

  isEnterpriseRole() {
    return this.authService.isEnterpriseRole();
  }

  ngOnDestroy() {
    this.pageSettings.pageMobileTopMenuToggled = false;
    this.pageSettings.pageMobileMegaMenuToggled = false;
  }

  logout() {
    const roleDetail = this.authService.getRoleDetail();
    const redirects = !roleDetail.isMoanful ? '/login' : '/login?role=moanful'
    this.authService.logout();
    location.href = redirects;
  }

  ngOnInit() {
    this.userData$ = this.pageSettingsQuery.userData$();
  }

  constructor(
    private renderer: Renderer2,
    private router: Router,
    private pageSettingsQuery: PageSettingsQuery,
    private authService: AuthService
    ) {

  }
}
