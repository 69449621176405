import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ToolbarService, LinkService, ImageService, HtmlEditorService } from "@syncfusion/ej2-angular-richtexteditor";
import { NgxSpinnerService } from 'ngx-spinner';
import { Filter } from "src/app/interfaces/ticket.interface";
import { IPaginate } from "src/app/services/base.service";
import { LoadingService } from "src/app/services/loading.service";
import { Audit } from "src/app/states/audit";
import { Location } from '@angular/common';
import { removeEmptyJsonFields } from "src/app/common/utils";
import { AuditService } from "src/app/services/audit.service";
import { PaginationConfig, getActionDescription } from "./data";
import { FormControl, FormGroup } from "@angular/forms";

@Component({
    selector: 'audit',
    templateUrl: './audits.html',
    providers: [ToolbarService, LinkService, ImageService, HtmlEditorService]
})

export class AuditsPage implements OnInit {

    public rows: Array<any> = [];
    public paginateData: IPaginate<Audit> = {
        data: [],
        totalPage: 0,
        totalRegister: 0
    };

    public filters: Filter = {
        page: null,
        loading: true
    };

    public config = PaginationConfig();
    public columns = this.config.baseConfig.sorting.columns;

    public filtersForm = new FormGroup({
        userEmail: new FormControl(null),
        consecutive: new FormControl(null),
        date: new FormControl(null)
    });

    public constructor(
        private loadingService: LoadingService,
        private spinner: NgxSpinnerService,
        private router: Router,
        private route: ActivatedRoute,
        private location: Location,
        private auditService: AuditService
    ) {
        this.spinner.show();
        this.loadingService.setLoading(true);
    }

    public getComponentLoading() {
        return !this.loadingService.loading;
    }

    async setLoading(loading) {
        if (loading) {
            this.spinner.show();
        } else {
            this.spinner.hide();
        }
        this.filters.loading = loading;
    }

    public async ngOnInit() {
        await this.setLoading(true)

        const it = this.route.snapshot.queryParams;
        this.setPage(it['page'] ? (parseInt(it['page'], 10)) : 1)
        this.addFilters();
        this.setFilters(it);

        await this.changePage(0, false, true)
    }

    setPage(page) {
        this.filters.page = page;
    }

    private addFilters() {
        const curForm = this.filtersForm.value;
        this.filters = Object.assign(this.filters, {
            ...this.filters,
            ...curForm
        })
    }

    setFilters(it) {
        this.filters = Object.assign(this.filters, {
            ...this.filters,
            ...it
        })
    }

    public async changePage(event, withRedirect: boolean = true, firstTime: boolean = false) {
        const it = this.route.snapshot.queryParams;
        if (firstTime) {
            this.loadingService.setLoading(true);
            this.setPage(it['page'] ? (parseInt(it['page'], 10)) : 1)
        } else {
            if (this.filters.loading && event == 1) {
                this.loadingService.setLoading(true);
                this.setPage(it['page'] ? (parseInt(it['page'], 10)) : 1)
            } else {
                this.setPage(event)
            }
        }
        await this.onChangeTable(withRedirect)
        this.loadingService.setLoading(false);
    }

    public async onChangeTable(withRedirect: boolean = true) {
        try {
            await this.setLoading(true)
            const resume = (await this.fetchData()).data;
            this.paginateData = {
                ...resume,
                data: this.fetchHtmlData(resume.data),
            }
            if (withRedirect) {
                this.goToComplaintsWithQueryParams()
            }
            await this.setLoading(false)
        } catch (e) {
            await this.setLoading(false)
        }
    }

    private goToComplaintsWithQueryParams() {
        const tmpFilters = removeEmptyJsonFields(this.filters);
        delete tmpFilters['loading']
        this.location.replaceState(this.router.createUrlTree(
            ['/audits'],
            { queryParams: tmpFilters }
        ).toString())
    }

    async fetchData() {
        const tempFilters = {
            ...this.filters,
            page: this.filters.page > 0 ? this.filters.page - 1 : 0
        }
        delete tempFilters['loading']

        return await this.auditService.listAuditEntries(tempFilters);
    }

    private fetchHtmlData(rows: Audit[] = this.paginateData.data) {
        return rows.map(x => {
            var target = (x.logTicketId === 0) ? x.logUserEmail : x.logConsecutive;

            return {
                ...x,
                target: target,
                userEmail: x.userEmail,
                userIP: x.userIP,
                action: x.action,
                creationDate: x.creationDate
            };
        });
    }

    public getFormatedRow(row, columnName: string) {
        if (typeof row[columnName] === 'undefined')
            return '-'

        if (columnName === 'action')
            return getActionDescription(row[columnName])

        if (columnName.includes('Date')) {
            if (row[columnName].includes('T')) {
                var date = row[columnName].split('T')[0]
                var time = row[columnName].split('T')[1]

                return date + " " + time
            }

            return row[columnName]
        }

        return row[columnName]
    }

    public async submitFilters() {
        this.addFilters();
        await this.onChangeTable(true)
    }
}