import { guid, StoreConfig, Store, EntityState, EntityStore } from '@datorama/akita';
import pageSettings from '../../config/page-settings';
import { Injectable } from '@angular/core';
import { BaseEntity, BaseEntityDetail } from 'src/app/interfaces/user.interface';

export interface TicketFile {
  id: number;
  name: string;
  state: string;
  ticket: number;
  url: string;
}

export interface TicketFileState extends EntityState<TicketFile, number> {
  id: number;
  name: string;
  state: string;
  ticket: number;
  url: string;
}

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'TicketFile' })
export class TicketFileStore extends EntityStore<TicketFileState> {
  constructor() {
    super();
  }
}
