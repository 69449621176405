<ol class="breadcrumb float-xl-right">
  <li class="breadcrumb-item"><a href="javascript:;">Inicio</a></li>
</ol>
<h1 class="page-header">Mi perfil</h1>
<hr />
<div class="row">

  <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
    <panel title="Información básica" noButton="true" variant="default" bodyClass="border-default">
      <div class="row" *ngIf="userData$ | async as userData">
        <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12">
          <div class="form-group row m-b-15">
            <label class="col-form-label col-md-12">Nombre completo</label>
            <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12">
              <input class="form-control" type="text" [value]="userData.fullName" disabled readonly>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
          <div class="form-group row m-b-15">
            <label class="col-form-label col-md-12">Tipo de documento</label>
            <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12">
              <input class="form-control" type="text" [value]="userData.docType" disabled readonly>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
          <div class="form-group row m-b-15">
            <label class="col-form-label col-md-12">Número de documnto</label>
            <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12">
              <input class="form-control" type="text" [value]="userData.docNumber" disabled readonly>
            </div>
          </div>
        </div>

        <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12">
          <div class="form-group row m-b-15">
            <label class="col-form-label col-md-12">Fecha de Nacimiento</label>
            <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12">
              <input class="form-control" type="text" [value]="userData.birthDate" disabled readonly>
            </div>
          </div>
        </div>

        <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12">
          <hr />

          <div class="form-group row m-b-15">
            <label class="col-form-label col-md-12">Correo electrónico</label>
            <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12">
              <input class="form-control" type="text" [value]="userData.email" disabled readonly>
            </div>
          </div>

          <div class="form-group row m-b-15">
            <label class="col-form-label col-md-12">Número celular</label>
            <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12">
              <input class="form-control" type="text" [value]="userData.cellPhone" disabled readonly>
            </div>
          </div>
        </div>
      </div>
    </panel>
  </div>

  <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
    <panel title="Información de usuario" noButton="true" variant="default" bodyClass="border-default">
      <div class="row" *ngIf="userData$ | async as userData">
        <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12">
          <div class="form-group row m-b-15">
            <label class="col-form-label col-md-12">Rol asignado</label>
            <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12">
              <input class="form-control" type="text" [value]="userData.role" disabled readonly>
            </div>
          </div>
        </div>
      </div>
    </panel>

    <panel title="Actualizar contraseña" noButton="true" variant="default" bodyClass="border-default">
      <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmitChangePassword()">
        <div class="row">
          <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12">
            <div class="form-group row m-b-15">
              <label class="col-form-label col-md-12">Contraseña Actual</label>
              <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12">
                <input class="form-control" formControlName="oldPassword" class="form-control" type="password"
                  [ngClass]="{
                  'is-valid': !oldPassword.invalid,
                  'is-invalid': (oldPassword.invalid)
                }">
              </div>
            </div>

            <div class="form-group row m-b-15">
              <label class="col-form-label col-md-12">Nueva Contraseña</label>
              <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12">
                <input class="form-control" formControlName="newPassword" class="form-control" type="password"
                  [ngClass]="{
                  'is-valid': !newPassword.invalid,
                  'is-invalid': (newPassword.invalid)
                }">
                <small id="passwordHelpBlock" class="form-text text-muted">
                  Recuerda que la contraseña debe tener entre 8 y 16 caracteres, una letra mayúscula, una minúscula y un
                  número.
                </small>
              </div>
            </div>
          </div>

          <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12">
            <hr />
            <div class="form-group">
              <button type="submit" tabindex=”12” class="btn btn-success pull-right"
                [disabled]="!changePasswordForm.valid">Actualizar ahora</button>
            </div>
          </div>
        </div>
      </form>
    </panel>
  </div>

  <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12">
    <div class="alert alert-warning" role="alert">
      <b>Tip de seguridad: </b> Te recomendamos actualizar tu contraseña al menos, cada 90 dias.
    </div>
  </div>  
</div>