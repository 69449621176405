import { guid, StoreConfig, Store, EntityState, EntityStore } from '@datorama/akita';
import pageSettings from '../../config/page-settings';
import { Injectable } from '@angular/core';
import { BaseEntity, BaseEntityDetail, TicketUser_ } from 'src/app/interfaces/user.interface';

export interface TicketUser {
  academicLevel: number;
  address: string;
  city: number;
  cityName: string;
  documentExpeditionDate: string;
  proffesion: number;
  typePerson: number;
  user: TicketUser_;
  userId: 122
}

export interface TicketUserState extends EntityState<TicketUser, number> {
  academicLevel: number;
  address: string;
  city: number;
  cityName: string;
  documentExpeditionDate: string;
  proffesion: number;
  typePerson: number;
  user: TicketUser_;
  userId: 122
}

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'TicketUser' })
export class TicketUserStore extends EntityStore<TicketUserState> {
  constructor() {
    super();
  }
}
