export interface IColumnHeaders {
  title: string;
  name: string;
  sort?: boolean;
}

export const BaseTableColumnHeaders: Array<IColumnHeaders> = [
  {
    title: '-',
    name: 'have_extra_msg_',
    sort: false,
  },
  {
    title: 'Consecutivo',
    name: 'consecutive',
  },
  {
    title: 'Fecha de creación',
    name: 'creationDate',
    sort: false,
  },
  {
    title: 'Documento de solicitante',
    name: 'moanfulSerial',
    sort: false,
  },
  {
    title: 'Nombre de solicitante',
    name: 'moanfulName',
    sort: false,
  },
  {
    title: 'Tipo de solicitud',
    name: 'typeComplaintName',
    sort: false,
  },
  {
    title: 'Tiempo de respuesta',
    name: 'counterDays',
    sort: false,
  },
  {
    title: 'Entidad',
    name: 'entityName',
    sort: false,
  },
  {
    title: 'Medio de solicitud',
    name: 'receivingMediumName',
    sort: false,
  },
  {
    title: 'Estado actual',
    name: 'stateTicketName',
    sort: false,
  },    
  {
    title: 'Enviada',
    name: 'is_sent_',
    sort: false,
  },
  {
    title: 'Fecha de asignación',
    name: 'assignmentDate',
    sort: false,
  },
];

export const MoanfulTableColumnHeaders: Array<IColumnHeaders> = [
  {
    title: 'Consecutivo',
    name: 'id',
  },
  {
    title: 'Fecha de creación',
    name: 'creationDate',
    sort: false,
  },
  {
    title: 'Tipo de solicitud',
    name: 'typeComplaintName',
    sort: false,
  },
  {
    title: 'Tiempo de respuesta',
    name: 'counterDays',
    sort: false,
  },
  {
    title: 'Entidad',
    name: 'entityName',
    sort: false,
  },
  {
    title: 'Estado actual',
    name: 'stateTicketName',
    sort: false,
  },
];

export const EntityColumnHeaders: Array<IColumnHeaders> = [
  {
    title: '-',
    name: 'have_extra_msg_',
    sort: false,
  },
  {
    title: 'Consecutivo',
    name: 'consecutive',
  },
  {
    title: 'Tiempo de respuesta',
    name: 'counterDays',
    sort: false,
  },
  {
    title: 'Documento de solicitante',
    name: 'moanfulSerial',
    sort: false,
  },
  {
    title: 'Nombre de solicitante',
    name: 'moanfulName',
    sort: false,
  },
  {
    title: 'Fecha límite',
    name: 'maxDate',
    sort: false,
  },
  {
    title: 'Producto',
    name: 'productName',
    sort: false,
  },
  {
    title: 'Motivo',
    name: 'typeFormat378Name',
    sort: false,
  },
  {
    title: 'Estado actual',
    name: 'stateTicketName',
    sort: false,
  }
];

export const LawyerColumnHeaders: Array<IColumnHeaders> = [
  {
    title: '-',
    name: 'have_extra_msg_',
    sort: false,
  },
  {
    title: 'Consecutivo',
    name: 'consecutive',
  },
  {
    title: 'Fecha de asignación',
    name: 'assignmentDate',
    sort: false,
  },
  {
    title: 'Documento de solicitante',
    name: 'moanfulSerial',
    sort: false,
  },
  {
    title: 'Nombre de solicitante',
    name: 'moanfulName',
    sort: false,
  },
  {
    title: 'Entidad',
    name: 'entityName',
    sort: false,
  },
  {
    title: 'Tipo de solicitud',
    name: 'typeComplaintName',
    sort: false,
  },
  {
    title: 'Estado actual',
    name: 'stateTicketName',
    sort: false,
  },
  {
    title: 'Tiempo de respuesta',
    name: 'counterDays',
    sort: false,
  },
];

export const PaginationConfig = (role) => {
  const columns = getRoleColumnHeaders(role);
  function getRoleColumnHeaders(role) {
    switch (role) {
      case 'RADICADOR':
        return BaseTableColumnHeaders;
      case 'ENTIDAD':
        return EntityColumnHeaders;
      case 'ABOGADO':
        return LawyerColumnHeaders;
      case 'APROBADOR':
        return BaseTableColumnHeaders;
      case 'QUEJOSO':
        return MoanfulTableColumnHeaders;
      default:
        return BaseTableColumnHeaders;
    }
  }
  
  return {
    baseConfig: {
      paging: true,
      sorting: {columns: columns},
      className: ['table-striped', 'table-bordered', 'm-b-0']
    },
    maxSize: 3,
    numPages: 1
  }
}