<ol class="breadcrumb float-xl-right">
  <li class="breadcrumb-item"><a href="javascript:;">Inicio</a></li>
</ol>

<h1 class="page-header">Asignación manual</h1>

<div class="panel panel-default">
  <div class="panel-body pb-0">
    <form [formGroup]="filtersForm" (ngSubmit)="submitFilters()" class="margin-bottom-0">
      <div class="row">

        <!-- <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
          <div class="form-group row m-b-15">
            <label class="col-form-label"><b>Seleccionar entidad</b></label>
            <div class="col-md-12">
              <input formControlName="entity" class="form-control m-b-5" placeholder="Ej: Banco Falabella" type="search"
                tabindex=”2” [ngbTypeahead]="searchEntity" (focus)="focusEntity$.next($any($event).target.value)"
                (click)="clickEntity$.next($any($event).target.value)" #instanceEntity="ngbTypeahead">
            </div>
          </div>
        </div> -->
        <!-- Prueba selección de entidad -->
        <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
          <div class="form-group row m-b-15" *ngIf="entities$ | async as entities">
            <label class="col-form-label"><b>Seleccione Entidad</b></label>
            <div class="col-md-12">
              <ng-select appearance="outline" [searchable]="true" formControlName="entity" [items]="entities"
                bindLabel="name" [clearable]="true" bindValue="id" placeholder="Seleccione la entidad">
              </ng-select>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
          <div class="form-group row m-b-15">
            <label class="col-form-label"><b>Consecutivo</b></label>
            <div class="col-md-12">
              <input formControlName="consecutive" class="form-control m-b-5" placeholder="Ej: 20-01-0010" type="search"
                tabindex=”0”>
            </div>
          </div>
        </div>

        <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
          <div class="form-group row m-b-15">
            <div class="col-md-12">
              <button type="submit" class="btn btn-primary m-r-10">Filtrar resultados</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<panel title="Solicitudes" noButton="true">
  <ng-container *ngIf="getComponentLoading()">
    <ng-container outsideBody>
      <div class="table-responsive text-nowrap">
        <table class="table table-reversed table-hover">
          <thead>
            <tr>
              <th *ngFor="let column of columns">{{column.title}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="paginateData.data.length == 0">
              <td [attr.colspan]="columns.length" style="text-align: center;">
                <i class="fa fa-exclamation-circle fa-3x m-t-15"></i><br />
                <h5 class="m-t-15">No se han encontrado resultados</h5>
              </td>
            </tr>
            <tr *ngFor="let row of paginateData.data">
              <td *ngFor="let keyD of columns" [innerHTML]="getFormatedRow(row, keyD.name)" [class]="getRowClass(row)"
                (click)="openAssinmentModal(modalDialog, row)"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
    <ng-container footer>
      <div class="clearfix"></div>
      <ngb-pagination *ngIf="config.baseConfig.paging" class="d-flex justify-content-center m-t-15"
        (pageChange)="changePage($event, true)" [page]="filters.page" [currentPage]="filters.page"
        [maxSize]="config.maxSize" [boundaryLinks]="true" [collectionSize]="paginateData.totalRegister" [rotate]="true"
        (numPages)="config.numPages = $event"></ngb-pagination>
    </ng-container>
    <div class="row">
      <div class="clearfix"></div>
      <div class="alert py-0 mb-0 width-full">
        <div class="col-md-12 text-center font-weight-bold">
          <label class="col-form-label text-center" *ngIf="config.baseConfig.paging">Página: {{filters.page}} /
            {{paginateData.totalPage}}</label>
        </div>
      </div>
    </div>
  </ng-container>
</panel>

<ng-template #modalDialog let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Asignación de ticket</h4>
  </div>

  <div class="modal-body">
    <form [formGroup]="assignmentForm" (ngSubmit)="submitAssignment()" class="margin-bottom-0">
      <div class="row">
        <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
          <div class="form-group row m-b-15">
            <label class="col-form-label"><b>Consecutivo</b></label>
            <div class="col-md-12">
              <input class="form-control m-b-5" formControlName="ticketConsecutive">
            </div>
          </div>
        </div>

        <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
          <div class="form-group row m-b-15">
            <label class="col-form-label"><b>Entidad</b></label>
            <div class="col-md-12">
              <input class="form-control m-b-5" formControlName="entityName">
            </div>
          </div>
        </div>

        <div class="col-md-12 col-xl-12 col-xs-12 col-sm-12 col-lg-12">
          <div class="form-group row m-b-15">
            <label class="col-form-label"><b>Seleccionar abogado</b></label>
            <div class="col-md-12">
              <select class="form-control" formControlName="assignedId">
                <option *ngFor="let lawyer of lawyers$ | async" [ngValue]="lawyer.id">{{lawyer.name}}
                  {{lawyer.lastname}} <small>({{lawyer.email}})</small></option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer pb-0">
        <button type="button" class="btn btn-default" (click)="c('Close click')">Cancelar</button>
        <button type="submit" class="btn btn-primary">Asignar</button>
      </div>
    </form>
  </div>
</ng-template>