import { guid, StoreConfig, Store, EntityState, EntityStore } from '@datorama/akita';
import pageSettings from '../../config/page-settings';
import { Injectable } from '@angular/core';

export interface Role  {
  id: number;
  name: string;
  state: string;
}

export interface RoleState extends EntityState<Role, number> {
  id: number;
  name: string;
  state: string;
}

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'Role' })
export class RoleStore extends EntityStore<RoleState> {
  constructor() {
    super();
    this.set({1: null}, {activeId: 1});
  }
}
