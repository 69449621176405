import { Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import swal from 'sweetalert2';
import { CompanyQuery } from 'src/app/states/company';
import { ExternalService } from 'src/app/services/external.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'home',
  templateUrl: './home.html'
})

export class HomePage implements OnDestroy, OnInit  {

  closeResult: string;
  selectedId: number;

  entities$ = this.companyQuery.company$();

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private companyQuery: CompanyQuery,
    private externalService: ExternalService,
    private spinner: NgxSpinnerService,
  ) {}

  async ngOnInit() {
    this.spinner.show();
    // Get all data from /entity endpoint
    await new Promise(resolve => setTimeout(resolve, 1000));
    await this.externalService.getEntities();
    this.spinner.hide();
  }

  ngOnDestroy() {
    this.modalService.dismissAll('Destroy Component');
  }

  activeBackground(id: number) {
    return (this.selectedId === id) ? '#cfd0da' : '#ffffff';
  }

  setActive(id: number) {
    this.selectedId = ((this.selectedId === id) ? null : id);
  }

  goToComplaint() {
    if (this.selectedId == null) {
      swal.fire('Ooops!!!', 'Creo que no has seleccionado aún una entidad para reclamos', 'error');
    } else {
      this.router.navigate(['/complaint', this.selectedId]);
    }
  }

  open(content) {
    this.modalService.open(content, { size: 'xl', backdrop: 'static' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
}
