import { Component, OnDestroy, Renderer2, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TypePersonQuery } from 'src/app/states/type_person';
import { ExternalService } from 'src/app/services/external.service';
import * as moment from 'moment';
import { Utils } from 'src/app/common/utils';

@Component({
  selector: 'login',
  templateUrl: './login.html'
})

export class LoginPage implements OnDestroy, OnInit {
  showPassword = true;

  years_ago_init = {
    year: 1750,
    month: 1,
    day: 1
  }

  today_mark = {
    year: moment().year(),
    month: moment().month() + 1,
    day: moment().date()
  }

  loginRole = 'collaborator';

  loginForm = new FormGroup({
    username: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
  });

  loginMoanFul = new FormGroup({
    typeDocumentId: new FormControl(1, [Validators.required]),
    serial: new FormControl('', Validators.required),
    documentDate: new FormControl(null, Validators.required),
  });

  documentTypes$ = this.typePersonQuery.getAllDocumentTypes();

  public async ngOnInit() {
    if (this.authService.getAccessToken()) {
      this.router.navigate(['dashboard']);
      return;
    }
    await this.externalService.getTypePersonAndDocuments();
    const queryParams = this.route.snapshot.queryParams;
    this.loginRole = queryParams['role'] || this.loginRole;
  }

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private typePersonQuery: TypePersonQuery,
    private externalService: ExternalService,
    ) {
    this.renderer.addClass(document.body, 'bg-white');
  }

  get username() {
    return this.loginForm.get('username');
  }

  get typeDocumentId() {
    return this.loginMoanFul.get('typeDocumentId');
  }

  get serial() {
    return this.loginMoanFul.get('serial');
  }

  get documentDate() {
    return this.loginMoanFul.get('documentDate');
  }

  get password() {
    return this.loginForm.get('password');
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'bg-white');
  }

  getForm() {
    if (this.loginRole == 'collaborator') {
      return {
        email: this.username.value,
        password: this.password.value
      }
    }
    return{
      typeDocumentId: this.typeDocumentId.value,
      serial: this.serial.value,
      documentDate: Utils.getDate(this.documentDate.value),
    } 
  }

  async onSubmit() {
    try {
      this.spinner.show();
      const form = this.getForm();
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      const response = await this.authService.login(form, this.loginRole == 'collaborator');      
      let redirect = this.route.snapshot.queryParams['redirect'] || '/dashboard';  
      redirect = redirect.replace('@', '?');
      
      if (response.data.otpRequired) redirect = `verification?uid=${response.data.refreshToken}`  
      else this.authService.storeTokens(response.data.accessToken, response.data.refreshToken)

      this.spinner.hide();
      this.router.navigateByUrl(redirect);
    } catch (error) {
      this.spinner.hide();
    }
  }
}
