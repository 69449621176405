<ol class="breadcrumb float-xl-right">
    <li class="breadcrumb-item"><a href="javascript:;">Inicio</a></li>
</ol>

<h1 class="page-header">Auditoria</h1>

<div class="panel panel-default">
    <div class="panel-body pb-0">
        <form [formGroup]="filtersForm" (ngSubmit)="submitFilters()" class="margin-bottom-0">
            <div class="row">
                <div class="col-md-6 col-xl-4 col-xs-12 col-sm-12 col-lg-4">
                    <div class="form-group row m-b-15">
                        <label class="col-form-label"><b>Usuario</b></label>
                        <div class="col-md-12">
                            <input formControlName="userEmail" class="form-control m-b-5"
                                placeholder="Ej: alguien@email.com" type="search" tabindex=”0”>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-xl-4 col-xs-12 col-sm-12 col-lg-4">
                    <div class="form-group row m-b-15">
                        <label class="col-form-label"><b>Consecutivo</b></label>
                        <div class="col-md-12">
                            <input formControlName="consecutive" class="form-control m-b-5" placeholder="Ej: 20-01-0010"
                                type="search" tabindex=”0”>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-xl-4 col-xs-12 col-sm-12 col-lg-4">
                    <div class="form-group row m-b-15">
                        <label class="col-form-label"><b>Fecha</b></label>
                        <div class="col-md-12">
                            <input formControlName="date" class="form-control m-b-5" type="date" tabindex=”0”>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 col-xl-6 col-xs-12 col-sm-12 col-lg-6">
                    <div class="form-group row m-b-15">
                        <div class="col-md-12">
                            <button type="submit" class="btn btn-primary m-r-10">Filtrar registros</button>
                            <button type="reset" class="btn btn-warning m-r-10">Limpiar</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<panel title="Registro de auditoria" noButton="true">
    <ng-container *ngIf="getComponentLoading()">
        <ng-container outsideBody>
            <div class="table-responsive text-nowrap">
                <table class="table table-reversed table-hover">
                    <thead>
                        <tr>
                            <th *ngFor="let column of columns">{{column.title}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="paginateData.data.length == 0">
                            <td [attr.colspan]="columns.length" style="text-align: center;">
                                <i class="fa fa-exclamation-circle fa-3x m-t-15"></i><br />
                                <h5 class="m-t-15">No se han encontrado resultados</h5>
                            </td>
                        </tr>
                        <tr *ngFor="let row of paginateData.data">
                            <td *ngFor="let keyD of columns" [innerHTML]="getFormatedRow(row, keyD.name)"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </ng-container>
        <ng-container footer>
            <div class="clearfix"></div>
            <ngb-pagination *ngIf="config.baseConfig.paging" class="d-flex justify-content-center m-t-15"
                (pageChange)="changePage($event, true)" [page]="filters.page" [currentPage]="filters.page"
                [maxSize]="config.maxSize" [boundaryLinks]="true" [collectionSize]="paginateData.totalRegister"
                [rotate]="true" (numPages)="config.numPages = $event"></ngb-pagination>
        </ng-container>
        <div class="row">
            <div class="clearfix"></div>
            <div class="alert py-0 mb-0 width-full">
                <div class="col-md-12 text-center font-weight-bold">
                    <label class="col-form-label text-center" *ngIf="config.baseConfig.paging">Página: {{filters.page}}
                        /
                        {{paginateData.totalPage}}</label>
                </div>
            </div>
        </div>
    </ng-container>
</panel>