import { AuthGuardService as AuthGuard } from '../guards/auth.guard';
import { Route, Routes, RunGuardsAndResolvers } from '@angular/router';
import { LoginPage } from '../pages/login/login';
import { DashboardPage } from '../pages/dashboard/dashboard';
import { ComplaintPage } from '../pages/complaint/complaint';
import { ComplaintDetailPage } from '../pages/complaint_detail/complaint_detail';
import { HomePage } from '../pages/home/home';
import { ComplaintsPage } from '../pages/complaints/complaints';
import { NotFoundPage } from '../pages/not_found/not_found';
import { ManualComplaintPage } from '../pages/manual_complaint/manual_complaint';
import { UserProfilePage } from '../pages/user_profile/user_profile';
import { UsersListPage } from '../pages/users/list/users_list';
import { UsersDetailPage } from '../pages/users/detail/users_detail';
import { ManualAssignmentPage } from '../pages/manual_assignment/manual_assignment';
import { UpdatePasswordPage } from '../pages/password/update_password';
import { VerificationPage } from '../pages/login/verification/verification';
import { AuditsPage } from '../pages/audits/audits';
import { AutoAssignmentPage } from '../pages/auto_assignment/auto_assignment';

export const routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomePage, data: { title: 'Inicio'}, canActivate: [AuthGuard] },
  { path: 'complaint/:id', component: ComplaintPage, data: { title: 'Tramita tu queja'}, canActivate: [AuthGuard] },
  { path: 'login', component: LoginPage, data: { title: 'Iniciar Sessión'}, canActivate: [AuthGuard] },
  { path: 'not_found', component: NotFoundPage, name: 'NotFound' ,data: { title: 'Recurso no encontrado'} },
  { path: 'update_password', component: UpdatePasswordPage, name: 'UpdatePassword' ,data: { title: 'Actualizar contraseña'} },
  { path: 'verification', component: VerificationPage, name: 'Verification' ,data: { title: 'Verificacion de usuario'} },
  {
    path: 'dashboard',
    component: DashboardPage,
    data: {
      title: 'Inicio',
      withRoles: ['*'],
      icon: 'fa fa-th-large',
      url: '/dashboard',
      isMenu: true,
      caret: false,
    },
    canActivate: [AuthGuard]
  },
  // Radicator role complaints route
  {
    path: 'complaints',
    component: ComplaintsPage, data: {
      title: 'Lista de quejas',
      withRoles: ['RADICADOR', 'ENTIDAD', 'ABOGADO', 'APROBADOR', 'QUEJOSO', 'ADMINISTRADOR'],
      icon: 'fa fa-list',
      url: '/complaints',
      isMenu: true,
      caret: false,
    },
    canActivate: [AuthGuard]
  },  
  {
    path: 'assignment',
    component: ManualAssignmentPage, data: {
      title: 'Asignacion manual',
      withRoles: ['APROBADOR', 'ADMINISTRADOR'],
      icon: 'fa fa-random',
      url: '/assignment',
      isMenu: true,
      caret: false,
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'complaints/:id',
    component: ComplaintDetailPage, data: {
      title: 'Detalle de solicitud',
      withRoles: ['RADICADOR', 'ENTIDAD', 'ABOGADO', 'APROBADOR', 'QUEJOSO', 'ADMINISTRADOR'],
      icon: 'fa fa-list',
      url: '/complaints/:id',
      isMenu: false,
      caret: false,
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'ticket-radicator',
    component: ManualComplaintPage, data: {
      title: 'Ingresar queja manualmente',
      withRoles: ['RADICADOR'],
      icon: 'fa fa-clipboard-list',
      url: '/ticket-radicator',
      isMenu: true,
      caret: false,
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'user/profile',
    component: UserProfilePage, data: {
      title: 'Información de perfil',
      withRoles: ['*'],
      icon: 'fa fa-clipboard-list',
      url: '/ticket-radicator',
      isMenu: false,
      caret: false,
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'user/list',
    component: UsersListPage, data: {
      title: 'Usuarios',
      withRoles: ['ADMINISTRADOR'],
      icon: 'fa fa-user',
      url: '/user/list',
      isMenu: true,
      caret: false,
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'user/detail/:id',
    component: UsersDetailPage, data: {
      title: 'Usuarios',
      withRoles: ['ADMINISTRADOR'],
      icon: 'fa fa-user',
      url: '/user/detail/:id',
      isMenu: false,
      caret: false,
    },
    
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always' as RunGuardsAndResolvers
  },
  {
    path: 'audits',
    component: AuditsPage, data: {
      title: 'Auditoria',
      withRoles: ['ADMINISTRADOR'],
      icon: 'fa fa-search ',
      url: '/audits',
      isMenu: true,
      caret: false,
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'autoAssignment',
    component: AutoAssignmentPage, data: {
      title: 'Asignación Automatica',
      withRoles: ['APROBADOR', 'ADMINISTRADOR'],
      icon: 'fa fa-share-alt ',
      url: '/autoAssignment',
      isMenu: true,
      caret: false,
    },
    canActivate: [AuthGuard]
  }
];
