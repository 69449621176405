import { AuthService } from 'src/app/services/auth.service';
import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { map, shareReplay, tap, filter, startWith, switchMap, flatMap } from 'rxjs/operators';
import { routes } from 'src/app/config/routes';
import { EntityProduct, EntityProductState, EntityProductStore } from './entity_product.store';
import { Observable } from 'rxjs-compat';
import { ItemsList } from '@ng-select/ng-select/lib/items-list';

@Injectable({
  providedIn: 'root'
})
export class EntityProductQuery extends QueryEntity<EntityProductState> {
  constructor(protected store: EntityProductStore) {
    super(store);
  }

  active$ = () => this.selectEntityProduct().pipe(shareReplay(1));

  getSnapshot = () => this.getValue();

  isLoading$ = () => this.selectLoading();

  entityProduct$ = () => this.selectEntityProduct().pipe(shareReplay(1));

  entityClientsProduct$ = () => this.selectEntityProduct().pipe(
    map(products=> products.filter(x=> x.id > 63)),
    shareReplay(1));

  selectEntityProduct() {
    return this.selectAll().pipe(
      map(it => it.filter(x => x != null)),
      map(it => it.map(x => ({
        ...x,
        detailName: `${x.id} - ${x.name}`
      })))
    )
  }

  setLoading(isLoading) {
    this.store.setLoading(isLoading);
  }


  setState(data, activeId) {
    this.store.set(data, activeId);
  }

}
