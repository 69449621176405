import { AuthService } from 'src/app/services/auth.service';
import { QueryEntity } from '@datorama/akita';
import { PageSettingsStore, PageSettingsState, PageSettings } from './page_settings.store';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map, shareReplay, tap, filter, startWith } from 'rxjs/operators';
import { routes } from 'src/app/config/routes';

@Injectable({
  providedIn: 'root'
})
export class PageSettingsQuery extends QueryEntity<PageSettingsState> {
  constructor(protected store: PageSettingsStore) {
    super(store);
  }

  active$ = () => this.selectSettings().pipe(shareReplay(1));
  hiddingBars$ = () => this.selectSettings().pipe(shareReplay(1), map(it => it.hiddingBars));
  userData$ = () => this.selectSettings().pipe(shareReplay(1), map(it => it.userData));
  allowedRoutes = (auth: AuthService) => this.getAllowedRoutes(auth);

  getSnapshot = () => this.getValue();

  isLoading$ = () => this.selectLoading();

  public getAllowedRoutes(auth: AuthService) {
    return routes.map(x => x)
      .filter(x => x.data !== undefined)
      .filter(x => x.data.withRoles !== undefined)
      .filter(x => x.data.withRoles.includes('*') || x.data.withRoles.includes(auth.getRole()));
  }

  selectSettings() {
    return this.store._select(it => it).pipe(filter(it => !it.loading)).pipe(shareReplay(1), map(it => it.entities[1]));
  }

  setLoading(isLoading) {
    this.store.setLoading(isLoading);
  }

  updateSettings(data) {
    this.store.update(1, data);
  }

  setState(data, activeId) {
    this.store.set({1: data}, activeId);
  }

}
