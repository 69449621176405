import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { map, shareReplay, tap, filter, startWith, switchMap, flatMap } from 'rxjs/operators';
import { SpecialConditionState, SpecialConditionStore } from './special_condition.store';

@Injectable({
  providedIn: 'root'
})
export class SpecialConditionQuery extends QueryEntity<SpecialConditionState> {
  constructor(protected store: SpecialConditionStore) {
    super(store);
  }

  active$ = () => this.selectSpecialCondition().pipe(shareReplay(1));

  getSnapshot = () => this.getValue();

  isLoading$ = () => this.selectLoading();

  specialCondition$ = () => this.selectSpecialCondition();

  selectSpecialCondition() {
    return this.selectAll().pipe(
      map(it => it.filter(x => x != null))
    )
  }

  setLoading(isLoading) {
    this.store.setLoading(isLoading);
  }

  setState(data, activeId) {
    this.store.set(data, activeId);
  }

}