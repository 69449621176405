import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Utils } from 'src/app/common/utils';
import { TicketService } from 'src/app/services/ticket.service';
import { ExternalService } from 'src/app/services/external.service';
import { TicketQuery } from 'src/app/states/ticket';
import { TicketFileQuery } from 'src/app/states/ticket_file/ticket_file.query';
import { AttachedFilesRequestPayload } from 'src/app/interfaces/external.interface';
import { AuthService, IRoleDetail } from 'src/app/services/auth.service';
import swal from 'sweetalert2';

@Component({
  selector: 'complaint_description',
  templateUrl: './complaint_description.html'
})

export class ComplaintDescriptionPage implements OnInit {
  public ticketFiles$ = this.ticketFileQuery.ticketFile$();
  public ticketFileCount$ = this.ticketFileQuery.ticketFileCount$()
  ticketData$ = this.ticketQuery.ticket$();

  roleDetail: IRoleDetail;
  
  constructor(
    private route: ActivatedRoute,
    private ticketFileQuery: TicketFileQuery,
    private ticketService: TicketService,
    private externalService: ExternalService,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private ticketQuery: TicketQuery
  ) {

  }

  public async ngOnInit() {
    this.roleDetail = this.authService.getRoleDetail();
    await this.fetchData();
  }

  public async fetchData() {
    this.spinner.show();
    const params = this.route.snapshot.params;
    await this.ticketService.getFilesByTicket(params['id'])
    await this.spinner.hide();
  }

  public getIconObject(iconUrl) {
    const splitedUrl = iconUrl.split('.');
    const extension = splitedUrl[(splitedUrl.length - 1)]
    return {
      content: Utils.IMAGE_FORMATS.includes(extension) ? `<img src="${iconUrl}" alt="">` : '<i class="fa fa-file"></i>',
      icon: Utils.IMAGE_FORMATS.includes(extension) ? 'fa-camera' : 'fa-file'
    }
  }

  public async openUploadFileDialog() {
    document.getElementById('fileDropRef').click();
  }

  public async deleteFile(attachedId){
    await swal.fire({
      title: '¿Está seguro de eliminar este soporte?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, continuar',
      cancelButtonText: 'No, quiero pensarlo mejor'
    }).then(async (result) => {
      if (result.value){
        this.spinner.show();
        await this.ticketService.markAsDeleteAttachment(attachedId)
        await this.spinner.hide();
        
        await this.fetchData();
      }        
    });
  }

  public async uploadSelectedFile(fileList, ticketId) {
    this.spinner.show();

    let fileArray = new Array();
    for (let i = 0; i < fileList.length; i++)
      fileArray[fileArray.length] = fileList.item(i);    

    const attachedFiles = { ticketId: ticketId, files: fileArray } as AttachedFilesRequestPayload;
    await this.externalService.attachTicketFiles(attachedFiles);
    await this.spinner.hide();

    await this.fetchData();
  }

  public async downloadZip(consecutive: number) {
    this.spinner.show();
    const params = this.route.snapshot.params;
    await this.ticketService.downloadTicketFiles(consecutive || params['id'])
    await this.spinner.hide();
  }

  isRadicatedState(ticketState: number) {
    return Utils.isRejectedState(ticketState);
  }
}
