import { AfterViewChecked, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal, NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Utils } from 'src/app/common/utils';
import { AuthService, IRoleDetail } from 'src/app/services/auth.service';
import { TicketService } from 'src/app/services/ticket.service';
import { Location } from '@angular/common';
import { TicketQuery } from 'src/app/states/ticket';
import { RichTextEditorComponent } from '@syncfusion/ej2-angular-richtexteditor';
import { ViewChild } from '@angular/core';
import { ExternalService } from 'src/app/services/external.service';
import { RejectionTypeQuery } from 'src/app/states/rejection_type';
import { TypeFormatQuery } from 'src/app/states/type_format';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { CompanyQuery } from 'src/app/states/company';
import { TypeTicketQuery } from 'src/app/states/type_ticket';
import { TicketLog, TicketLogQuery } from 'src/app/states/ticket_log';
import { map, startWith } from 'rxjs/operators';
import { EntityTicketAction } from 'src/app/interfaces/ticket.interface';
import { combineLatest, forkJoin, Observable } from 'rxjs';
import { TicketAnswerQuery } from 'src/app/states/ticket_answer';
import { LawyerQuery } from 'src/app/states/lawyer';
import { EntityProductQuery } from 'src/app/states/entity_product';
import { TypeResponseApprovePronouncementQuery } from 'src/app/states/type-response-approve-pronouncement/type-response-approve-pronouncement.query';
import swal from 'sweetalert2';

@Component({
  selector: 'complaint_detail',
  templateUrl: './complaint_detail.html'
})

export class ComplaintDetailPage implements OnInit, AfterViewChecked, OnDestroy {

  activeIdString = "general";

  answerFormType: EntityTicketAction;

  detailData$ = this.ticketQuery.ticket$();

  files_: File[] = [];
  closeResult: string;
  roleDetail: IRoleDetail;
  editorValue = '';
  rejectionTypes$ = this.rejectiontypeQuery.RejectionType$();
  typeFormats$ = this.typeFormatQuery.TypeFormat$();
  products$ = combineLatest(
    this.entityProductQuery.entityProduct$(),
    this.detailData$
  ).pipe(map(it => {
    const el = it[0].map(x => {
      return {
        ...x,
        selected: x.id == it[1].product
      }
    })
    return el
  }));

  entities$ = combineLatest(
    this.companyQuery.company$(),
    this.detailData$
  ).pipe(map(it => {
    const el = it[0].map(x => {
      return {
        ...x,
        selected: x.id == it[1].entity
      }
    })
    return el
  }));

  typeTicket$ = combineLatest(
    this.typeTicketQuery.typeTicket$(),
    this.detailData$
  ).pipe(map(it => {
    const el = it[0].map(x => {
      return {
        ...x,
        disabled: x.id == it[1].typeTicket
      }
    })
    return el
  }));
  ticketLogs$ = this.ticketLogQuery.ticketLog$();

  benefits$ = this.typeResponseApprovePronouncementQuery.TypeResponseApprovePronouncement$();

  // Changeeeee
  lawyer$ = combineLatest(
    this.lawyerQuery.lawyer$(),
    this.detailData$
  ).pipe(map(it => {
    const el = it[0].map(x => {
      return {
        ...x,
        fullname: `${x.name} ${x.lastname}`,
        disabled: `${x.name} ${x.lastname}` == it[1].userName
      }
    })
    return el
  }));

  currentId;

  @ViewChild('fromRTE', { static: false }) private rteEle: RichTextEditorComponent;

  todayDate = new Date();
  events = [];
  editorStyle = {
    height: '200px',
    display: 'block'
  };

  rejectionForm = new FormGroup({
    rejectionTypeId: new FormControl(null, [Validators.required]),
    replicaConsecutive: new FormControl(null, []),
    note: new FormControl(null, [Validators.required, Validators.minLength(15)])
  });

  assignationForm = new FormGroup({
    entityId: new FormControl(null, [Validators.required]),
    typeFormat378Id: new FormControl(null, [Validators.required]),
    productId: new FormControl(null)
  });

  changeTypeForm = new FormGroup({
    typeTicketId: new FormControl(null, [Validators.required]),
  });

  askForInformationform = new FormGroup({
    subject: new FormControl('[RADICADOR] Solicitud de Información y/o Documentos', [Validators.required]),
    message: new FormControl(null, [Validators.required]),
    typeResponseTicketExtraId: new FormControl(null),
    files: new FormControl([])
  });

  assignTicketForm = new FormGroup({
    assignedId: new FormControl(null),
  });

  isHidden$: Observable<boolean> = this.rejectionForm.get('rejectionTypeId').valueChanges.pipe(
    map((rejectionTypeId: number) => {
      if (rejectionTypeId !== Utils.REPLICA_TYPE_STATE) {
        this.rejectionForm.get('replicaConsecutive').clearValidators();
        this.rejectionForm.get('replicaConsecutive').updateValueAndValidity();
        return true;
      }

      this.rejectionForm.get('replicaConsecutive').setValidators([Validators.required, Validators.minLength(10)])
      this.rejectionForm.get('replicaConsecutive').updateValueAndValidity();

      return false;
    }),
    startWith(true)
  );

  constructor(
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private authService: AuthService,
    private ticketService: TicketService,
    private externalService: ExternalService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private location: Location,
    private ticketQuery: TicketQuery,
    private rejectiontypeQuery: RejectionTypeQuery,
    private typeFormatQuery: TypeFormatQuery,
    private companyQuery: CompanyQuery,
    private typeTicketQuery: TypeTicketQuery,
    private ticketLogQuery: TicketLogQuery,
    private ticketAnswerQuery: TicketAnswerQuery,
    private lawyerQuery: LawyerQuery,
    private entityProductQuery: EntityProductQuery,
    private typeResponseApprovePronouncementQuery: TypeResponseApprovePronouncementQuery

  ) {
    this.spinner.show();

  }

  ngAfterViewChecked(): void {

  }

  ngOnDestroy(): void {
    this.ticketService.resetStores();
  }

  public async ngOnInit() {
    try {
      this.roleDetail = this.authService.getRoleDetail();
      const it = this.route.snapshot.params;
      this.currentId = parseInt(it['id'], 10);
      await this.fetchData(it['id']);
      await new Promise(resolve => setTimeout(resolve, 1000));
      await this.spinner.hide();
      const params = this.route.snapshot.queryParams;
      if (params['tab'] != null && params['tab'] != '') { this.activeIdString = params['tab'] }
    } catch (error) { }
  }

  open(content, type: EntityTicketAction) {
    this.answerFormType = type;
    this.modalService.open(content, { size: 'xl', backdrop: 'static' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private async fetchData(consecutive: number) {
    // Get tickets
    await this.ticketService.retrieveTicket(consecutive);

    await this.externalService.getRejectionType();
    await this.externalService.getTypeFormatAssign();
    await this.externalService.getEntities();
    await this.externalService.getTypeTicket();
    await this.ticketService.getTicketLogs(consecutive)
    const sub = this.detailData$.subscribe(async (it) => {
      this.changeTypeForm.controls.typeTicketId.patchValue(it.typeTicket);
      this.assignationForm.controls.entityId.patchValue(it.entity);
      this.assignationForm.controls.productId.patchValue(it.product);
      this.assignationForm.controls.typeFormat378Id.patchValue(it.typeFormat378);
      await this.externalService.getProductsByEntity(it.entity);
      if (it.userName) {
        if (this.roleDetail.isRadicator) {
          await this.externalService.getAllLawyers(it.id, it.entity);
        }
      } else {
        if (this.roleDetail.isRadicator) {
          await this.externalService.getAllLawyers();
        }
      }
      if (this.roleDetail.isApprover || this.roleDetail.isLawyer) {
        await this.ticketService.getApprovePronouncement()
      }
    });
    sub.unsubscribe();
  }

  public getNoticeStatus(counterDays, assignmentDate) {
    const isLate = Utils.isDateLate(counterDays);
    const isAssigned = assignmentDate != null;
    return this.getNoticeStyle(isAssigned, isLate);
  }

  public getNoticeStyle(isAssigned, isLate) {
    const style = {
      style: isLate == 1 ? 'info' : isLate == 2 ? 'warning' : 'danger',
      icon: isLate == 1 ? 'check' : isLate == 2 ? 'warning' : 'times'
    };
    return {
      style: `alert alert-${style.style} fade show`,
      icon: `fa fa-${style.icon} fa-2x pull-left m-r-10`
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  beforeChangeTab($event: NgbTabChangeEvent) {
    this.activeIdString = $event.nextId;
    this.location.replaceState(this.router.createUrlTree(
      [`/complaints/${this.route.snapshot.params['id']}`],
      {
        queryParams: {
          tab: $event.nextId
        }
      }
    ).toString())
  }

  isValidForAction(ticketStateId) {
    return !Utils.isInvalidForAction(ticketStateId) 
  }

  haveResponseFromEntity(ticketStateId) {
    return ![1, 2, 3, 4, 5].includes(ticketStateId);
  }

  isEntityState(ticketState) {
    return Utils.isEntityState(ticketState);
  }

  isRadicatedState(ticketState) {
    return Utils.isRadicatedState(ticketState);
  }

  isEditableTypeTicket$ = () => {
    return this.ticketLogs$.pipe(map((it: TicketLog[]) => (it.filter(it_ => it_.stateTicket.detalle.includes('Cambio de Tipo')).length == 0)))
  }

  isEditableSpokesPerson$ = () => {
    return this.ticketLogs$.pipe(map((it: TicketLog[]) => (it.filter(it_ => it_.stateTicket.detalle.includes('Creacion Voceria"')).length == 0)))
  }

  inputFilesEvent($event) {
    this.askForInformationform.patchValue({ files: $event.target.files })
  }

  async submitAskForInformation(c) {
    var canContinue = await this.supportsConfirmation();
    if (!canContinue && this.answerFormType == 'answer') {
      event.preventDefault();
      return;
    }

    try {
      await this.spinner.show();
      const formData = {
        ...this.askForInformationform.value,
        ticketId: this.currentId,
        messagesExtraRequestDTO: [
          { message: this.askForInformationform.value['message'] }
        ]
      };
      delete formData.message;
      delete formData.files;
      
      const baseResponse = await this.ticketService.askForInformation(formData, this.roleDetail, this.answerFormType);
      
      if (this.askForInformationform.value['files'].length > 0) {
        const formFilesData = {
          ticketId: this.currentId,
          extraRequestId: baseResponse.data.id,
          messageId: baseResponse.data.messagesExtraRequestDTO[0].id,
        }
       
        await this.ticketService.attachExtraFiles(formFilesData, this.askForInformationform.value['files']);
      }

      this.files_ = []
      await this.ticketService.notifyInformation(baseResponse.data.id);

    } catch {
      swal.fire('Ha ocurrido un error!', 'Lo sentimos ha sucedido algo, ¿puedes intentar nuevamente?', 'error');
    } finally {
      this.askForInformationform.reset();
      await this.baseFormAction(c);
    }
  }

  async supportsConfirmation(): Promise<Boolean> {
    if (this.askForInformationform.value['files'].length > 0)
      return true;

    return await swal.fire({
      text: '¿No has añadido ningún adjunto, deseas continuar de todas formas?',
      icon: 'question',
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonText: 'Si, continuar con la respuesta!',
      cancelButtonText: 'No, quiero pensarlo mejor'
    }).then(async (result) => {
      return result.isConfirmed;
    });
  }

  async submitRejection(c) {
    try {
      if (this.rejectionForm.get('rejectionTypeId').value === Utils.REPLICA_TYPE_STATE) {
        var replicaConsecutive = this.rejectionForm.get('replicaConsecutive').value;
        var finalNote = `<b>Replica de ${replicaConsecutive}</b><br/> ${this.rejectionForm.get('note').value}`;
        this.rejectionForm.get('note').setValue(finalNote);
      }

      await this.spinner.show();
      await this.ticketService.rejectTicket({ ...this.rejectionForm.value, ticketId: this.currentId })
    } catch {
      swal.fire('Ha ocurrido un error!', 'Lo sentimos ha sucedido algo, ¿puedes intentar nuevamente?', 'error');
    } finally {
      this.rejectionForm.reset();
      await this.baseFormAction(c);
    }
  }

  async submitAssignation(c) {
    try {
      await this.spinner.show();
      const form = this.assignationForm.value;
      delete form['note']
      if (!this.roleDetail.isEntity) {
        await this.submitAssignationBase({ ...form, ticketId: this.currentId })
      } else {
        await this.submitAssignationEntity({ ...form, ticketId: this.currentId })
      }
    } catch {
      swal.fire('Ha ocurrido un error!', 'Lo sentimos ha sucedido algo, ¿puedes intentar nuevamente?', 'error');
    } finally {
      this.assignationForm.reset();
      await this.baseFormAction(c);
    }
  }

  async submitAssignationBase(form) {
    await this.ticketService.changeTicketEntity(form)
  }

  async submitAssignationEntity(form) {
    await this.ticketService.changeTicketEntityProduct(form)
  }

  async submitChangeType(c) {
    await this.spinner.show();
    const form = this.changeTypeForm.value;
    delete form['note']
    await this.ticketService.changeTicketType({ ...form, ticketId: this.currentId })
    this.changeTypeForm.reset();
    await this.baseFormAction(c);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files);
  }

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      this.files_.push(item);
    }
    this.files.patchValue(this.files_);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files_.splice(index, 1);
    this.files.patchValue(this.files_);
  }

  get files() {
    return this.askForInformationform.get('files');
  }

  async submitAssignToLawyer(c) {
    const it = this.route.snapshot.params;
    const form = {
      ticketId: it['id'],
      assignedId: this.assignTicketForm.value['assignedId']
    }
    await this.ticketService.assignTicketToLawyer(form)
    await this.baseFormAction(c)
  }

  private async setTypeComplaintId() {
    const it = this.route.snapshot.params;
    await this.fetchData(it['id']);
    await this.ticketService.getTicketLogs(it['id'])
    await this.ticketService.getTicketAnswers(it['id'])
    this.detailData$.subscribe(it => {
      this.changeTypeForm.controls.typeTicketId.patchValue(it.typeTicket);
      this.askForInformationform.controls.subject.patchValue('[RADICADOR] Solicitud de Información y/o Documentos')
    });
  }

  private async baseFormAction(c) {
    await this.setTypeComplaintId();
    await this.spinner.hide();
    c('Close click')
  }

  validatePronouncementExists() {
    return this.ticketAnswerQuery.ticketAnswer$().pipe(map(it => it.filter(x => x.typeExtraRequest.name.includes('Pronunciamiento')).length == 0))
  }

  isValidForActionAssign(stateTicketId: number) {
    return this.isValidForAction(stateTicketId) && [10, 4].includes(stateTicketId)
  }

  validateAssignationForm(isValidForm) {
    const form = this.assignationForm.value
    if (this.roleDetail.isEntity) {
      return form['typeFormat378Id'] == null || form['productId'] == null
    }
    return isValidForm
  }

  askInformationIsValid(validationBase) {
    if (this.answerFormType == 'pronouncement') {
      if (!this.roleDetail.isLawyer) { return validationBase; }
      return validationBase || this.askForInformationform.value['typeResponseTicketExtraId'] == null;
    } else {
      return validationBase;
    }
  }

}
