import { guid, StoreConfig, Store, EntityState, EntityStore } from '@datorama/akita';
import pageSettings from '../../config/page-settings';
import { Injectable } from '@angular/core';

export interface EducationLevel  {
  id: number;
  name: string;
  state: string;
}

export interface EducationLevelState extends EntityState<EducationLevel, number> {
  id: number;
  name: string;
  state: string;
}

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'EducationLevel' })
export class EducationLevelStore extends EntityStore<EducationLevelState> {
  constructor() {
    super();
    this.set({1: null}, {activeId: 1});
  }
}
