import { AuthService } from 'src/app/services/auth.service';
import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { map, shareReplay, tap, filter, startWith, switchMap, flatMap } from 'rxjs/operators';
import { routes } from 'src/app/config/routes';
import { Observable } from 'rxjs-compat';
import { User, UserState, UserStore } from './user.store';
import { of } from 'rxjs';
import { BaseQuery } from '../base/base.query';

@Injectable({
  providedIn: 'root'
})
export class UserQuery extends BaseQuery<UserState> {
  constructor(protected store: UserStore) {
    super(store);
  }

  active$ = () => this.selectUser();

  getSnapshot = () => this.getValue();

  isLoading$ = () => this.selectLoading();

  User$ = () => this.selectUser().pipe(filter(it => it != null));
  UserCount$ = () => this.selectCount(it => it != null)

  selectUser() {
    return this.selectActive(it => it) as Observable<User>;
  }

}
