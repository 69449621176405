import { guid, StoreConfig, Store, EntityState, EntityStore } from '@datorama/akita';
import pageSettings from '../../config/page-settings';
import { Injectable } from '@angular/core';
import { TicketState } from 'src/app/interfaces/external.interface';


export interface TicketStateState extends EntityState<TicketState, number> {
  id: number;
  detalle: string;
  state: string;
}

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'TicketState' })
export class TicketStateStore extends EntityStore<TicketStateState> {
  constructor() {
    super();
    this.set({1: null}, {activeId: 1});
  }
}
