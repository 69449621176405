import { Component } from '@angular/core';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { timer } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';

@Component({
    selector: 'verification',
    templateUrl: './verification.html',
    providers: []
})

export class VerificationPage {
    validateForm = new FormGroup({ otp: new FormControl('', [Validators.required]) });
    secondsLeft = 0;
    resendEnabled = false;
    timer: any;

    constructor(
        private router: Router,
        private authService: AuthService,
        private spinner: NgxSpinnerService,
        private route: ActivatedRoute
    ) { 
        this.restartResend();
    }

    clearInput() {
        this.validateForm.get('otp').reset();
    }

    restartResend() {
        this.secondsLeft = 59;
        window.clearInterval(this.timer);
        this.timer = setInterval(() => {
            this.secondsLeft--;
            if (this.secondsLeft === 0) {
                window.clearInterval(this.timer);
                this.resendEnabled = true;
            }
        }, 1000);
    }

    cancel(){
        this.router.navigateByUrl(`login`);
    }

    async resendOTP() {
        this.spinner.show();
        const queryParams = this.route.snapshot.queryParams;

        const uuid = queryParams['uid'] || '';
        const response = await this.authService.resend(uuid);
        if (!response.success) {
            this.spinner.hide();
            this.router.navigateByUrl(`login`);
            return;
        }

        this.spinner.hide();
        this.router.navigateByUrl(`verification?uid=${response.data.refreshToken}`);
    }

    async onSubmit() {
        try {
            this.spinner.show();
            const queryParams = this.route.snapshot.queryParams;

            const uuid = queryParams['uid'] || '';
            const otpCode = this.validateForm.get('otp').value;

            const response = await this.authService.validate(otpCode, uuid);
            if (!response.success) {
                this.validateForm.controls['otp'].setErrors({ 'invalidOtp': true });
                this.spinner.hide();
                return;
            }

            let redirect = this.route.snapshot.queryParams['redirect'] || '/dashboard';
            redirect = redirect.replace('@', '?');
            this.authService.storeTokens(response.data.accessToken, response.data.refreshToken)

            this.spinner.hide();
            this.router.navigateByUrl(redirect);
        } catch (error) {
            console.log(error);
            this.spinner.hide();
        }
    }
}