import { AuthService } from 'src/app/services/auth.service';
import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { map, shareReplay, tap, filter, startWith, switchMap, flatMap } from 'rxjs/operators';
import { routes } from 'src/app/config/routes';
import { TypeResponseApprovePronouncementState, TypeResponseApprovePronouncementStore } from './type-response-approve-pronouncement.store';
import { Observable } from 'rxjs-compat';
import { BaseQuery } from '../base/base.query';

@Injectable({
  providedIn: 'root'
})
export class TypeResponseApprovePronouncementQuery extends BaseQuery<TypeResponseApprovePronouncementState> {
  constructor(protected store: TypeResponseApprovePronouncementStore) {
    super(store);
  }

  active$ = () => this.selectTypeResponseApprovePronouncement().pipe(shareReplay(1));

  getSnapshot = () => this.getValue();

  isLoading$ = () => this.selectLoading();

  TypeResponseApprovePronouncement$ = (selectedId=null) => this.selectTypeResponseApprovePronouncement(selectedId).pipe(shareReplay(1));
  
  selectTypeResponseApprovePronouncement(selectedId=null) {
    return this.selectAll().pipe(
      map(it => it.filter(x => x != null)),
      map(it => it.map( x => ({
        ...x,
        selected: x.id == selectedId
      })))
    )
  }

}
