import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { removeEmptyJsonFields, Utils } from 'src/app/common/utils';
import { Filter, TicketTableFormat } from 'src/app/interfaces/ticket.interface';
import { IPaginate } from 'src/app/services/base.service';
import { TicketService } from 'src/app/services/ticket.service';
import { Location } from '@angular/common';
import { LoadingService } from 'src/app/services/loading.service';
import { FormControl, FormGroup } from '@angular/forms';
import { CompanyQuery } from 'src/app/states/company';
import { ExternalService } from 'src/app/services/external.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TypeComplaintQuery } from 'src/app/states/type_complaint';
import { NgxSpinnerService } from 'ngx-spinner';
import { PaginationConfig } from './data';
import { AuthService, IRoleDetail } from 'src/app/services/auth.service';
import { Moment } from 'moment';
import { Ticket } from 'src/app/states/ticket';
import { TicketStateQuery } from 'src/app/states/ticket_state';
import { LawyerQuery } from 'src/app/states/lawyer';
import swal from 'sweetalert2';

@Component({
  selector: 'complaints',
  templateUrl: './complaints.html'
})

export class ComplaintsPage implements OnInit {
  roleDetail: IRoleDetail;
  closeResult: string;
  maxDate: string = new Date().toISOString().split('T')[0];

  public rows: Array<any> = [];
  public paginateData: IPaginate<Ticket> = {
    data: [],
    totalPage: 0,
    totalRegister: 0
  };
  public filters: Filter = {
    page: null,
    loading: true
  };

  public config = PaginationConfig(this.authService.getRole());
  public columns = this.config.baseConfig.sorting.columns;

  lawyers$ = this.lawyerQuery.lawyer$();
  entities$ = this.companyQuery.company$();
  complaintTypes$ = this.typeComplaintQuery.typeComplaint$();
  ticketStates$ = this.ticketStateQuery.TicketState$();

  public filtersForm = new FormGroup({
    id: new FormControl(null),
    consecutive: new FormControl(null),
    creationDate: new FormGroup({
      creationDateStart: new FormControl(null),
      creationDateEnd: new FormControl(this.maxDate)
    }),
    entity: new FormControl(null),
    typeComplaint: new FormControl(null),
    receivingMedium: new FormControl(null),
    assignmentDate: new FormControl(false),
    stateTicket: new FormControl(null),
    moanfulSerial: new FormControl(null),
    moanfulName: new FormControl(null),
    lawyerId: new FormControl(null)
  });

  public constructor(
    private router: Router,
    private ticketService: TicketService,
    private route: ActivatedRoute,
    private location: Location,
    private loadingService: LoadingService,
    private companyQuery: CompanyQuery,
    private lawyerQuery: LawyerQuery,
    private externalService: ExternalService,
    private typeComplaintQuery: TypeComplaintQuery,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private modalService: NgbModal,
    private ticketStateQuery: TicketStateQuery,
  ) {
    this.spinner.show();
    this.loadingService.setLoading(true);
  }

  public async ngOnInit() {
    this.roleDetail = this.authService.getRoleDetail();
    await this.setLoading(true)
    if (Utils.SELF_INCLUDED_ENTITY_ROLES.includes(this.authService.getRole())) this.externalService.getPrivateEntities();

    await this.externalService.getTypeComplaintByRole();
    await this.externalService.getPrivateEntities();
    await this.externalService.getTicketStates();
    await this.externalService.getAllLawyers();

    const it = this.route.snapshot.queryParams;
    this.setPage(it['page'] ? (parseInt(it['page'], 10)) : 1)
    this.setFilters(it);
    await this.changePage(0, false, true)
  }

  public getFormatedRow(row, columnName: string) {
    if (typeof row[columnName] === 'undefined') {
      return '-'
    }
    if (columnName.includes('Date')) {
      if (row[columnName].includes('T')) {
        return row[columnName].split('T')[0]
      }

      if (columnName.includes('maxDate')) {
        return row['extesionMaxDate'] ? row['extesionMaxDate'] : row['maxDate']
      }

      return row[columnName]
    }

    if (columnName.includes('counterDays')) {
      return `${row[columnName]} Días hábiles`
    }

    if (columnName.includes('consecutive') || columnName.includes('id')) {
      return `<span class="btn-link pointer">${row[columnName]}</span>`;
    }

    if (columnName.includes('stateTicketName')) {
      return `<b>${row[columnName]}</b>`;
    }

    return row[columnName]
  }

  private fetchHtmlData(rows: Ticket[] = this.paginateData.data) {
    return rows.map(x => {
      const isSent = this.isSent((x.stateTicket as any).id);
      return {
        ...x,
        id_: this.renderCell(false, this.renderComplaintId(x.consecutive, false, isSent), isSent),
        creationDate_: this.renderCell(false, Utils.parseDateToString(x.creationDate, false), isSent),
        typeComplaintName_: this.renderCell(false, x.typeComplaintName, isSent),
        remaining_time_response_: this.renderCell(false, Utils.getHtmlAvailableDays(x.counterDays), isSent),
        entity_: this.renderCell(false, x.entityName, isSent),
        response_type_: this.renderCell(false, x.receivingMediumName, isSent),
        is_sent_: this.renderCell(false, this.renderIsSent(isSent), isSent),
        is_late_: this.renderCell(false, false, isSent),
        asigned_user_: this.renderCell(false, x.userName || '-', isSent),
        assignmentDate_: this.renderCell(false, Utils.parseDateToString(x.assignmentDate), isSent),
        have_extra_msg_: this.renderSubStatus(x),
      };
    });
  }

  getRowClass(row) {
    let baseClass = '';
    if (Utils.isInvalidForAction(row.stateTicket)) { return baseClass; }
    if (Utils.isDateLate(row.counterDays) == 1) { baseClass = 'success'; }
    if (Utils.isDateLate(row.counterDays) == 2) { baseClass = 'warning'; }
    if (Utils.isDateLate(row.counterDays) == 3) { baseClass = 'danger'; }

    return `bg-${baseClass}-super-lighter-transparent`;
  }

  private isSent(stateTicketId) {
    return stateTicketId !== 1;
  }

  private renderIsSent(isSent) {
    return isSent ? 'Si <i class="fa fa-check-circle"></i>' : 'No <i class="fa fa-times-circle"></i>';
  }

  private renderSubStatus(ticket: Ticket) {
    var subStatus = "";
    if (ticket.apremioDate)
      subStatus += "Con apremio";

    if (ticket.voceriaEntityDate)
      subStatus += "\nCon voceria";

    if (ticket.extensionDate)
      subStatus += "\nCon prórroga";

    if (ticket.inconsistencyMarkDate)
      subStatus += "\nInconsistencia";

    return subStatus ? `<i class="fa fa-certificate text-warning" title="${subStatus}"></i>` : '';
  }

  private renderCell(isLate, templateString, isSent) {
    return templateString;
  }

  private renderComplaintId(value, isLate, isSent) {
    return `<a class="${isLate ? 'text-laurado-red' : isSent ? 'bg-success' : ''}">${value}</a>`;
  }

  public async submitFilters(c) {
    this.addFilters();
    await this.onChangeTable(true)
    c('Close click');
  }

  private addFilters() {
    const curForm = this.filtersForm.value;
    const dateFilters = {
      ...curForm['creationDate'],
      ...curForm['assignmentDate_']
    }
    delete curForm['creationDate'];
    delete curForm['assignmentDate_'];
    this.filters = Object.assign(this.filters, {
      ...this.filters,
      ...curForm,
      ...dateFilters
    })
  }

  setPage(page) {
    this.filters.page = page;
  }

  setFilters(it) {
    this.filters = Object.assign(this.filters, {
      ...this.filters,
      ...it
    })
    Object.keys(it).filter(x => !['loading', 'page'].includes(x)).forEach(element => {
      if (['creationDateStart', 'creationDateEnd'].includes(element)) {
        this.filtersForm.controls.creationDate.patchValue({
          ...this.filtersForm.value.creationDate,
          [`${element}`]: it[element]
        })
      } else {
        this.filtersForm.controls.creationDate.patchValue({
          creationDateStart: null,
          creationDateEnd: null
        })
        if (['assignmentDateStart', 'assignmentDateEnd'].includes(element)) {
          this.filtersForm.controls.assignmentDate_.patchValue({
            ...this.filtersForm.value.assignmentDate_,
            [`${element}`]: it[element]
          })
        } else {
          this.filtersForm.controls.creationDate.patchValue({
            assignmentDateStart: null,
            assignmentDateEnd: null
          })
          if (element == 'assignmentDate') {
            this.filtersForm.controls.assignmentDate.patchValue(it[element] == 'true')
          } else {
            this.filtersForm.controls[element].patchValue(it[element])
          }
        }
      }
    });
  }

  async setLoading(loading) {
    if (loading) {
      this.spinner.show();
    } else {
      this.spinner.hide();
    }
    this.filters.loading = loading;
  }

  async fetchData() {
    const tempFilters = {
      ...this.filters,
      page: this.filters.page > 0 ? this.filters.page - 1 : 0
    }
    delete tempFilters['loading']
    return await this.ticketService.listTickets(tempFilters);
  }

  public async changePage(event, withRedirect: boolean = true, firstTime: boolean = false) {
    const it = this.route.snapshot.queryParams;
    if (firstTime) {
      this.loadingService.setLoading(true);
      this.setPage(it['page'] ? (parseInt(it['page'], 10)) : 1)
    } else {
      if (this.filters.loading && event == 1) {
        this.loadingService.setLoading(true);
        this.setPage(it['page'] ? (parseInt(it['page'], 10)) : 1)
      } else {
        this.setPage(event)
      }
    }
    await this.onChangeTable(withRedirect)
    this.loadingService.setLoading(false);
  }

  public getComponentLoading() {
    return !this.loadingService.loading;
  }

  public async onChangeTable(withRedirect: boolean = true) {
    try {
      await this.setLoading(true)
      const resume = (await this.fetchData()).data;
      this.paginateData = {
        ...resume,
        data: this.fetchHtmlData(resume.data),
      }
      if (withRedirect) {
        this.goToComplaintsWithQueryParams()
      }
      await this.setLoading(false)
    } catch (e) {
      await this.setLoading(false)
    }
  }

  private goToComplaintsWithQueryParams() {
    const tmpFilters = removeEmptyJsonFields(this.filters);
    delete tmpFilters['loading']
    this.location.replaceState(this.router.createUrlTree(
      ['/complaints'],
      { queryParams: tmpFilters }
    ).toString())

  }

  public onCellClick(row: TicketTableFormat): any {
    this.router.navigateByUrl(`complaints/${row.id}`);
  }

  setCreationDateFilters(e: { creationDateStart: Moment, creationDateEnd: Moment }) {
    this.filtersForm.controls.creationDate.patchValue({
      creationDateStart: e.creationDateStart ? e.creationDateStart.format('YYYY-MM-DD') : null,
      creationDateEnd: e.creationDateEnd ? e.creationDateEnd.format('YYYY-MM-DD') : null
    });
  }

  setAssignationDateFilters(e: { assignmentDateStart: Moment, assignmentDateEnd: Moment }) {
    this.filtersForm.controls.assignmentDate_.patchValue({
      assignmentDateStart: e.assignmentDateStart ? e.assignmentDateStart.format('YYYY-MM-DD') : null,
      assignmentDateEnd: e.assignmentDateEnd ? e.assignmentDateEnd.format('YYYY-MM-DD') : null
    });
  }

  open(content) {
    this.modalService.open(content, { size: 'lg', backdrop: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  resetFilterForm() {
    this.filtersForm.reset();
  }

  async downloadExcel() {
    this.addFilters();
    if (!this.filters.creationDateStart) {
      await swal.fire({
        html:
          '<h3>¿Está seguro de continuar?</h3>' +
          '<h2 class="m-b-0"><small>Aun no se ha establecido un </b>rango de fechas</b> para el reporte</small></h2>' +
          '<h4><small>(Usaremos como rango el último año)</small></h4>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, continuar!',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
      }).then(async (result) => {
        if (result.value) {          
          this.setCurrentYearForFilter();
          this.addFilters();

          await this.downloadExcel_()
        }
      });
    } else {
      await this.downloadExcel_();
    }
  }

  async downloadExcel_() {
    try {
      this.spinner.show();
      const tempFilters = {
        ...this.filters,
        page: this.filters.page > 0 ? this.filters.page - 1 : 0
      }
      delete tempFilters['loading']
      await this.ticketService.downloadTickets(tempFilters);
      this.spinner.hide();
      swal.fire('Excelente...', 'La descarga se realizó exitosamente!', 'success')
    } catch (e) {
      this.spinner.hide();
      swal.fire('Ooops!!!', `Lo sentimos ocurrio un error, por favor intenta nuevamente.`, 'error');
    }
  }

  clearDatesForFilter() {
    this.filtersForm.controls.creationDate.patchValue({
      creationDateStart: null,
      creationDateEnd: null
    })
  }

  setCurrentYearForFilter() {
    this.filtersForm.controls.creationDate.patchValue({
      creationDateStart: new Date().getFullYear() + '-01-01',
      creationDateEnd: new Date().toISOString().split('T')[0]
    })
  }

  setCurrentMonthForFilter() {
    var initialDate = new Date();
    initialDate.setDate(1);

    this.filtersForm.controls.creationDate.patchValue({
      creationDateStart: initialDate.toISOString().split('T')[0],
      creationDateEnd: new Date().toISOString().split('T')[0]
    })
  }

  setLastDaysForFilter(days) {
    var initialDate = new Date();
    initialDate.setDate(initialDate.getDate() - days);

    this.filtersForm.controls.creationDate.patchValue({
      creationDateStart: initialDate.toISOString().split('T')[0],
      creationDateEnd: new Date().toISOString().split('T')[0]
    })
  }
}
