import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Utils } from 'src/app/common/utils';
import { AuthService, IRoleDetail } from 'src/app/services/auth.service';
import { TicketQuery } from 'src/app/states/ticket';
import { TicketLogQuery } from 'src/app/states/ticket_log';

@Component({
  selector: 'complaint_general',
  templateUrl: './complaint_general.html'
})

export class ComplaintGeneralPage implements OnInit {
  
  readonly ticketStates = Utils.ticketStates;
  
  ticketData$ = this.ticketQuery.ticket$();
  ticketLogs$ = this.ticketLogQuery.ticketLog$();
  roleDetail: IRoleDetail;
  constructor(
    private route: ActivatedRoute,
    private ticketLogQuery: TicketLogQuery,
    private ticketQuery: TicketQuery,
    private authService: AuthService,

  ) {
    
  }

  public ngOnInit(): void {
    this.roleDetail = this.authService.getRoleDetail();
  }

  public haveState(ticketLogs: any[], stateId: number){
    return this.getState(ticketLogs, stateId) != null;
  }  

  public getCreationDateState(ticketLogs: any[], stateId: number){
    var findState = this.getState(ticketLogs, stateId);
    return (findState) ? findState.date.slice(0, 10) : "-";
  }

  public getState(ticketLogs: any[], stateId: number){
    return ticketLogs.find(logs=> logs.stateTicket.id == stateId);
  }

}
