<div class="row" *ngIf="ticketData$ | async as ticketData">
  <div class="col-md-12 col-xs-12 col-sm-12">
    <panel variant="success" title="Descripción de la solicitud" noButton="true" bodyClass="border-success">
      <div class="row">
        <div class="col-md-12">
          <h5>Asunto de la reclamación</h5>
          <span class="f-s-14" [innerHTML]="ticketData.subject">
          </span>
        </div>
      </div>
      <div class="clearfix"></div>
      <br/>
      <div class="row">
          <div class="col-md-6 bg-blue-super-lighter-transparent p-15">
            <perfect-scrollbar style="height: 150px">
              <div style="position: static;" class="ps ps--active-y">
                <div class="ps-content">
                  <h5><i class="fas fa-question-circle"></i> Información del incidente</h5>
                  <p><span [innerHTML]="ticketData.acts"></span></p>
                </div>
              </div>
            </perfect-scrollbar>
          </div>
          <div class="col-md-6 bg-green-super-lighter-transparent p-15">
            <perfect-scrollbar style="height: 150px">
              <div style="position: static;" class="ps ps--active-y">
                <div class="ps-content">
                  <h5><i class="fas fa-lightbulb"></i> Solicitud del cliente</h5>
                  <p><span [innerHTML]="ticketData.request"></span></p>
                </div>
              </div>
            </perfect-scrollbar>
          </div>          
          <div class="col-md-12 bg-red-super-lighter-transparent p-15" *ngIf="isRadicatedState(ticketData.stateTicket)">
            <perfect-scrollbar style="height: 150px">
              <div style="position: static;" class="ps ps--active-y">
                <div class="ps-content">
                  <h5><i class="fas fa-bullhorn"></i> Motivo de rechazo ({{ticketData.rejectionTypeName}})</h5>                  
                  <p><span [innerHTML]="ticketData.note"></span></p>
                </div>
              </div>
            </perfect-scrollbar>
          </div>
      </div>
      <div class="clearfix"></div>
      <br/>
      <div class="row">
        <div class="col-md-12"><h5>Archivos adjuntos a la petición</h5></div>
        <div class="col-md-12 inbox">
          <div class="ps">
            <div class="ps-content">
              <div class="wrapper">
                <ul class="attached-document clearfix" *ngIf="(ticketFileCount$ | async) > 0">
                  <li class="fa-file-archive" *ngIf="roleDetail.isAdmin">
                    <input type="file" id="fileDropRef" accept=".pdf,.jpg,.jpeg,.png,.mp4,.doc,.docx,.xls,.xlsx,.bmp,.mp3,.msg" (change)="uploadSelectedFile($event.target.files, ticketData.id)" hidden />
                    <div class="document-file">
                      <a (click)="openUploadFileDialog();" href="javascript:;"><i style="color: indianred;" class="fa fa-upload"></i></a>
                    </div>
                    <div class="document-name">
                      <a href="javascript:;" (click)="openUploadFileDialog();">Añadir soporte</a>
                    </div>
                  </li>
                  <li [class]="getIconObject(ticketFile.url).icon" *ngFor="let ticketFile of ticketFiles$ | async">
                    <div *ngIf="roleDetail.isAdmin" class="p-l-5" style="position: absolute; background-color: #f2f3f4;width: 25px;" title="Eliminar soporte">
                      <a (click)="deleteFile(ticketFile.id);" href="javascript:;"><i style="color: indianred;" class="fa fa-trash"></i></a>
                    </div>
                    <div class="document-file">
                      <a [href]="ticketFile.url" target="_blank" [innerHtml]="getIconObject(ticketFile.url).content"></a>
                    </div>
                    <div class="document-name">
                        <a [href]="ticketFile.url" target="_blank">{{ticketFile.name}}</a>
                    </div>                    
                  </li>
                  <li class="fa-file-archive">
                    <div class="document-file">
                      <a href="javascript:;" (click)="downloadZip(ticketData.id)"><i class="fa fa-download"></i></a>
                    </div>
                    <div class="document-name">
                      <a href="javascript:;" (click)="downloadZip(ticketData.id)">Descargar como zip</a>
                    </div>
                  </li>                  
                </ul>
                <div class="note note-info text-center" *ngIf="(ticketFileCount$ | async) == 0">
                  <div class="note-content">
                    <i class="fas fa-exclamation-circle fa-5x"></i>
                    <hr>
                    <h4><b>Registro sin archivos asociados!</b></h4>
                    <p>Esta solicitud no contiene archivos asociados como evidencia u otros.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </panel>
  </div>
</div>