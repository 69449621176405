import { BaseService, ApiResponse, BaseEntity } from './base.service';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { PageSettingsQuery } from '../states/page_settings/page_settings.query';
import { CookieService } from 'ngx-cookie-service';
import { Utils } from '../common/utils';
import * as moment from 'moment';

export interface LoginMoanful {
  typeDocumentId: number;
  serial: number;
  documentDate: string;
}

export interface VerificationPayload {
  otp: string;
  uuid: string;
}

export interface ResendVerificationPayload {
  uuid: string;
}

export interface LoginCollaborator {
  email: string;
  password: string;
}

export type LoginPayload = LoginCollaborator | LoginMoanful;
export interface LoginBody {
  accessToken: string;
  refreshToken: string;
  otpRequired: boolean;
}

export interface UserBody {
  lastname: string;
  serial: number;
  name: string;
  rol: BaseEntity | number;
  email: string;
  birthdate: string;
  typeDocumentName: string;
  cellPhone: number;
  entityName: string;
}

export interface RefreshTokenPayload {
  refreshToken: string;
  email: string;
}

export interface IRoleDetail {
  isRadicator: boolean;
  isRadicatorAdmin: boolean;
  isEntity: boolean;
  isEntityAdmin: boolean;
  isLawyer: boolean;
  isLawyerAdmin: boolean;
  isApprover: boolean;
  isApproverAdmin: boolean;
  isMoanful: boolean;
  isAdmin: boolean;
}

export interface IChangePassword {
  oldPassword: string;
  newPassword: string;

}

export const NoValidatedRoutes = [
  'home',
  'login',
  'complaint',
  '**'
];

@Injectable()
export class AuthService extends BaseService {

  constructor(
    public jwtHelper: JwtHelperService,
    private pageSettingsQuery: PageSettingsQuery,
    public http: HttpClient,
    private cookieService: CookieService
  ) {
    super(http);
  }

  public updateSettingsStore(data: any) {
    this.pageSettingsQuery.updateSettings(data);
  }

  public getAllowedRoutes() {
    return this.pageSettingsQuery.allowedRoutes(this);
  }

  public getRole() {
    const tData = this.getJwtData();
    return tData.scopes[0].authority;
  }

  // Assigment role detail
  public getRoleDetail(): IRoleDetail {
    return {
      isRadicator: this.isRadicator(),
      isRadicatorAdmin: this.isRadicator() || this.isAdmin(),
      isEntity: this.isEntity(),
      isEntityAdmin: this.isEntity() || this.isAdmin(),
      isLawyer: this.isLawyer(),
      isLawyerAdmin: this.isLawyer() || this.isAdmin(),
      isApprover: this.isApprover() || this.isLawyer(),
      isApproverAdmin: this.isApprover() || this.isAdmin(),
      isMoanful: this.isMoanful(),
      isAdmin: this.isAdmin(),
    } as IRoleDetail
  }

  isEnterpriseRole() {
    return this.isRadicator() || this.isEntity() ||
           this.isLawyer() || this.isApprover()
  }

  public isRadicator() {
    return this.getRole() == Utils.RADICATOR;
  }

  public isEntity() {
    return this.getRole() == Utils.ENTITY;
  }

  public isLawyer() {
    return this.getRole() == Utils.LAWYER;
  }

  public isApprover() {
    return this.getRole() == Utils.APPROVER || this.isAdmin();
  }

  public isMoanful() {
    return this.getRole() == Utils.MOANFUL;
  }

  public isAdmin() {
    return this.getRole() == Utils.ADMINISTRATOR;
  }

  public async whoAmI() {
    const url = `${this.baseUrl}/user/me`;
    return await this.get<UserBody>(url);
  }

  public getJwtData() {
    const token = this.getAccessToken();
    return this.jwtHelper.decodeToken(token);
  }

  public async changePassword(data_: IChangePassword) {
    try {
      const url = `${this.baseUrl}/user/change-password`;
      const cResponse = await this.post<IChangePassword, any>(url, data_);
      if (!cResponse.success) {
        throw new Error("Contraseña incorrecta");
      }
      const tData = this.getJwtData();
      const data = {
        email: tData.sub,
        password: data_.newPassword
      }
      const loginResponse = await this.login(data, true);
      this.storeTokens(loginResponse.data.accessToken, loginResponse.data.refreshToken, true)
    } catch (error) {
      throw error;
    }
  }

  public async refreshToken() {
    const url = `${this.baseUrl}/api/refresh`;
    const response = await this.refreshPost<LoginBody, RefreshTokenPayload>(url, {
      refreshToken: this.getRefreshToken(),
      email: this.getJwtData().sub
    } as RefreshTokenPayload).toPromise();
    this.storeTokens(response.accessToken, response.refreshToken, true)
    return response;
  }

  public async isAuthenticated() {
    try {
      const token = this.getAccessToken();
      if (token == null) { return false; }
      if (!await this.sessionAvailable()) {
        await this.refreshToken();
        return await this.sessionAvailable();
      }
      return await this.sessionAvailable();
    } catch (error) {
      return false;
    }
  }

  public async sessionAvailable() {
    const token = this.getAccessToken();
    return await !this.jwtHelper.isTokenExpired(token);
  }

  public async validate(otp: string, uuid: string): Promise<ApiResponse<LoginBody>> {
    const payload: VerificationPayload = { otp: otp,  uuid: uuid };
    return await this.post<LoginBody, VerificationPayload>(`${this.baseUrl}/api/verification`, payload);
  }

  public async resend(uuid: string): Promise<ApiResponse<LoginBody>> {
    const payload: ResendVerificationPayload = { uuid: uuid };
    return await this.post<LoginBody, ResendVerificationPayload>(`${this.baseUrl}/api/verification/resend`, payload);
  }

  public async login(payload: LoginPayload, isCollaborator: boolean): Promise<ApiResponse<LoginBody>> {    
    const url = isCollaborator ? `${this.baseUrl}/api/login` : `${this.baseUrl}/api/login-moanful`;
    return await this.post<LoginBody, LoginPayload>(url, payload);
  }

  storeTokens(accessToken: string, refreshToken: string, isReload=false) {
    this.cookieService.set('def-auth-token', accessToken, (moment(this.jwtHelper.getTokenExpirationDate(accessToken)).add(50, 'hours')).toDate(), '/')
    this.cookieService.set('def-refresh-token', refreshToken, (moment(this.jwtHelper.getTokenExpirationDate(accessToken)).add(50, 'hours')).toDate(), '/')
    if (!isReload) {
      this.cookieService.set('def-last-login', moment().format('YYYY-MM-DD HH:mm:ss'), (moment(this.jwtHelper.getTokenExpirationDate(accessToken)).add(50, 'hours')).toDate(), '/')
    }
  }

  public logout() {
    this.cookieService.delete('def-auth-token', '/');
    this.cookieService.delete('def-refresh-token', '/');
    this.cookieService.delete('def-last-login', '/');
    this.cookieService.delete('def-auth-token', '/complaints');
    this.cookieService.delete('def-refresh-token', '/complaints');
    this.cookieService.delete('def-last-login', '/complaints');
    this.cookieService.deleteAll();
  }

  public getRefreshToken() {
    return this.cookieService.get('def-refresh-token');
  }

  public getAccessToken() {
    return this.cookieService.get('def-auth-token');
  }

  public getLastLogin() {
    return this.cookieService.get('def-last-login');
  }
}
