<ngx-loading-bar class="top"></ngx-loading-bar>
<ngx-spinner 
    bdColor="rgba(0, 0, 0, 0.8)"
    size = "large" color = "#fff"
    type = "timer"
    [fullScreen] = "true">
    <p style="color: white" > Cargando información, por favor espere... </p>
  </ngx-spinner>
  <!-- begin #page-container -->
  <div id="page-container" class="page-container fade page-sidebar-fixed page-header-fixed show" [ngClass]="{
    'has-scroll' : pageHasScroll,
    'page-sidebar-minified': pageSettings.pageSidebarMinified,
    'page-with-footer': pageSettings.pageWithFooter,
    'page-content-full-height': pageSettings.pageContentFullHeight,
    'page-without-sidebar': pageSettings.pageWithoutSidebar,
    'page-with-right-sidebar': pageSettings.pageSidebarRight,
    'page-with-two-sidebar': pageSettings.pageSidebarTwo,
    'page-with-wide-sidebar': pageSettings.pageSidebarWide,
    'page-right-sidebar-collapsed': pageSettings.pageSidebarRightCollapsed,
    'page-with-light-sidebar': pageSettings.pageSidebarLight,
    'page-with-top-menu': pageSettings.pageTopMenu,
    'page-sidebar-toggled': pageSettings.pageMobileSidebarToggled,
    'page-right-sidebar-toggled': pageSettings.pageMobileRightSidebarToggled,
    'p-0': (hiddingBars$ | async)
    }">

    <top-menu *ngIf="pageSettings.pageTopMenu && !(hiddingBars$ | async)"></top-menu>

      <header
        (toggleSidebarRightCollapsed)="onToggleSidebarRight($event)"
        (toggleMobileSidebar)="onToggleMobileSidebar($event)"
        (toggleMobileRightSidebar)="onToggleMobileRightSidebar($event)"
        [pageSidebarTwo]="pageSettings.pageSidebarTwo"
        *ngIf="!(hiddingBars$ | async)">
      </header>

      <sidebar
        (toggleSidebarMinified)="onToggleSidebarMinified($event)"
        (hideMobileSidebar)="onHideMobileSidebar($event)"
        *ngIf="!pageSettings.pageWithoutSidebar && !(hiddingBars$ | async)"
        [pageSidebarTransparent]="pageSettings.pageSidebarTransparent"
        [pageSidebarMinified]="pageSettings.pageSidebarMinified">
      </sidebar>

      <sidebar-right
        (hideMobileRightSidebar)="onHideMobileRightSidebar($event)"
        *ngIf="pageSettings.pageSidebarTwo && !(hiddingBars$ | async)">
      </sidebar-right>

    <div id="content" class="content"
      [ngClass]="{
        'content-full-width': pageSettings.pageContentFullWidth,
        'content-inverse-mode': pageSettings.pageContentInverseMode,
        'p-0 m-0': (hiddingBars$ | async)
      }">
      <router-outlet></router-outlet>
    </div>

    <footer *ngIf="pageSettings.pageWithFooter"></footer>
  </div>
  <!-- end #page-container -->


<ng-template #ElseOption>
  <router-outlet></router-outlet>
</ng-template>

